/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { faBars, faChevronLeft, faPaperPlane, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import { Form, Button, Image } from "react-bootstrap";
import { IMAGES } from "../../../constants/imagePath";
import useAxios from "../../../hooks/useAxios";
import { API_ENDPOINTS } from "../../../constants/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import XLoader from "../../../component/loader/XLoader";
import * as Yup from "yup";
import "./chat.css";
import { utcToLocalStringWithTime } from "../../../utils/helper";
import { RootState } from "../../../redux/rootReducer";
import { useSelector } from "react-redux";
import useSidebar from "../../../hooks/useSidebar";

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { fetchData, postData, isLoading } = useAxios();
  const location = useLocation();
  const { contactPhoneNumber, firstName, lastName } = location.state || {};
  const [activeNumbers, setActiveNumbers] = useState<any[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | null>("");
  const { logoUrl } = useSelector((state: RootState) => state.user);
  const { onShowSidebar } = useSidebar();
  const navigate = useNavigate();
  const { contactId } = useParams();

  const validationSchema = Yup.object().shape({
    message: Yup.string().required("Message is required"),
    from: Yup.string().required("Select a contact to send message to"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
      from: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { message, from } = values;
      const payload = { message, from, to: contactPhoneNumber };
      const response = await postData(API_ENDPOINTS.CONTACT_CHAT, payload);
      if (response.success) {
        fetchChat({ from });
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    getActiveNumbers();
  }, []);

  const fetchChat = useCallback(async ({ from, pageToken, prevMessages = [] }: any) => {

    if (!from) {
      return
    }

    const payload = {
      from,
      to: contactPhoneNumber,
      pageSize: 10,
      pageToken
    };
    const response = await postData(API_ENDPOINTS.GET_CONTACTS_CONVERSATION, payload);
    const newMessages = response?.data?.messages || [];
    const pageTokenUrl = response?.data?.pageToken;

    if (pageTokenUrl) {
      setNextPageToken(pageTokenUrl);
    } else {
      setNextPageToken(null);
      setHasMore(false);
    }

    setMessages([...prevMessages, ...newMessages]);
  }, [contactPhoneNumber, nextPageToken]);

  const getActiveNumbers = async () => {
    try {
      const response = await fetchData(API_ENDPOINTS.GET_NUMBERS);
      const formattedOption = response?.data.map((v: any) => ({
        value: v.id,
        text: v.number,
        key: v.number,
      }));
      formik.setFieldValue("from", formattedOption[0].text);
      fetchChat({ from: formattedOption[0].text });
      setActiveNumbers(formattedOption);
    } catch (error) {}
  };

  const loadMoreMessages = () => {
    if (!nextPageToken) {
      return
    }
    fetchChat({ from: formik.values.from, prevMessages: messages, pageToken: nextPageToken });
  };

  const refreshChat = () => {
    setMessages([]);
    setNextPageToken("");
    setHasMore(true);
    fetchChat({ from: formik.values.from });
  };

  return (
    <div className="mainHead">
      <Form onSubmit={formik.handleSubmit}>
        <div className="filter-bar d-flex align-items-start justify-content-between row-item top-block">
          <div className="d-flex">
            <h2 >
              <span className="d-lg-none me-3 cursor-pointer" ><FontAwesomeIcon icon={faBars} onClick={()=>onShowSidebar()} /></span>
            </h2>
            <div className="position-relative search-bar">
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlInput1"
              >
                <div className="d-flex align-items-center">
                <Form.Label className="me-3">Send message from</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="from"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setMessages([]);
                    setNextPageToken("");
                    setHasMore(true);
                    fetchChat({ from: e.target.value });
                  }}
                  value={formik.values.from}
                  isInvalid={formik.touched.from && !!formik.errors.from}
                >
                  {activeNumbers.map((v) => (
                    <option key={v.key} value={v.text}>{v.text}</option>
                  ))}
                </Form.Select>
                </div>
                <Form.Control.Feedback type="invalid">{formik.errors.from}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <Button className="refresh-chat-btn" onClick={refreshChat}>
            <FontAwesomeIcon icon={faRotate} />
          </Button>
        </div>
        <div className="chat">
          <div className="chat-header">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className='me-3 cursor-pointer'
              onClick={() => navigate(`/org/contact-detail/${contactId}`)}
            />
            <Image 
              className="chat-header-image me-2"
              src={IMAGES.profile} 
            />
            {firstName} {lastName}
          </div>
          <div
            className="chatBody"
            id="scrollableDiv"
            style={{ overflow: "auto", display: "flex", flexDirection: "column-reverse" }}
          >
            <InfiniteScroll
              dataLength={messages?.length}
              next={loadMoreMessages}
              hasMore={hasMore}
              loader={<p>Loading...</p>}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              scrollableTarget="scrollableDiv"
            >
              {isLoading && <XLoader />}
              {messages.map((msg: any) => {
                const isInbound = msg.direction !== "outbound-api"
                const sid = msg.sid
                const body = msg.body

                return (
                  <div key={sid} className={isInbound ? "received-txt-div" : "send-txt-div"}>
                    <div className="d-flex">
                      {isInbound ? (
                        <>
                          <Image className="me-2" height={30} width={30} src={IMAGES.profile} />
                          <div className="received-txt msg">{body}</div>
                        </>
                      ) : (
                        <>
                          <div className="sender-txt msg">{body}</div>
                            <Image
                              className="ms-2"
                              height={30}
                              width={30}
                              src={logoUrl ? logoUrl : IMAGES.profile}
                              style={{ borderRadius: "50%" }}
                            />
                        </>
                      )}
                    </div>
                    <div className={isInbound ? "received-time" : "send-time"}>
                      {utcToLocalStringWithTime(msg.dateSent)}
                    </div>
                  </div>
                )
              })}
              {!isLoading && messages.length === 0 && 
                <p className="text-center no-records">
                  No messages available
                </p>
              }
            </InfiniteScroll>
          </div>
          <div className="send">
            <Form.Control
              type="text"
              placeholder="Text message"
              {...formik.getFieldProps("message")}
            />
            <Button 
              type="submit" 
              disabled={!formik.values.message}
              className="send-chat-btn"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Chat;
