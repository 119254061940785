import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import XButton from "../../component/button/XButton";
import { campaignTypeOptions, toListTypeOptions } from "../../utils/options";
import useCampaign from "./useCampaign";
import MultiLevelDropdown from "../journey/multiLevelDropdown";
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { toaster } from "../../utils/toast";
import { formatDateToYYYYMMDDTHHMM } from "../../utils/helper";
import { CampaignType, TimeUnit } from "../../utils/enums";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

export type CreateCampaignProp = {
}

const CreateCampaign: FC<CreateCampaignProp> = () => {
  const {
    step,
    handleClose,
    formik1,
    formik2,
    formik3,
    campaignId,
    loading,
    handleBack,
    templates,
    templateWithFolderList,
    domains,
    activeNumbers,
    listMap,
    segmentMap,
  } = useCampaign()

  const toListType = +formik2.values.toListType
  const delayUnit = formik2.values.delayUnit
  const handleTemplateSelect = (id: any) => {
    formik3.setFieldValue('templateId', id);
  }
  const CampaignTypeValue = formik1?.values?.type;
  const { smsFeature } = useSelector((state: RootState) => state.user);

  return (
    <div>
      {step === 1 && (
        <Modal show={step === 1} onHide={handleClose} centered>
          <Form onSubmit={formik1.handleSubmit} noValidate>
            <Modal.Header closeButton>
              <Modal.Title>{campaignId ? 'Update' : 'Create'} a Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Name your campaign</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter"
                  value={formik1.values.title}
                  onChange={formik1.handleChange}
                  isInvalid={formik1.touched.title && !!formik1.errors.title}
                />
                {formik1.errors.title && (
                  <Form.Control.Feedback type="invalid">
                    {formik1.errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Select your campaign type</Form.Label>
                <Form.Select
                  name="type"
                  aria-label="Default select example"
                  value={formik1.values.type}
                  onChange={formik1.handleChange}
                  isInvalid={formik1.touched.type && !!formik1.errors.type}
                >
                  <option value="">Select</option>
                  {campaignTypeOptions.map((v: any) => {

                    if (!smsFeature && v.value === CampaignType.SMS) {
                      return null;
                    }

                    return (
                      <option key={v.key} value={v.value}>{v.text}</option>
                    )
                  })}
                </Form.Select>
                {formik1.errors.type && (
                  <Form.Control.Feedback type="invalid">
                    {formik1.errors.type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Button
                variant="primary"
                className="btn-theme btn-no-border"
                disabled={loading}
                onClick={() => {
                  formik1.setFieldValue("isCreateCampaign", false);
                  formik1.handleSubmit();
                }}
              >
                {loading ? "Loading..." : "Save Draft"}
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="btn-theme"
                disabled={loading}
              >
                {loading ? "Loading..." : `${campaignId ? 'Update' : 'Save'} & Continue`}
                <FontAwesomeIcon icon={faPlay} />
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {step === 2 && (
        <div className="campaign-fields">
          <Form onSubmit={formik2.handleSubmit} noValidate>
            <div className="stepper">
              <ul className="d-flex justify-content-between align-items-center">
                <li className="text-center active">
                  <span className="step d-inline-block">1</span>
                  <span className="d-block step-name">Recipients</span>
                </li>
                <li className="active">
                  <span className="border-line"></span>
                </li>
                <li className="text-center">
                  <span className="step d-inline-block">2</span>
                  <span className="d-block step-name">Content</span>
                </li>
                <li>
                  <span className="border-line"></span>
                </li>
                <li className="text-center">
                  <span className="step d-inline-block">3</span>
                  <span className="d-block step-name">Review</span>
                </li>
              </ul>
            </div>
            <div className="sending-fields">
              <div className="card">
                <div className="d-flex align-items-center justify-content-between content">
                  <div className="text">
                    <span>To</span>
                    <p>Who are you sending this {CampaignTypeValue} to?</p>
                  </div>
                  <div className="select-boxes d-flex">
                    <div className="item">
                      <Form.Select
                        aria-label="Default select example"
                        name="toListType"
                        value={formik2.values.toListType || ""}
                        onChange={(e) => {
                          formik2.handleChange(e)
                          formik2.setFieldValue('toListId', []);
                        }}
                        isInvalid={
                          formik2.touched.toListType &&
                          !!formik2.errors.toListType
                        }
                      >
                        <option value="">Select</option>
                        {toListTypeOptions.map((v) => (
                          <option key={v.key} value={v.value}>{v.text}</option>
                        ))}
                      </Form.Select>
                      {formik2.errors.toListType && (
                        <Form.Control.Feedback type="invalid">
                          {formik2.errors.toListType}
                        </Form.Control.Feedback>
                      )}
                    </div>

                    <div >
                      {(toListType === 1) && (
                        <div className="item" > 
                        <Form.Group className="form-group">
                          <Select
                            value={[...formik2.values.toListId]}
                            defaultValue={[...formik2.values.toListId] as OptionsOrGroups<any, never> }
                            options={Object.values(listMap)}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                              formik2.setFieldValue('toListId', selected);
                            }}
                            onBlur={formik2.handleBlur}
                            className="campaign-dropdown-list"
                          />
                        </Form.Group>
                        </div>
                      )}

                      {toListType === 2 && (
                        <div className="item" > 
                        <Form.Group className="form-group">
                          <Select
                            value={[...formik2.values.toListId]}
                            defaultValue={[...formik2.values.toListId] as OptionsOrGroups<any, GroupBase<any>> }
                            options={Object.values(segmentMap)}
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(selected) => {
                              formik2.setFieldValue('toListId', selected);
                            }}
                            className="campaign-dropdown-list"
                          />
                        </Form.Group>
                        </div>
                      )}
                      {(formik2.errors.toListId && (toListType === 2 || toListType === 1)) && (
                        <Form.Text className='text-danger error-text'>{formik2.errors.toListId && formik2.touched.toListId && <div className="text-danger error-text">{formik2.errors.toListId as ReactNode}</div>}</Form.Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="d-flex align-items-center justify-content-between content-sm">
                  <div className="text">
                    <span>From</span>
                    <p>Who is sending this {CampaignTypeValue}?</p>
                  </div>
                  {CampaignTypeValue === CampaignType.EMAIL ? (
                  <div className="item">
                    <Form.Select
                      aria-label="Default select example"
                      name="fromEmailDomain"
                      onChange={formik2.handleChange}
                      value={formik2.values.fromEmailDomain || ""}
                      isInvalid={
                        formik2.touched.fromEmailDomain &&
                        !!formik2.errors.fromEmailDomain
                      }
                    >
                      <option value="">Select</option>
                      {domains.map((v) => (
                        <option key={`domain-${v.value}`} value={v.value}>{v.text}</option>
                      ))}
                    </Form.Select>
                    {formik2.errors.fromEmailDomain && (
                      <Form.Control.Feedback type="invalid">
                        {formik2.errors.fromEmailDomain}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  ) : (
                    <div className="item">
                    <Form.Select
                      aria-label="Default select example"
                      name="fromSmsNumber"
                      onChange={formik2.handleChange}
                      value={formik2.values.fromSmsNumber || ""}
                      isInvalid={
                        formik2.touched.fromSmsNumber &&
                        !!formik2.errors.fromSmsNumber
                      }
                    >
                      <option value="">Select</option>
                      {activeNumbers?.map((v) => (
                        <option key={v.value} value={v.value}>{v.text}</option>
                      ))}
                    </Form.Select>
                    {formik2.errors.fromSmsNumber && (
                      <Form.Control.Feedback type="invalid">
                        {formik2.errors.fromSmsNumber}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="d-flex align-items-center justify-content-between content-sm">
                  <div className="text">
                    <span>Schedule Time</span>
                    <p>Send now </p>
                  </div>
                  <div className="item date-time-picker">
                    <Form.Control
                      type="datetime-local"
                      name="scheduleTime"
                      value={formik2.values.scheduleTime}
                      onChange={(e) => {
                        const selectedDateTime = e.target.value;
                        if (new Date(selectedDateTime) < new Date()) {
                          toaster({message: "you can't select past time", success: false})
                          return
                        }
                        formik2.setFieldValue("scheduleTime", selectedDateTime);
                      }}
                      min={formatDateToYYYYMMDDTHHMM()}
                      className="form-control"
                      style={{ width: "230px" }}
                      isInvalid={
                        formik2.touched.scheduleTime &&
                        !!formik2.errors.scheduleTime
                      }
                    />
                    {formik2.errors.scheduleTime && (
                      <Form.Control.Feedback type="invalid">
                        {formik2.errors.scheduleTime}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="d-flex align-items-center justify-content-between content">
                  <div className="text">
                    <span>Select Bulk {CampaignTypeValue} Limit</span>
                    <Form.Group controlId="formBasicRadiobox" className='d-flex align-items-center custom-radio-btns gap-3'>
                        <Form.Check
                          type="radio"
                          label="Send as fast"
                          className="d-flex align-items-center"
                          name="bulkEmailsLimitType"
                          value="1"
                          id="send_as_fast_radio"
                          checked={!formik2.values.bulkEmailsLimitType || formik2.values.bulkEmailsLimitType === 1}
                          onChange={() => {
                            formik2.setFieldValue("bulkEmailsLimitType", 1);
                          }}
                        />
                        <Form.Check
                          type="radio"
                          label={`Send ${CampaignTypeValue} with limit`}
                          className="d-flex align-items-center"
                          name="bulkEmailsLimitType"
                          value="2"
                          id="send_emails_with_limit_radio"
                          checked={formik2.values.bulkEmailsLimitType === 2}
                          onChange={() => {
                            formik2.setFieldValue("bulkEmailsLimitType", 2);
                          }}
                        />
                    </Form.Group>
                  </div>

                  {formik2.values.bulkEmailsLimitType === 2 && (
                    <div className="select-boxes d-flex">
                      <div className="item">
                        <Form.Control
                          type="text"
                          name="limit"
                          value={formik2.values.limit}
                          placeholder={`Add send ${CampaignTypeValue} limit`}
                          onChange={(e) => {
                            formik2.setFieldValue("limit", e.target.value);
                          }}
                          isInvalid={
                            formik2.touched.limit &&
                            !!formik2.errors.limit
                          }
                          className="dark-border-input"
                        />
                          <Form.Control.Feedback type="invalid">
                            {formik2.errors.limit}
                          </Form.Control.Feedback>
                      </div>

                      <div className="item">
                        <Form.Select
                          aria-label="Default select example"
                          name="delayUnit"
                          onChange={(e) => {
                            formik2.setFieldValue("delayUnit", e.target.value);
                          }}
                          value={formik2.values.delayUnit}
                          isInvalid={
                            formik2.touched.delayUnit &&
                            !!formik2.errors.delayUnit
                          }
                        >
                          <option value="">Select</option>
                          <option value={TimeUnit.MINUTES}>Minutes</option>
                          <option value={TimeUnit.HOURS}>Hours</option>
                          <option value={TimeUnit.DAYS}>Days</option>
                        </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {formik2.errors.delayUnit}
                          </Form.Control.Feedback>
                      </div>

                      {delayUnit && (
                        <div className="item">
                          <Form.Control
                            type="text"
                            name="delayValue"
                            value={formik2.values.delayValue}
                            placeholder="Enter delay value"
                            onChange={(e) => {
                              formik2.setFieldValue("delayValue", e.target.value);
                            }}
                            isInvalid={
                              formik2.touched.delayValue &&
                              !!formik2.errors.delayValue
                            }
                            className="dark-border-input"
                          />
                            <Form.Control.Feedback type="invalid">
                              {formik2.errors.delayValue}
                            </Form.Control.Feedback>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="btns">
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  onClick={handleBack}
                  variant="primary"
                  className="btn-theme btn-border"
                >
                  <FontAwesomeIcon icon={faPlay} />
                  Back
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-theme"
                  disabled={loading}
                >
                  {loading ? "Loading..." : `${campaignId ? 'Update' : 'Save'} & Continue`}
                  <FontAwesomeIcon icon={faPlay} />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}

      {step === 3 && (
        <div className="campaign-fields">
          <Form onSubmit={formik3.handleSubmit} noValidate>
            <div className="stepper">
              <ul className="d-flex justify-content-between align-items-center">
                <li className="text-center done">
                  <span className="step d-inline-block">
                    <svg
                      width="27"
                      height="20"
                      viewBox="0 0 27 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="d-block step-name">Recipients</span>
                </li>
                <li className="active">
                  <span className="border-line"></span>
                </li>
                <li className="text-center active">
                  <span className="step d-inline-block">2</span>
                  <span className="d-block step-name">Content</span>
                </li>
                <li className="active">
                  <span className="border-line"></span>
                </li>
                <li className="text-center">
                  <span className="step d-inline-block">3</span>
                  <span className="d-block step-name">Review</span>
                </li>
              </ul>
            </div>
            <div className="sending-fields">
              <Row>
                  {CampaignTypeValue === CampaignType.EMAIL && (
                <Col xxl={6} xl={12} lg={12} md={12} sm={12}>
                  <div className="card">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text">
                        <span>Subject</span>
                        <p>What’s the subject line for this email?</p>
                      </div>
                      {/* <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme btn-border"
                    >
                      Add Subject
                    </Button> */}
                    </div>
                    <div className="add-subject d-flex align-items-center justify-content-between">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="subject-field"
                      >
                        <Form.Control
                          type="text"
                          name="subject"
                          placeholder="Enter Subject"
                          value={formik3.values.subject}
                          onChange={formik3.handleChange}
                          isInvalid={
                            formik3.touched.subject && !!formik3.errors.subject
                          }
                          className="dark-border-input"
                        />
                        {formik3.errors.subject && (
                          <Form.Control.Feedback type="invalid">
                            {formik3.errors.subject}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      {/* <div className="btns">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-theme"
                      >
                        Save
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-theme btn-border"
                      >
                        Cancel
                      </Button>
                    </div> */}
                    </div>
                  </div>
                </Col>
                  )}

                <Col xxl={6} xl={12} lg={12} md={12} sm={12}>
                  <div className="card">
                    <div>
                      <div className="text">
                        <span>Select From Library</span>
                        <p>Select {CampaignTypeValue} from library.</p>
                      </div>
                      <div className="choose-library-dropdown">
                        <MultiLevelDropdown
                          data={templateWithFolderList}
                          onSelect={handleTemplateSelect}
                          value={formik3.values.templateId}
                        />
                        <Form.Text className='text-danger error-text'>
                          {formik3.touched.templateId && formik3.errors.templateId}
                        </Form.Text>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>
            </div>
            <div className="template-options">
              {/* <Row>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <div className="card align-items-center">
                  <Image src={IMAGES.Icon1} />
                  <div className="text">
                    <span>Use a Template</span>
                    <p>
                      Browse your saved templates or get started with one of our
                      theme.
                    </p>
                  </div>
                  <Button variant="primary" type="submit" className="btn-theme">
                    Get Started
                  </Button>
                </div>
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <div className="card align-items-center">
                  <Image src={IMAGES.Icon2} />
                  <div className="text">
                    <span>Start from Scratch</span>
                    <p>
                      Keep things simple and personal according to your needs.
                    </p>
                  </div>
                  <Button variant="primary" type="submit" className="btn-theme">
                    Get Started
                  </Button>
                </div>
              </Col>
              <Col xxl={4} xl={6} lg={6} md={6} sm={12}>
                <div className="card align-items-center">
                  <Image src={IMAGES.Icon3} />
                  <div className="text">
                    <span>Import HTML</span>
                    <p>
                      Upload a custom coded email, either from your computer or
                      from the web.
                    </p>
                  </div>
                  <Button variant="primary" type="submit" className="btn-theme">
                    Get Started
                  </Button>
                </div>
              </Col>
            </Row> */}
            </div>
            <div className="btns">
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  onClick={handleBack}
                  variant="primary"
                  className="btn-theme btn-border"
                >
                  <FontAwesomeIcon icon={faPlay} />
                  Back
                </Button>
                <Button variant="primary" type="submit" className="btn-theme">
                  Continue to Review
                  <FontAwesomeIcon icon={faPlay} />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
      {step === 4 && (
        <div className="campaign-fields">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik1.setFieldValue("isCreateCampaign", false);
              formik3.handleSubmit();
            }}
            noValidate
          >
            <div className="stepper">
              <ul className="d-flex justify-content-between align-items-center">
                <li className="text-center done">
                  <span className="step d-inline-block">
                    <svg
                      width="27"
                      height="20"
                      viewBox="0 0 27 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="d-block step-name">Recipients</span>
                </li>
                <li className="active">
                  <span className="border-line"></span>
                </li>
                <li className="text-center done">
                  <span className="step d-inline-block">
                    <svg
                      width="27"
                      height="20"
                      viewBox="0 0 27 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span className="d-block step-name">Content</span>
                </li>
                <li className="active">
                  <span className="border-line"></span>
                </li>
                <li className="text-center active">
                  <span className="step d-inline-block">3</span>
                  <span className="d-block step-name">Review</span>
                </li>
              </ul>
            </div>
            <div className="template-preview">
              <Row className="justify-content-center">
                <Col xxl={10} xl={10} lg={10} md={10} sm={12}>
                  <div className="card align-items-center">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: templates[formik3?.values?.templateId]?.data
                          ?.content ?? <></>,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="btns">
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  onClick={handleBack}
                  variant="primary"
                  className="btn-theme btn-border"
                >
                  <FontAwesomeIcon icon={faPlay} />
                  Back
                </Button>
                <XButton
                  type="submit"
                  buttonText={<>Send
                    <FontAwesomeIcon icon={faPlay} /></>}
                  loading={loading}
                />
              </div>
            </div>
          </Form>
        </div>
      )}

      {/* --------- create campaign ends --------------------- */}
    </div>
  );
};

export default CreateCampaign;
