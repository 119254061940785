import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './modules/auth/authSlice';
import accountSetupReducer from './modules/auth/accountSetupSlice';
import locationReducer from './modules/auth/locationSlice';
import compaignReducer from './modules/compaigns/compaignSlice';
import userReducer from './modules/auth/userSlice';
import contactReducer from './modules/contacts/contactSlice';
import listsReducer from './modules/lists/listsSlice';
import settingReducer from './modules/settings/settingsSlice'
import sidenavReducer from './modules/sideNav/sideNavSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  accountSetup:accountSetupReducer,
  location:locationReducer,
  campaigns:compaignReducer,
  user: userReducer,
  contactlist:contactReducer,
  lists:listsReducer,
  settings:settingReducer,
  sidenav:sidenavReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;