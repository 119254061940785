import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import XButton from './XButton';

interface XButtonProps {

}

const XBackButton: FC<XButtonProps> = ({
}) => {
  const navigate = useNavigate()
  return (
    <XButton onClick={() => { navigate(-1) }} buttonText={<span><FontAwesomeIcon icon={faPlay} />Back</span>} className='btn-theme btn-border' />
  );
};

export default XBackButton;
