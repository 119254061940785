/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import { getMonthName } from "../../utils/helper";

type EmailStats = {
  delivered: number;
  opened: number;
  clicked: number;
  bounce: number;
  softBounce: number;
  hardBounce: number;
  unsubscribed: number;
  openedRate: number;
  clickedRate: number;
  bounceRate: number;
  unsubscribedRate: number;
};

type CampaignGraphAnalyticsType = {
  month: string
  delivered: number;
  opened: number;
  clicked: number;
  bounce: number;
  softbounce: number;
  hardbounce: number;
  unsubscribed: number;
}
type TotalStats = {
  delivered: number;
  opened: number;
  clicked: number;
  contacts: string;
  openedRate: number;
  clickedRate: number;
  bounceRate: number;
  unsubscribedRate: number;
};

type AnalyticDataType = {
  email: EmailStats;
  total: TotalStats;
  campaignGraphAnalytics: CampaignGraphAnalyticsType
};

type FilterType = {
  dateRange: "lastWeek" | "lastMonth" | "lastYear" | "custom";
  from?: string;
  to?: string;
};

const useDashboard = () => {
  const { postData, isLoading, error } = useAxios();
  const [filter, setFilter] = useState<FilterType>({dateRange:'lastWeek'})
  const [analyticData, setAnalyticData] = useState<AnalyticDataType>();
  const [labels, setLabels] = useState<string[]>([]);
  const [deliveryDataset, setDeliveryDataset] = useState<number[]>([]);
  const [openDataset, setOpenDataset] = useState<number[]>([]);

  useEffect(() => {
    if (filter.dateRange && ((filter.dateRange !== 'custom' || (filter.from && filter.to)))) {
      fetchAnalyticData();
    }
  }, [filter]);

  const fetchAnalyticData = async () => {
    const response = await postData(API_ENDPOINTS.GET_DASHBOARD_ANALYTIC, {
      filter,
    });
    if (response?.success) {
      setAnalyticData(response?.data);
      transformData(response?.data.campaignGraphAnalytics)
    }
  };

  const transformData = (rawData: CampaignGraphAnalyticsType[]) => {
    const labels: string[] = [];
    const deliveriesData: number[] = [];
    const opensData: number[] = [];

    rawData?.forEach(item => {
        const monthNumber = parseInt(item.month.split('-')[1]);
        labels.push(getMonthName(monthNumber));
        const totalEmail = item.delivered + item.softbounce + item.hardbounce
        deliveriesData.push((item.delivered/ totalEmail) * 100);
        opensData.push((item.opened / item.delivered) * 100);
    });

    setLabels(labels)
    setDeliveryDataset(deliveriesData)
    setOpenDataset(opensData)

}


  const handleFilterChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target
    if (name === 'dateRange' && value !== 'custom') {
      setFilter({'dateRange': value})
    }else{
      setFilter((preFilter)=>({...preFilter,[name]: value}))
    }
  };


  return {
    analyticData,
    isLoading,
    error,
    handleFilterChange,
    filter,
    labels,
    deliveryDataset,
    openDataset,
  };
};

export default useDashboard;
