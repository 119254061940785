import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign } from "../auth/types";

interface CampaignState {
  campaignlist: any;
  loading: boolean;
  error: any;
  step: number;
  campaignId: string;
  message: string;
  campaignEditData: Campaign | null;
}
interface GetCampaignsRequestParams {
  status?: string;
  type?: string;
  page: number;
  limit: number;
  search?: string;
  sortBy?: string;
}

const initialState: CampaignState = {
  campaignlist: [],
  loading: false,
  error: null,
  step: 0,
  campaignId: "",
  message: '',
  campaignEditData: null
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    getCampaignsRequest(
      state,
      action: PayloadAction<GetCampaignsRequestParams>
    ) {
      state.loading = true;
      state.error = null;
    },
    getCampaignsSuccess(state, action: PayloadAction<any>) {
      state.campaignlist = action.payload;
      state.loading = false;
      state.error = null;
    },
    getCampaignsFailure(state, action: PayloadAction<string>) {
      state.campaignlist = [];
      state.loading = false;
      state.error = action.payload;
    },
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    }, 
    setCampaignEditData(state, action: PayloadAction<Campaign|null>) {
      state.campaignEditData = action.payload;
    },    
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    setcampaignId(state, action: PayloadAction<string>) {
      state.campaignId = action.payload;
    },
    saveCampaign(state, action: PayloadAction) {
      state.loading = true;
      state.error = null;
    },
    saveCampaignSuccess(state, action: PayloadAction<any>) {
      const { success, message, data: { id, from_email_domain, number_id, subject, template_id} } = action.payload
      state.loading = false;
      state.error = null;
      if (success) {
        state.campaignId = id;
        if (state.step === 1) {
          state.campaignEditData = {...state.campaignEditData, number_id, from_email_domain, template_id, subject} as Campaign
        }
        if (state.step === 4) {
          state.message = message;
          state.campaignId = "";
          state.step = 0;
        } else {
          state.step = state.step + 1;
        }
      }
    },
    saveCampaignFailure(state, action: PayloadAction<any>) {
      const { success, message } = action.payload
      state.loading = false;
      if (!success) {
        state.error = message;
      }
      
    },
  },
});

export const {
  getCampaignsRequest,
  getCampaignsSuccess,
  getCampaignsFailure,
  setStep,
  setcampaignId,
  saveCampaign,
  saveCampaignSuccess,
  saveCampaignFailure,
  setMessage,
  setCampaignEditData
} = campaignSlice.actions;

export default campaignSlice.reducer;
