import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { IMAGES } from "../constants/imagePath";
import useAxios from "../hooks/useAxios";
import { API_ENDPOINTS } from "../constants/api";
import { useParams } from "react-router-dom";
import { toaster } from "../utils/toast";
import XButton from "../component/button/XButton";
import { decodeJwt } from "../utils/helper";
import { useState } from "react";

const Unsubscribe = () => {
  const { postData, isLoading } = useAxios();
  const { unsubscribeToken } = useParams();
  const { email } = decodeJwt(unsubscribeToken);
  const [successStatus, setSuccessStatus] = useState("");

  const onUnsubscribe = async () => {
    const response = await postData(API_ENDPOINTS.UNSUBSCRIBE, {
      unsubscribeToken,
    });
    const { message, success } = response;
    setSuccessStatus(success);
    toaster({ message, success });
  };

  return (
    <div>
      <div className="signup-process">
        <Container>
          <div className="content">
            <Image src={IMAGES.blueLogo} className="top-logo" />
            <div className="card">
              <h2 className="card-title">Unsubscribe Your Email</h2>
              <div className="text-center">
                <h5>{email}</h5>
                <p className="mb-4">
                  {!successStatus
                    ? "Are you sure that you want to unsubscribe?"
                    : "This address has been unsubscribed. Thank you."}
                </p>
                {!successStatus && (
                  <XButton
                    variant="primary"
                    type="submit"
                    buttonText="Yes, Unsubscribe"
                    loading={isLoading}
                    onClick={onUnsubscribe}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Unsubscribe;
