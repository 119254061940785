/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Container ,Row ,Col,Button,Form ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IMAGES } from "../../../constants/imagePath";
import { ResetFormValues } from "../../../redux/modules/auth/types";
import { RootState } from "../../../redux/rootReducer";
import { resetPasswordRequest } from "../../../redux/modules/auth/authSlice";
import Loader from "../../../component/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ForgetPassword: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {error ,resetPasswordRes ,loading} = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if(resetPasswordRes || error)formik.resetForm();
      }, [error ,resetPasswordRes])

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik<ResetFormValues>({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values: ResetFormValues) => {
    const { email } = values;
    dispatch(resetPasswordRequest({ email }));
    },
  });  

  return (
    <div>
      <div className="login card-bg">
        <div className="card">
          <Container>
            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="bg-blue d-flex align-items-center"
              >
                <div className="lt-text text-center">
                  <Image src={IMAGES.darkLogo} />
                  <h1 className="title">
                    Improve patient engagement with personalized campaigns
                  </h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className="bg-white">
                <div className="rt-content">
                  <div className="heading-title">
                    <h2>Forget Password?</h2>
                    <p>No worries, we'll help you reset password.</p>
                  </div>
                  {error && <div className="error-box">{error}</div>}
                  {resetPasswordRes?.message && <div className="error-box">{resetPasswordRes?.message}</div>}
                  <Form
                    className="input-form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <Form.Group controlId="formBasicEmail" className="form-group">
                      <Form.Control type="email" name='email' placeholder="Enter email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                      required
                      />
                      <Form.Control.Feedback type="invalid">
                        Email is Required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme w-100"
                      disabled={loading}
                    >
                      {loading && <Loader/>}
                      Continue
                    </Button>
                  </Form>
                  <p className="text-center btm-text mb-0">
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to{" "}
                    <span className="text-blue underline cursor-pointer"
                    onClick={() => navigate("/login")}
                   >
                      Login
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
