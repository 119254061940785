/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { XModal } from "../../component";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import SingleAnalyticsUI from "../../component/singleAnalyticsUI/SingleAnalyticsUI";

interface SingleJourneyAnalyticsProps {
  selectedJourneyId: number;
  setSelectedJourneyId: any;
}

const SingleJourneyAnalytics: React.FC<SingleJourneyAnalyticsProps> = ({
  selectedJourneyId,
  setSelectedJourneyId,
}) => {
  const [journeyAnalyticsModalShow, setJourneyAnalyticsModalShow] =useState<boolean>(false);
  const [analyticsData, setAnalyticsData] = useState<any>([]);
  const { postData, isLoading } = useAxios();

  useEffect(() => {
    if (selectedJourneyId) {
      setJourneyAnalyticsModalShow(true);
      fetchAnalytics(selectedJourneyId);
    }
  }, [selectedJourneyId]);

  const fetchAnalytics = async (journeyId: any) => {
    const response = await postData(
      API_ENDPOINTS.JOURNEY_ANALYTICS_DETAILS_BY_ID,
      { journeyId }
    );
    setAnalyticsData({
      analytics: response?.data?.selectedJourneyAnalytics,
      contacts: response?.data?.selectedJourneyContacts
    })
  };

  return (
    <XModal
      show={journeyAnalyticsModalShow}
      handleClose={() => {
        setJourneyAnalyticsModalShow(false);
        setSelectedJourneyId("");
      }}
      title={"Journey Analytics"}
      confirmText={"Close"}
      handleConfirm={() => {
        setJourneyAnalyticsModalShow(false);
        setSelectedJourneyId("");
      }}
      body={
        <SingleAnalyticsUI
          data={analyticsData}
          title={"Journey Name"}
          type={"Journey Type"}
          loader={isLoading}
        />
      }
    />
  );
};

export default SingleJourneyAnalytics;
