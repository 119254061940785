import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSliceState } from './types'; 

const initialState: UserSliceState = {
  locations: [],
  orgLocations: [],
  orgLocationMap: null,
  selectedLocationId: localStorage.getItem('locationId') ?? '',
  permissions: [],
  role_id: 0,
  loading: false,
  smsFeature: false,
  error: '',
  first_name: '',
  last_name: '',
  userId: 0,
  orgId: 0,
  email: '',
  business_name: '',
  phone_number: '',
  website_url: '',
  filename: '',
  logoUrl: '',
  created_date: '',
  question_id: '',
  two_factor_answer: '',
};

const locationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getAllLocations: (state) => {
      state.loading = true;
    },
    getAllLocationSuccess: (state, action: PayloadAction<any>) => {
      const { data, error } = action.payload;
      state.locations = data ?? [];
      state.loading = false;
      state.error = error; // Clear error on success
      //set default locationId
      localStorage.setItem("locationId", data[0]?.id ?? "");
    },
    getAllLocationFailure: (state, action: PayloadAction<any>) => {
      state.locations = [];
      state.loading = false;
      state.error = action.payload.message;
      localStorage.setItem("locationId", "");
    },
    setUserData: (state, action: PayloadAction<any>) => {
      const {
        permissions,
        first_name,
        last_name,
        role_id,
        orgLocations,
        userId,
        orgId,
        email,
        business_name,
        phone_number,
        website_url,
        filename,
        logoUrl,
        created_date,
        locations,
        question_id,
        two_factor_answer,
        smsFeature
      } = action.payload;
      const orgLocationMap = orgLocations?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      if (permissions) {
        state.permissions = permissions;
      }
      state.locations = locations;
      state.orgLocations = orgLocations;
      state.orgLocationMap = orgLocationMap;
      state.role_id = +role_id;
      state.first_name = first_name;
      state.last_name = last_name;
      state.userId = userId;
      state.orgId = orgId;
      state.email = email;
      state.business_name = business_name;
      state.phone_number = phone_number;
      state.website_url = website_url;
      state.filename = filename;
      state.logoUrl = logoUrl;
      state.created_date = created_date;
      state.question_id = question_id;
      state.two_factor_answer = two_factor_answer;
      state.smsFeature = smsFeature;
    },
    setSelectedLocationId: (state, action: PayloadAction<string>) => {
      state.selectedLocationId = action.payload;
    },
  },
});

export const { getAllLocations, getAllLocationSuccess, getAllLocationFailure, setUserData, setSelectedLocationId } = locationSlice.actions;
export default locationSlice.reducer;
