import { useSelector } from "react-redux";

function useAuthorization() {
  const { permissions, role_id } = useSelector((state) => state.user);
  
  const isAuthorize = (module_id, action) => {
    // return true
    if (permissions[module_id] && permissions[module_id][action]) {
      return true
    } else {
      return false
    }
  }

  return { isAuthorize, role_id, permissions }
}

export default useAuthorization