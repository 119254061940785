/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { optionMaker } from '../utils/options';

const useLocationData = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { fetchData } = useAxios()

  const fetchCountries = async () => {
    try {
      setLoading(true)
      const response = await fetchData(API_ENDPOINTS.GET_COUNTRIES);
      setCountries(optionMaker(response.data));
    } catch (error) {
      setError('Error fetching countries');
    } finally {
      setLoading(false)
    }
  };

  const fetchStates = async (countryId) => {
    try {
      setLoading(true)
      const response = await fetchData(`${API_ENDPOINTS.GET_STATES}/${countryId}`);
      setStates(optionMaker(response.data));
    } catch (error) {
      setError('Error fetching states');
    } finally {
      setLoading(false)
    }
  };

  const fetchCities = async (stateId) => {
    try {
      setLoading(true)
      const response = await fetchData(`${API_ENDPOINTS.GET_CITIES}/${stateId}`);
      setCities(optionMaker(response.data));
    } catch (error) {
      setError('Error fetching cities');
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchCountries()
  }, []);

  return {
    countries,
    states,
    cities,
    error,
    loading,
    fetchCities,
    fetchCountries,
    fetchStates,
  };
};

export default useLocationData;
