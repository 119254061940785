export const IMAGES = {
  bgImage: require('../assets/images/BG.png'),
  calenderIcon: require('../assets/images/calender-icon.png'),
  darkLogo: require('../assets/images/logo-dark-blue.png'),
  blueLogo: require('../assets/images/logo-blue.png'),
  verify: require('../assets/images/verify.png'),
  profile: require('../assets/images/profile.png'),
  DeliveriesIcon: require('../assets/images/Deliveries-Icon.png'),
  OpenRateIcon: require('../assets/images/Open-Rate-Icon.png'),
  ClickRateIcon: require('../assets/images/Click-Rate-Icon.png'),
  ContactsIcon: require('../assets/images/Contacts-Icon.png'),
  UnsubscribeIcon: require('../assets/images/Unsubscribe-Rate-Icon.png'),
  BounceIcon: require('../assets/images/Bounce-Rate-Icon.png'),
  Icon1: require('../assets/images/Icon1.png'),
  Icon2: require('../assets/images/Icon2.png'),
  Icon3: require('../assets/images/Icon3.png'),
  template: require('../assets/images/template.png'),
  iconMail: require('../assets/images/icon-mail.png'),
  iconSMS: require('../assets/images/icon-sms.png'),
  iconOpenMail: require('../assets/images/icon-opened-mail.png'),
  iconClickLink: require('../assets/images/icon-clicked-link.png'),
  iconJourney: require('../assets/images/icon-journey.png'),
  iconUnsubscribed: require('../assets/images/icon-unsubscribed.png'),
  previewThumbnail: require('../assets/images/preview-thumbnail.png'),
  smsPreviewThumbnail: require('../assets/images/sms-preview.png'),
  businessLogo: require('../assets/images/business-logo.png'),
  listBlue: require('../assets/images/list-blue.png'),
  scheduledBlue: require('../assets/images/scheduled-blue.png'),
  draftBlue: require('../assets/images/draft-blue.png'),
  completedBlue: require('../assets/images/completed-blue.png'),
  emailsBlue: require('../assets/images/emails-blue.png'),
  smsBlue: require('../assets/images/sms-blue.png'),
};