export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const tableHeadings_Predefined_Fields = ['Column Name', 'Column Type'];
export const tableHeadings_CustomFields = ['Column Name', 'Column Type', 'Actions'];

export const tableHeadings_List = ['List Name', 'Contact Count', 'Last Updated' ,'Actions'];
export const tableHeadings_Journey = ['Name of Journey', 'Opened Journeys', 'Clicked Journeys' ,'Start Date & Time' ,'Status', 'Action'];
export const tableHeadings_AuditLog = ['Sr. No.', 'Table Name', 'Change Type' ,'Change Data','Changed At'];
export const tableHeadings_Segment = ['Segment Name', 'Contact Count', 'Last Updated' ,'Actions'];

export const tableHeadings_ViewBy = [
  {
    viewType: "View By Status",
    child: [
      { icon: "listBlue", name: "All", id: "first" },
      { icon: "scheduledBlue", name: "Scheduled", id: "second" },
      { icon: "draftBlue", name: "Draft", id: "third" },
      { icon: "completedBlue", name: "Completed", id: "fourth" },
    ],
  },
  // {
  //   viewType: "View By Type",
  //   child: [
  //     { icon: "emailsBlue", name: "Emails", id: "fifth" },
  //     { icon: "smsBlue", name: "SMS", id: "sixth" },
  //   ],
  // },
];


