import { FC } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

interface Location {
    location_id: number;
    country_id: number;
    country_name: string;
    state_id: number;
    state_name: string;
    city: number | null;
    city_name: string | null;
    address: string | null;
    zipcode: string | null;
}

interface LocationsAccordianProps {
    locations: Location[];
}

const LocationsAccordian: FC<LocationsAccordianProps> = (props) => {
    const { locations } = props
    return (
        <Accordion defaultActiveKey="0">
            {locations.map((v, k) => {
                return <Accordion.Item eventKey={k.toString()} key={v.location_id}>
                    <Accordion.Header>Location {k + 1}</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>Address</span>
                                        <span className='text-btm'>{v?.address ?? 'N/A'}</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>City</span>
                                        <span className='text-btm'>{v?.city ?? 'N/A'}</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>State</span>
                                        <span className='text-btm'>{v?.state_name ?? 'N/A'}</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>Zip Code</span>
                                        <span className='text-btm'>{v?.zipcode ?? 'N/A'}</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>Country</span>
                                        <span className='text-btm'>{v?.country_name ?? 'N/A'}</span>
                                    </li>
                                </ul>
                            </Col>
                            {/* <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>Total Contacts Per Company</span>
                                        <span className='text-btm'>258</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col xxl={4} xl={6} lg={6} md={6} sm={6} xs={12}>
                                <ul>
                                    <li>
                                        <span className='text-tp'>Contacts Minus Unsubscribes</span>
                                        <span className='text-btm'>140</span>
                                    </li>
                                </ul>
                            </Col> */}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            })}
        </Accordion>
    );
};

export default LocationsAccordian;
