import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useSidebar from "../../../hooks/useSidebar";
import ActiveNumbers from "./ActivateNumbers";
import NumbersBuy from "./NumbersBuy";

const Numbers: FC = () => {
  const { onShowSidebar } = useSidebar();
  const [activeTab, setActiveTab] = useState<string>("active-numbers");

  return (
    <div className="numbers-tab">
      <div className="main-title">
        <h2>
          <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Numbers
        </h2>
      </div>
      <div className="contact-details setting-domains">
        <div className="info-box">
          <h2>Numbers For SMS Campaigning</h2>
          <p>
            Get Numbers instantly to start sending SMS messages and improve
            customer engagement with seamless communication.
          </p>
          <Nav
            activeKey={activeTab}
            onSelect={(selectedKey) => selectedKey && setActiveTab(selectedKey)}
            justify
          >
            <Nav.Item>
              <Nav.Link eventKey="active-numbers">Active Numbers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="numbers-available-to-buy">
                Numbers Available To Buy
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {activeTab === "active-numbers" && <ActiveNumbers />}
          {activeTab === "numbers-available-to-buy" && <NumbersBuy />}
        </div>
      </div>
    </div>
  );
};

export default Numbers;
