import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../redux/rootReducer";

interface ProtectedRouteProps {
  proctedRoute?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken === undefined || !authToken) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
