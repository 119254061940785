import { faArrowsSplitUpAndLeft, faClock, faEnvelope, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import JourneyConditionDelayModal from './JourneyConditionDelayModal';
import JourneyConditionModal from './JourneyConditionModal';
import JourneyEmail from './JourneyEmail';
import { setParentIdHandlerType } from './useJourneyBuilder';
import useJourneyStepModal, { StepPayloadType } from './useJourneyStepModal';
import { JourneyContext } from './JourneyContext';
import { JourneyAction } from '../../utils/enums';
import { RootState } from '../../redux/rootReducer';
import { useSelector } from 'react-redux';

type Props = {
    parentId: number
    setParentIdHandler: (payload : setParentIdHandlerType) => void
    addStep: (payload: StepPayloadType[]) => void
    isRule: boolean
    updateStep:  (updatedStep: any) => void
    templates: any[]
}

const JourneyStepModal: FC<Props> = (props) => {
    const { parentId, setParentIdHandler, addStep, isRule, updateStep, templates } = props
    const { selectedNode, currentId, triggerData, smsTempalate, parentNode } = useContext(JourneyContext)
    const { conditionDelayModal, conditionDelayModalClickHandler, conditionModal, conditionModalClickHandler, stepPayload, updateStepPayloadHandler, conditions, actions, actionId, closeEmailModal, actionClickHandler } = useJourneyStepModal({updateStep: updateStep})
    const { smsFeature } = useSelector((state: RootState) => state.user);

    return (
        <div>
            {conditionModal && <JourneyConditionModal
                show={conditionModal}
                stepPayload={stepPayload}
                handleClose={conditionModalClickHandler}
                updateStepPayloadHandler={updateStepPayloadHandler}
                conditions={conditions}
                addStep={addStep}
            />
            }
            {conditionDelayModal && <JourneyConditionDelayModal
                show={conditionDelayModal}
                updateStepPayloadHandler={updateStepPayloadHandler}
                handleClose={conditionDelayModalClickHandler}
                addStep={addStep}
            />
            }
            {(!!actionId && !!parentId) && <JourneyEmail
                show={!!actionId}
                handleClose={closeEmailModal}
                templates={actionId === JourneyAction.EMAIL ? templates : smsTempalate}
                actionId= {actionId}
                currentId={currentId}
                data={currentId === 1 ? (triggerData.action_id === actionId ? triggerData : {}) : (selectedNode?.data.action_id === actionId ? selectedNode?.data : {})}
                handelSubmit={(data: any)=>{
                    const { from, subject='', templateId, actionId, fromId, delaySeconds } = data
                    updateStep({data: {from, subject, templateId, fromId, delaySeconds}, action_id: actionId})
                }}
            />
            }
            {!!parentId && <div className="journey-rules-box">
                <span className='close-icon' onClick={()=>setParentIdHandler({parent: 0, current_id: 0, isRule: true})}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#EDEDED" />
                        <path d="M15.6068 7.76719C15.5492 7.70946 15.4807 7.66365 15.4054 7.6324C15.3301 7.60115 15.2493 7.58507 15.1677 7.58507C15.0862 7.58507 15.0054 7.60115 14.9301 7.6324C14.8547 7.66365 14.7863 7.70946 14.7287 7.76719L11.6834 10.8063L8.63808 7.76096C8.58042 7.7033 8.51197 7.65757 8.43664 7.62637C8.36131 7.59516 8.28057 7.5791 8.19903 7.5791C8.11749 7.5791 8.03675 7.59516 7.96142 7.62637C7.88609 7.65757 7.81764 7.7033 7.75998 7.76096C7.70233 7.81862 7.65659 7.88707 7.62539 7.9624C7.59419 8.03773 7.57812 8.11847 7.57812 8.20001C7.57813 8.28155 7.59419 8.36229 7.62539 8.43762C7.65659 8.51295 7.70233 8.5814 7.75998 8.63906L10.8053 11.6844L7.75998 14.7297C7.70233 14.7873 7.65659 14.8558 7.62539 14.9311C7.59419 15.0064 7.57812 15.0872 7.57812 15.1687C7.57812 15.2503 7.59419 15.331 7.62539 15.4063C7.65659 15.4817 7.70233 15.5501 7.75998 15.6078C7.81764 15.6654 7.88609 15.7112 7.96142 15.7424C8.03675 15.7736 8.11749 15.7896 8.19903 15.7896C8.28057 15.7896 8.36131 15.7736 8.43664 15.7424C8.51197 15.7112 8.58042 15.6654 8.63808 15.6078L11.6834 12.5625L14.7287 15.6078C14.7864 15.6654 14.8548 15.7112 14.9301 15.7424C15.0055 15.7736 15.0862 15.7896 15.1677 15.7896C15.2493 15.7896 15.33 15.7736 15.4054 15.7424C15.4807 15.7112 15.5491 15.6654 15.6068 15.6078C15.6644 15.5501 15.7102 15.4817 15.7414 15.4063C15.7726 15.331 15.7887 15.2503 15.7887 15.1687C15.7887 15.0872 15.7726 15.0064 15.7414 14.9311C15.7102 14.8558 15.6644 14.7873 15.6068 14.7297L12.5615 11.6844L15.6068 8.63906C15.8434 8.40241 15.8434 8.00384 15.6068 7.76719Z" fill="#13294B" />
                    </svg>
                </span>
                <div className={`item ${!isRule && 'disabled'}`}>
                    <p className='title'>Rules</p>
                    <ul>

                        <li className={`${parentNode?.action_id === 2 && 'disabled'}`} onClick={conditionModalClickHandler}>
                            <FontAwesomeIcon className={`${!!stepPayload.rule_type && 'text-blue'}`} icon={faArrowsSplitUpAndLeft} />
                            <span>Condition</span>
                        </li>
                        
                        <li onClick={conditionDelayModalClickHandler}>
                            <FontAwesomeIcon className={`${!!stepPayload.delaySeconds && 'text-blue'}`} icon={faClock} />
                            <span>Time Delay</span>
                        </li>

                    </ul>
                </div>
                <div className={`item ${isRule && 'disabled'}`}>
                    <p className='title'>Actions</p>
                    <ul>
                        {actions?.map((v) => {
                            const actionIcon = v.action === 'EMAIL' ? faEnvelope : faMessage;

                            const actionClassName = stepPayload.action_id === v.id ? 'text-blue' : '';

                            if (!smsFeature && v.id === 2) {
                                return null;
                            }

                            return <li key={`action-${v.id}`} onClick={() => actionClickHandler({ action_id: v.id })}>
                                <FontAwesomeIcon className={actionClassName} icon={actionIcon} />
                                <span>Send {v.action}</span>
                            </li>;
                        })}
                    </ul>
                    <div className='mt-3'></div>
                </div>
            </div>}
        </div>
    )
}

export default JourneyStepModal