import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { City, Country, LocationState, State } from './types';

const initialState: LocationState = {
  states: [],
  cities: [],
  countries:[],
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    locationRequest(state) {
    },
    setLocationData: (state, action: PayloadAction<{ states: State[]; cities: City[]; countries:Country[]}>) => {
      state.states = action.payload.states;
      state.cities = action.payload.cities;
      state.countries = action.payload.countries;
    },
  },
});

export const { setLocationData, locationRequest } = locationSlice.actions;
export default locationSlice.reducer;
