// DomainModal.tsx
import React, { FC, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../../hooks/useAxios";
import { API_ENDPOINTS } from "../../../constants/api";
import { toaster } from "../../../utils/toast";
import DnsDetailCard from "./dnsDetail";

interface VerifyDomainProps {
  show: boolean;
  handleClose: () => void;
  dnsDetail: any
}

const VerifyDomain: FC<VerifyDomainProps> = ({ show, handleClose  ,dnsDetail}) => {
  const { postData, isLoading } = useAxios();
  const [dnsDetailData, setDnsDetailData] = useState(dnsDetail);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Invalid email address'
      )
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const { email } = values;
      let payload = {
        email,
      };
      submitHandler({ payload });
    },
  });

  const submitHandler = async ({ payload }: any) => {
    try {
      const response = await postData(API_ENDPOINTS.VERIFY_DOMAIN, payload);
      if(response){
        const { message, success ,data } = response;
        toaster({ message, success });
        data.length === 0 ? handleClose() : setDnsDetailData(data);
      }
      else{
        handleClose()
        let message = "Error saving domian";
        let success = false
        toaster({ message , success });
      }
    } catch (error) {}
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="domains-modal">
      <Modal.Header closeButton>
        <Modal.Title>Add Domain</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       {!dnsDetail && <p>
        Verify Your Domain Ownership: Add TXT Records to Your Domain Registrar Console (e.g., GoDaddy, Porkbun) by Entering an Email Address ( *@yourdomain.com )
        </p>}
        {!dnsDetail && <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="form-group" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
          </Form.Group>
        </Form>}
        
       { dnsDetailData && <DnsDetailCard DnsDetailData={dnsDetailData} />
} 
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          type="button"
          className="btn-theme"
          onClick={() => {
            !dnsDetailData ? formik.handleSubmit() :handleClose();
          }}
          disabled={isLoading}
        >
          {isLoading ? "Loading…" :  !dnsDetailData ? "Add Domain" : 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VerifyDomain;
