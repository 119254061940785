import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios';


interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}
interface ApiError extends Error {
  response?: AxiosResponse;
}
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (request: AdaptAxiosRequestConfig) => {
    request.headers = request.headers || {};
    request.headers.common = request.headers.common || {};
    if (request.data instanceof FormData) {
      request.headers['Content-Type'] = 'multipart/form-data';
    }
    const authToken = localStorage.getItem('authToken');
    if (authToken) request.headers['Authorization'] = `Bearer ${authToken}`;
    request.headers['locationId'] = localStorage.getItem('locationId')
    return request;
  },
  (error: ApiError) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 208) {
        return refreshTokenAndRetryRequest(response.config);
    }
    if (response.status === 207) { // location id not found
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }  
    return response;
  },
  (error: ApiError) => {
    if (error.response?.status === 404) {
      console.log('NOT FOUND');
    }
    return Promise.reject(error);
  }
);

// Function to refresh token and retry original request
async function refreshTokenAndRetryRequest(
  config: AxiosRequestConfig
): Promise<AxiosResponse> {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("Refresh token not found");
    }

    // Call your backend API to refresh access token using refresh token
    const response = await axiosInstance.post("auth/gererate-access-token", {
      refreshToken,
    });
    const {success, data: {accessToken: newAccessToken}} = response.data;

    if (!success) {
      throw new Error('Error refreshing token')
    }

    localStorage.setItem("authToken", newAccessToken);

    // Retry original request with new access token
    const originalRequest: AxiosRequestConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${newAccessToken}`,
      },
    };

    return await axiosInstance(originalRequest);
  } catch (error) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    document.location = "/";
    return Promise.reject(error);
  }
}


// Method for handling GET requests
export const getRequest = (url: string) => {
  return axiosInstance.get(url);
};

// Method for handling POST requests
export const postRequest = (url: string, data: any) => {
  return axiosInstance.post(url, data);
};
// Method for handling PUT requests (for editing)
export const putRequest = (url: string, data: any) => {
  return axiosInstance.put(url, data);
};
//Method for handling DELETE requests
export const deleteRequest = (url: string) => {
  return axiosInstance.delete(url);
};

export default axiosInstance;
