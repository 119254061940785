import { ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';

interface XInputProps {
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  type?: string;
  value?: string;
  formik?: any
  onChange?: (event: ChangeEvent<any>) => void;
}

const XInput: FC<XInputProps> = ({ name, label = name, placeholder = 'Enter', className, type = 'text', value = '', onChange, formik, ...props }) => {

  return (
    <Form.Group className={`form-group ${className}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        {...{
          name,
          type,
          id: name,
          placeholder,
          ...(formik && {
            ...formik.getFieldProps(name),
            isInvalid: formik?.touched?.[name] && !!formik?.errors?.[name]
          }),
          ...(onChange && {
            onChange: (e)=>{
                onChange?.(e);
                if (formik) {
                    formik?.handleChange(e);
                } 
            }
        })
        }}
      />
      {formik?.errors?.[name] && (
        <Form.Control.Feedback type="invalid">
          {formik?.errors?.[name]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default XInput;
