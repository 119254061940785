/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPenToSquare, faCircleXmark, faSortUp,faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { clearData, deleteSegmentRequest, getSegmentsRequest } from '../../../redux/modules/contacts/contactSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { toast } from 'react-toastify';
import XDeleteModal from '../../../component/modal/XDeleteModal';
import useSidebar from '../../../hooks/useSidebar';
import { tableHeadings_Segment } from '../../../constants/constants';
import PaginationNav from '../../../component/pagination/pagination';
import XLoader from '../../../component/loader/XLoader';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';
import { PermissionObject } from '../../../redux/modules/auth/types';

interface SegmentProps {
  permission?: PermissionObject
}

const Segments:React.FC<SegmentProps> = (props) => {
  const { permission } = props
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const {segments,deleteSegment,loading,error} = useSelector((state: RootState) => state.contactlist);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
  });
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const { onShowSidebar } = useSidebar();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState<any| null>(null);

  // ChartJS.register(ArcElement, Tooltip, Legend);

  // export const data:any = {
  //   labels: ['Yellow', 'Blue', 'Green'],
  //   datasets: [
  //     {
  //       label: 'No. of Emails Sent',
  //       data: [33, 33, 33],
  //       backgroundColor: [
  //         'rgba(22, 93, 255, 1)',
  //         'rgba(20, 201, 201, 1)',
  //         'rgba(247, 186, 30, 1)',
  //       ],
  //     },
  //   ],
  // };
  // const chartOptions: any = {
  //   plugins: {
  //     tooltip: {
  //       titleFont: {
  //         size: 14
  //       },
  //       bodyFont: {
  //         size: 14
  //       },
  //     },
  //     legend: {
  //       display: true,
  //       responsive: true,
  //       position: "right",
  //       labels: {
  //         boxWidth: 24,
  //         boxHeight: 24,
  //         padding: 24,
  //         font: {
  //           size: 18,
  //         },
  //       },
  //       align: "center",
  //     },
  //   }
  // };
  useEffect(() => {
    dispatch(getSegmentsRequest(queryParams));
  }, [queryParams]);
  
  useEffect(() => {
    const totalSegments = segments?.totalCount;
    const totalPages = Math.ceil(totalSegments / queryParams?.limit);
    setTotalPages(totalPages);
  }, [segments?.totalCount]);

   const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleDeleteSegment=(segmentId:string)=>{
    dispatch(deleteSegmentRequest(segmentId));
    setShowDeleteModal(false)
  }

  useEffect(() => {
    if (deleteSegment && deleteSegment.success === true) {
      toast(deleteSegment.message);
      dispatch(getSegmentsRequest(queryParams));
    }
    else if (error && error.success === false) {
      toast(error.message)
    }
    dispatch(clearData());
  }, [error,deleteSegment])

  const handleEditClick=(segmentId:string)=>{
    navigate(`/org/edit-segment/${segmentId}`);
    dispatch(clearData())
  }
  const handleSortClick = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('desc');
    }
  };
  const sortedSegments = [...(segments?.segments || [])].sort((a, b) => {
    // if (sortColumn) {
    //   const aValue = a[sortColumn];
    //   const bValue = b[sortColumn];
    //   if (typeof aValue === 'string' && typeof bValue === 'string') {
    //     return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    //   }
    // }
    // return 0;
    if (sortColumn === 'List Name') {
      const aValue = a.name.toLowerCase();
      const bValue = b.name.toLowerCase();
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  } else if(sortColumn === 'contact_count') {
      const aValue = a['contact_count'];
      const bValue = b['contact_count'];
      return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  } else {
      const aValue = new Date(a.updated_at);
      const bValue = new Date(b.updated_at);
      return sortOrder === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
  }
  });

  const handleDeleteIconClick = (segment: any) => {
    setSelectedSegment(segment);
    setShowDeleteModal(true);
   }

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Segments</h2>
        <div className="rt-btns">
          {(permission?.edit) && (
            <Button
              variant="primary"
              type="submit"
              className="btn-theme"
              onClick={()=>{
                navigate('/org/contacts/createSegment')
              }
              }
            >Create Segment
            </Button>
          )}
          {/* <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            onClick={handleShow}
          >Create Tag
          </Button> */}
        </div>
        {/* <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Tag Name</Form.Label>
                <Form.Control type="text" placeholder="Enter" />
              </Form.Group>
              <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                <Form.Label>Select Contact</Form.Label>
                <Select
                  defaultValue={selectedOption}
                  options={options}

                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className='justify-content-between'>
            <Button variant="primary" type="submit" className='btn-theme' onClick={handleClose}>
              Save & Continue<FontAwesomeIcon icon={faPlay} />
            </Button>
            <Button variant="primary" className='btn-theme btn-no-border' >
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </div>
      {/* <div className="info-cards">
        <Row>
          <Col xl={4} lg={6} md={12}>
            <div className="card">
              <h2 className='title'>Total Contacts</h2>
              <div className="text text-center">
                <span className='count'>0</span>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-theme"
                >Export Contacts
                </Button>
              </div>
            </div>
          </Col>
          <Col xl={4} lg={6} md={12}>
            <div className="card">
              <h2 className='title'>Emails Sent</h2>
              <Doughnut data={data} options={chartOptions} />
            </div>
          </Col>
          <Col xl={4} lg={12} md={12}>
            <div className="card">
              <h2 className='title'>Unsubscribes</h2>
              <div className="d-flex justify-content-between">
                <div className="text text-center">
                  <span className='count'>0</span>
                  <p className='text'>List Unsubscribes</p>
                  <p className='sub-text'>0% Unsubscribe Rate</p>
                </div>
                <div className="text text-center">
                  <span className='count'>0</span>
                  <p className='text'>Global Unsubscribes</p>
                  <p className='sub-text'>0% Unsubscribe Rate</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
      <div className="table-listing contact-list position-relative">
        <Table responsive>
          <thead>
            <tr>
            {tableHeadings_Segment.map((heading, index) => (
            <th key={index} 
              className={`${heading === "Actions" ? "" : "cursor-pointer"}`}
              onClick={() => handleSortClick(heading)}>
              <span style={{ marginRight: '5px' }}>{heading}</span>
              {heading !== 'Actions' && (
                // <FontAwesomeIcon icon={sortColumn === heading && sortOrder === 'desc' ? faSortUp : faSortDown} />
                <FontAwesomeIcon icon={sortColumn === heading && sortOrder === 'desc' ? faSortUp : (sortColumn === heading && sortOrder === 'asc' ? faSortDown : faSort)} />
              )}
            </th>
          ))}
            </tr>
          </thead>
          
          <tbody>
          {!loading && sortedSegments && sortedSegments?.length === 0 &&
            <tr>
              <td colSpan={9}>
                <p className='text-center no-records'>No segment found.</p>
              </td>
            </tr>
          } 
          {!loading && sortedSegments && sortedSegments?.map((segment:any) => (
            <tr key={segment.id}>
            <td>
              <span className='d-flex'>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" />
                </Form.Group>
               {segment?.name}
              </span>
            </td>
            <td>{segment?.contact_count}</td>
            <td>{new Date(segment.updated_at).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</td>
            <td className='action-badges'>
              {/* <button onClick={() => handleDeleteSegment(segment?.id)}>
                <span className='d-flex align-items-center'>
                  Delete <FontAwesomeIcon icon={faCircleXmark} />
                </span>
              </button> */}
              {(permission?.delete) && (
                <button>
                  <span className='d-flex align-items-center'>
                  <span onClick={() => handleDeleteIconClick(segment)}>Delete <FontAwesomeIcon icon={faCircleXmark} /></span>
                    <XDeleteModal 
                      show={showDeleteModal}
                      handleClose={() => setShowDeleteModal(false)}
                      title="Delete Segment"
                      body={`Are you sure you want to delete the Segment?`}
                      onConfirm={() => {
                        if (selectedSegment) {
                          handleDeleteSegment(selectedSegment.id);
                        }
                        setShowDeleteModal(false);
                      }}
                      onCancel={() => setShowDeleteModal(false)}
                    />
                  </span>
                </button>
              )}
              {(permission?.edit) && (
                <button className='edit' onClick={() => handleEditClick(segment?.id)}>
                  <span className='d-flex align-items-center'>
                    Edit <FontAwesomeIcon icon={faPenToSquare} />
                  </span>
                </button>
              )}
            </td>
          </tr>
      ))}      
          </tbody>
        </Table>
        {loading && <XLoader />}
      </div>
      {sortedSegments?.length !== 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default Segments
