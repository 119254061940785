import React from "react";

interface PaginationNavProps {
  totalPages: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
}

const PaginationNav: React.FC<PaginationNavProps> = ({
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const generatePaginationLinks = () => {
    const paginationLinks = Array.from({ length: totalPages }, (_, index) => {
      if (
        index + 1 === currentPage ||
        index + 2 === currentPage ||
        index === totalPages - 1 ||
        index === 0
      ) {
        return (
          <li
            className={`page-item ${index + 1 === currentPage ? "active" : ""}`}
            key={index}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        );
      } else if (index === totalPages - 2 || index === 1) {
        return (
          <li className="page-item disabled" key={`ellipsis-${index}`}>
            <button className="page-link">...</button>
          </li>
        );
      }
      return null; // Return null for indices not matching conditions
    });

    // Previous button
    paginationLinks.unshift(
      <li
        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
        key="prev"
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
      </li>
    );

    // Next button
    paginationLinks.push(
      <li
        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
        key="next"
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </li>
    );

    return paginationLinks;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">{generatePaginationLinks()}</ul>
    </nav>
  );
};

export default PaginationNav;
