import { Location } from "../../../utils/interface";

export interface RegisterFormValues {
    email: string;
    password: string;
    confirmPassword: string;
    agreeToTerms: boolean;
    showPassword: boolean;
    showConfirmPassword: boolean;
  }
export interface RegisterPayload {
    email: string;
    password: string;
    agreeToTerms: boolean,
}

export interface EmailVerifyState {
    token: string,
  }

export interface StepOneFormValues {
    firstName: string;
    lastName: string;
    businessName: string;
    phoneNumber: string;

}
export interface StepTwoFormValues {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

export interface StepThreeFormValues {
    websiteURL: string;
    logo: File | null;
}

export interface CombinedFormData {
    stepOne: StepOneFormValues;
    stepTwo: StepTwoFormValues;
    stepThree: StepThreeFormValues;
}
export interface LoginFormValues {
    email: string;
    password: string;
    showPassword: boolean;
    rememberMe: boolean;
}
export interface LoginFormPayload {
    email: string;
    password: string;
    ipAddress:string;
    rememberMe:boolean;
}
export interface ResetFormValues {
    email: string;
}
export interface CreatePasswordFormValues {
    password: string;
    confirmPassword: string;
    showPassword: boolean;
    showConfirmPassword: boolean;
}
export interface CreatePasswordPayload {
    password: string;
    token: string | null;
}

export interface StepProps {
    onNext: () => void;
    onBack?: () => void;
}


export interface State {
    name: string;
    id: number;
    state_code: string;
    state_name: string;
}

export interface UserLocations {
    id: number;
    org_id: number;
    country_id: number;
    state_id: number;
    city_id: number | null;
    zipcode: string;
    address: string;
    created_at: string;
    updated_at: string;
  }

export interface Permission {
  [module_id: string]: PermissionObject;
}

export interface PermissionObject {
    module_name: string;
    module_id: number;
    view: boolean;
    edit: boolean;
    delete: boolean;
}

export interface City {
    id: number;
    id_state: number;
    city: string;
    country: string;
    latitude: number;
    longitude: number;
}

export interface Country {
    id: number;
    code: string;
    name: string;
    created_at: string;
    updated_at: string;
  }
export interface LocationState {
    states: State[];
    cities: City[];
    countries:Country[]
}

export interface Campaign {
    id: string;
    title: string;
    subject: string;
    template_id: string;
    to_list_type: string;
    to_list_id: number[];
    from_email_domain: string;
    number_id: string;
    scheduled_time: string;
    status: string;
    type: string;
    updated_at: string;
    content: string;
    open: number;
    click: number;
    contact_count: number;
    limit: number;
    time_delay: any;
}
export interface UserSliceState {
  selectedLocationId: string;
  permissions: Permission[];
  loading: boolean;
  smsFeature: boolean;
  error: string;
  role_id: number;
  first_name: string;
  last_name: string;
  question_id: string;
  two_factor_answer: string;

  userId: number;
  orgId: number;
  email: string;
  business_name: string;
  phone_number: string;
  website_url: string;
  filename: string;
  logoUrl: string;
  created_date: string;
  locations: Location[];
  orgLocations: Location[];
  orgLocationMap: {
    [id: number]: Location;
  } | null;
}

export interface Contact {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    address: string;
    phone_number: string;
    DOB: string;
    tags:  { id: string; name: string }[];
    created_at: string;
    unsubscribe: boolean;
    sms_unsubscribe: boolean;
}

export interface Segment {
    id: number;
    name: string;
  }
export interface ContactState {
    contactlist: any;
    loading: boolean;
    error: any;
    tags: any;
    addedContact: any;
    deletedContact: any;
    editedContact: any;
    contactdetails: any;
    createTag:any;
    segments: any;
    createSegment:any;
    deleteSegment:any;
    segmentdetails:any;
    editedSegment:any;
}

export interface AddContactRequestParams {
    email: string;
    firstname: string;
    lastname: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    phone_number: string;
    DOB: string;
    tags: Option[];
}

export interface Option {
    value: string;
    label: string;
}
export type FilterOption = 'Select' |'Tags' | 'Address' | 'Email' | 'FirstName';
export type FilterOperator =  'Select'|'Contains' | 'DoesNotContain';
export type Filter = {
    selectedFilter: FilterOption;
    selectedOperator: FilterOperator;
    inputValue: string;
    logicalOperator: null |'AND' | 'OR';
  };

export interface GetContactRequestParams {
    page: number;
    limit: number;
    search?: string;
    filters?: Filter[];  
    locationId?: string;
}

export interface FormValues {
    email: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    phoneNumber: string;
    dob: string;
    tags: Option[];
  }
  export interface GetSegmentRequestParams {
    page: number;
    limit: number;
}

export interface CreateSegmentFilter {
    field: FilterOption;
    operator: FilterOperator;
    value: string;
    logicalOperator: null | 'AND' | 'OR';
  }
  
  export interface CreateSegmentPayload {
    name: string;
    filters: CreateSegmentFilter[];
  }

export interface SettingsState {
    predefinedFieldList: any;
    loading: boolean;
    error: any;
    customField:any;
    userlist: any;
}

export interface CustomFieldPayload {
    customFields: {
      columnName: string;
      columnType: string;
    }[];
  }

  export const columnTypes = [
    'text',
    'date',
    'boolean',
  ];
  export interface GetUserListRequestParams {
    page: number;
    limit: number;
    search?: string;
    }

export interface JourneyFormValues {
  name: string;
  type: string;
  status: string;
  fromId: string;
  delayValue: number;
  delayUnit: string;
  isTimeDelay: 0 | 1
}