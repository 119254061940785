import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
} from '../../modules/compaigns/compaignSlice';
import {  getRequest, postRequest } from '../../../axios/interceptors';
import { API_ENDPOINTS } from '../../../constants/api';
import { createCustomFieldFailure, createCustomFieldRequest, createCustomFieldSuccess, getPredefinedFieldFailure, getPredefinedFieldRequest, getPredefinedFieldSuccess, getUserListFailure, getUserListRequest, getUserListSuccess } from './settingsSlice';


function* getPredefinedFieldSaga(action: ReturnType<typeof getPredefinedFieldRequest>) {
    try {  
        let endpoint = `${API_ENDPOINTS.GET_PREDEFINED_FILEDS}`;
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        if (response.data?.success) {
            yield put(getPredefinedFieldSuccess(response.data?.data));
        }
    } catch (error: any) {
        yield put(getPredefinedFieldFailure(error.response.data || error));
    }
}
function* createCustomFieldSaga(action: ReturnType<typeof createCustomFieldRequest>) {
    const { payload } = action;
    try {    
          const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.ADD_CUSTOM_FIELD,payload));
            yield put(createCustomFieldSuccess(response.data));
            yield put(getPredefinedFieldRequest());
    } catch (error: any) {
            yield put(createCustomFieldFailure(error.response.data || error));
    }
}
function* getUserListSaga(action: ReturnType<typeof getUserListRequest>) {
    try {
        const { payload } = action;
        let endpoint = `${API_ENDPOINTS.GET_USER_LIST}?page=${payload.page}&limit=${payload.limit}`;
        const {  search} = payload;
        if (search) {
            endpoint += `&search=${search}`;
        }
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        yield put(getUserListSuccess(response.data));
    } catch (error: any) {
        yield put(getUserListFailure(error.response.data || error));
    }
}

function* settingsSaga() {
    yield takeLatest(getPredefinedFieldRequest.type, getPredefinedFieldSaga);
    yield takeLatest(createCustomFieldRequest.type, createCustomFieldSaga);
    yield takeLatest(getUserListRequest.type, getUserListSaga);
}

export default settingsSaga;