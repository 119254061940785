/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { XModal } from '../../component';
import { StepPayloadType } from './useJourneyStepModal';
import { JourneyContext } from './JourneyContext';
import { TimeUnit } from '../../utils/enums';
import { convertToMilliSeconds, decodeMilliseconds } from '../../utils/helper';

interface Props {
    show: boolean;
    handleClose: () => void;
    updateStepPayloadHandler: (payload: StepPayloadType) => void
    addStep: (payload: StepPayloadType[]) => void
}

const JourneyConditionDelayModal: FC<Props> = ({ show, handleClose, updateStepPayloadHandler, addStep }) => {
    const { selectedNode } = useContext(JourneyContext)
    const { value, unit } = decodeMilliseconds(selectedNode?.data?.delaySeconds);
    const [delayValue, setDelayValue] = useState<number>(value);
    const [delayUnit, setDelayUnit] = useState<string>(unit);
    const [errors, setErrors] = useState<{ delayValue?: string, delayUnit?: string }>({});

    useEffect(()=>{
        updateStepPayloadHandler({rule_id: 0, rule_type: 0})
    }, [])

    const handleConfirmClick = () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        let delaySeconds = convertToMilliSeconds(delayValue, delayUnit)
        addStep([{delaySeconds, rule_id: 3}])
        // updateStepPayloadHandler({delaySeconds, rule_id: 3})
        delaySeconds > 0 && handleClose()
    };

    const validate = () => {
        const newErrors: { delayValue?: string, delayUnit?: string } = {};
        if (delayValue <= 0) {
            newErrors.delayValue = "Delay value must be greater than 0";
        }
        if (!delayUnit) {
            newErrors.delayUnit = "Delay unit is required";
        }
        return newErrors;
    };

    const modalBody = (
        <>
            <div className="d-flex mb-5">
                <h6>Choose how long contact will delay from moving forward</h6>
            </div>
            <Form.Label style={{ flex: 'none' }}>Delay for</Form.Label>
            <Row>
                <Col xl={6} md={6} xs={12}>
                <Form.Control
                    name="delay-value"
                    type="number"
                    aria-describedby="delay-text"
                    placeholder="Enter delay value"
                    value={delayValue}
                    onChange={(e) => {
                        setDelayValue(Number(e.target.value));
                        setErrors((prevErrors) => ({ ...prevErrors, delayValue: "" }));
                    }}
                    isInvalid={!!errors.delayValue}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.delayValue}
                </Form.Control.Feedback>
                </Col>

                <Col xl={6} md={6} xs={12}>
                <Form.Select
                    name="dateRange"
                    value={delayUnit}
                    onChange={(e) => {
                        setDelayUnit(e.target.value);
                        setErrors((prevErrors) => ({ ...prevErrors, delayUnit: "" }));
                    }}
                    isInvalid={!!errors.delayUnit}
                >
                    <option value="">Select</option>
                    <option value={TimeUnit.HOURS}>Hours</option>
                    <option value={TimeUnit.MINUTES}>Minutes</option>
                    {/* <option value="sec">Seconds</option> */}
                    <option value={TimeUnit.DAYS}>Days</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {errors.delayUnit}
                </Form.Control.Feedback>
                </Col>
            </Row>
        </>
    );

    return (
        <XModal
            cancelText="Discard"
            confirmText="Save"
            size="lg"
            title="Time Delay"
            show={show}
            body={modalBody}
            handleClose={handleClose}
            handleConfirm={handleConfirmClick}
        />
    );
};

export default JourneyConditionDelayModal;
