/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Image as BootstrapImage } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IMAGES } from '../../../../constants/imagePath';
import { clearData, registerRequest } from '../../../../redux/modules/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { RegisterFormValues } from '../../../../redux/modules/auth/types';
import Loader from '../../../../component/loader';
import { RootState } from '../../../../redux/rootReducer';
import { toast } from 'react-toastify';

const Signup: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (data && data.success === true && data.message) {
      toast(data.message);
    }
    else if (error && error.success === false) {
      toast(error.message)
    }
    formik.resetForm();
    dispatch(clearData());
  }, [error, data])

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        'Invalid email address'
      ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and include at least  one lowercase character, one uppercase character, one number, one special character.'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    agreeToTerms: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
  });

  const formik = useFormik<RegisterFormValues>({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      agreeToTerms: false,
      showPassword: false,
      showConfirmPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: RegisterFormValues) => {
      const { email, password, agreeToTerms } = values;
      dispatch(registerRequest({ email, password, agreeToTerms }));
    },
   
  });

  const togglePasswordVisibility = () => {
    formik.setFieldValue('showPassword', !formik.values.showPassword);
    formik.setFieldValue('password', formik.values.password, true);
  };

  const toggleConfirmPasswordVisibility = () => {
    formik.setFieldValue('showConfirmPassword', !formik.values.showConfirmPassword);
    formik.setFieldValue('confirmPassword', formik.values.confirmPassword, true);
  };

  return (
    <div>
      <div className='login card-bg'>
        <div className="card">
          <Container>
            <Row>
              <Col xl={6} lg={12} md={12} sm={12} className='bg-blue d-flex align-items-center'>
                <div className="lt-text text-center">
                  <BootstrapImage src={IMAGES.darkLogo} />
                  <h1 className='title'>Improve patient engagement with personalized campaigns</h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className='bg-white'>
                <div className="rt-content">
                  <BootstrapImage src={IMAGES.blueLogo} />
                  <div className="heading-title">
                  <h2>Create an Account</h2>
                  </div>
                  <Form className='input-form' noValidate onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="formBasicEmail" className='form-group'>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.email && !!formik.errors.email}
                        required
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" className="position-relative form-group">
                      <Form.Control
                        type={formik.values.showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.password && !!formik.errors.password}
                        required
                      />
                      <FontAwesomeIcon
                        icon={formik.values.showPassword ? faEyeSlash : faEye}
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword" className="position-relative form-group">
                      <Form.Control
                        type={formik.values.showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                        required
                      />
                      <FontAwesomeIcon
                        icon={formik.values.showConfirmPassword ? faEyeSlash : faEye}
                        className="eye-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className='box-check d-flex justify-content-between align-items-center'>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I agree to the Terms and Conditions"
                          className='d-flex align-items-center'
                          name="agreeToTerms"
                          required
                          feedbackType="invalid"
                          checked={formik.values.agreeToTerms}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isInvalid={formik.touched.agreeToTerms && !!formik.errors.agreeToTerms}
                        />
                    <Form.Control.Feedback type="invalid" className='d-block'>{formik.errors.agreeToTerms}</Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      className='btn-theme w-100'
                      disabled={loading}
                    >
                      {loading && <Loader />}
                      Sign up
                    </Button>
                  </Form>
                  <p className='text-center btm-text mb-0'>Already have a Campaign Account? <span className='text-blue cursor-pointer' onClick={()=> navigate('/login')}>Login</span></p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Signup;
