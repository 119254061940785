import React, { useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const AccountSetup: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const handleNext = () => {
    switch (currentStep) {
      case 1:
        return setCurrentStep(2) ;
      case 2:
        return setCurrentStep(3);
    }
  };
  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1));
  };
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne onNext={handleNext} />;
      case 2:
        return <StepTwo onBack={handleBack} onNext={handleNext} />;
      case 3:
        return <StepThree onBack={handleBack} />;
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

export default AccountSetup;