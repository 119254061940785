/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import useAxios from "../../../hooks/useAxios";
import XLoader from "../../../component/loader/XLoader";
import { toaster } from "../../../utils/toast";
import { API_ENDPOINTS } from "../../../constants/api";
import XConfirm from "../../../component/confirm/XConfirm";

type FilterType = {
  contains: any;
  inRegion: string;
  searchMode: string;
};

const searchModeEnum = {
  START: "START",
  END: "END",
  ANYWHERE: "",
};

const NumbersBuy = () => {
  const { fetchData, postData, isLoading } = useAxios();
  const [numbersToBuy, setNumbersToBuy] = useState<any[]>([]);
  const [statesCode, setStatesCode] = useState([]);
  const [filter, setFilter] = useState<FilterType>({
    contains: null,
    inRegion: "",
    searchMode: searchModeEnum.ANYWHERE,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedNumber, setSelectedNumber] = useState("");

  useEffect(() => {
    getAvailableToBuyNumbers();
    fetchStates();
  }, [filter]);

  const getAvailableToBuyNumbers = async () => {
    try {
      const response = await postData(
        API_ENDPOINTS.GET_AVAILABLE_NUMBERS_TO_BUY,
        { filter }
      );
      setNumbersToBuy(response?.data);
    } catch (error) {}
  };

  const buyNumber = async () => {
    try {
      const response = await postData(API_ENDPOINTS.BUY_NUMBER, {
        selectedNumber,
      });
      const { success, message } = response;
      toaster({ success, message });
      handleClose();
    } catch (error) {
      toaster({ success: false, message: error });
    }
  };

  const fetchStates = async () => {
    try {
      const response = await fetchData(`${API_ENDPOINTS.GET_STATES}/${1}`);
      setStatesCode(response.data);
    } catch (error) {}
  };

  const handleOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  return (
    <div>
      <br />
      <div className="number-buy-filter row-item analytics-bar sm-block">
        <Row>
          <Col lg={4} sm={12}>
            <div className="select-box">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Filter By Region</Form.Label>
                <Form.Select
                  name="inRegion"
                  onChange={(e) => handleOnChange(e)}
                >
                  <option value="">Select</option>
                  {statesCode?.map((state: any, index) => (
                    <option key={index} value={state.code}>
                      {state.code} - {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </Col>

          <Col lg={4} sm={12}>
            <div className="select-box">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Search Phone Numbers By Choice</Form.Label>
                <Form.Control
                  type="text"
                  name="contains"
                  placeholder="Eg: 123"
                  onChange={(e) => handleOnChange(e)}
                />
              </Form.Group>
            </div>
          </Col>

          <Col lg={4} sm={12}>
            <div className="select-box">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Match to</Form.Label>
                <Form.Select
                  name="searchMode"
                  onChange={(e) => handleOnChange(e)}
                >
                  <option>Select</option>
                  <option value={searchModeEnum.START}>
                    Area Code
                  </option>
                  <option value={searchModeEnum.ANYWHERE}>
                    Anywhere in number
                  </option>
                  <option value={searchModeEnum.END}>
                    Last part of number
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>

      <div className="table-listing all-contacts users-list position-relative px-0">
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "50%" }}>Numbers</th>
              <th style={{ width: "50%" }}>Status</th>
            </tr>
          </thead>
            
          <tbody>
            {!isLoading &&
              numbersToBuy?.map((number, i) => (
                <tr key={i}>
                  <td>{number.phoneNumber}</td>
                  <td>
                    <span
                      className="status-badge cursor-pointer"
                      onClick={() => {
                        handleShow();
                        setSelectedNumber(number.phoneNumber);
                      }}
                    >
                      Click to buy
                    </span>
                  </td>
                </tr>
              ))}
            <XConfirm
              show={show}
              onCancel={handleClose}
              onConfirm={buyNumber}
              isLoading={isLoading}
              confirmLabel="Buy"
              message="Dou you want to buy number, please proceed by clicking on the Buy button."
            />
            {!isLoading && numbersToBuy?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="text-center no-records">
                    No Numbers Available for Purchase.
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {isLoading && <XLoader />}
      </div>
    </div>
  );
};

export default NumbersBuy;
