import React, { ComponentType } from 'react';
import useAuthorization from '../hooks/useAuthorization';

interface WithAuthorizationProps {
    module_id: number;
    action: string;
}

const withAuthorization = <P extends object>(
    WrappedComponent: ComponentType<P>,
    { module_id, action }: WithAuthorizationProps
): React.FC<P> => {
    const AuthorizationWrapper: React.FC<P> = (props) => {
        const { isAuthorize, permissions, role_id } = useAuthorization();

        const hasPermission = isAuthorize(module_id, action);
        
        return hasPermission ? <WrappedComponent {...props} permissions={permissions} permission={permissions[module_id]} role_id={role_id} /> : <h1>Un-Authorize</h1>;
    };

    return AuthorizationWrapper;
};

export default withAuthorization;
