import { useState } from "react";
import { deleteRequest, getRequest, postRequest, putRequest } from "../axios/interceptors";

const useAxios = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (url) => {
    try {
      setIsLoading(true);
      const response = await getRequest(url);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const postData = async (url, data) => {
    let response
    try {
      setIsLoading(true);
      response = await postRequest(url, data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.data?.message ?? error.message);
      return {success: false, message: error?.response?.data?.message ?? error.message ?? 'internal server error'};
    }
  };
  const putData = async (url, data) => {
    try {
      setIsLoading(true);
      const response = await putRequest(url, data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };
  const deleteData = async (url, data) => {
    try {
      setIsLoading(true);
      const response = await deleteRequest(url, data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };
  return {
    isLoading,
    error,
    fetchData,
    postData,
    putData,
    deleteData,
    setIsLoading
  };
};

export default useAxios;
