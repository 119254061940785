/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import { usePDF } from "react-to-pdf";

type FilterType = {
  dateRange: "lastMonth" | "lastYear" | "custom";
  from?: string;
  to?: string;
  type?: string;
};

export type BestCampaignResponseType = {
  title: string;
  type: string;
  scheduled_time: string;
  open: number;
  click: number;
  unsubscribe: number;
  open_rate: string;
};

export type StatsType = {
  delivered: number;
  opened: number;
  clicked: number;
  hardBounce: number;
  softBounce: number;
  unsubscribed: number;
  clickedRate: number;
  bounceRate: number;
  unsubscribedRate: number;
  openedRate: number;
  deliveredRate: number;
};

const useCampaignAnalytic = () => {
  const [filter, setFilter] = useState<FilterType>({
    dateRange: "lastMonth",
  });
  const [stats, setStats] = useState<StatsType>();
  const { postData } = useAxios();
  const { toPDF, targetRef } = usePDF({ filename: "campaign_analytics.pdf" });

  useEffect(() => {
    if (
      filter.dateRange &&
      (filter.dateRange !== "custom" || (filter.from && filter.to))
    ) {
      fetchCampaignAnalytics();
    }
  }, [filter]);

  const fetchCampaignAnalytics = async () => {
    const response = await postData(API_ENDPOINTS.GET_CAMPAIGN_ANALYTICS, {
      filter,
    });
    setStats(response?.data);
  };

  const handleFilterChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (name === "dateRange" && value !== "custom") {
      setFilter({ dateRange: value });
    } else {
      setFilter((preFilter) => ({ ...preFilter, [name]: value }));
    }
  };

  const exportHandler = () => {
    toPDF();
  };

  return {
    handleFilterChange,
    filter,
    stats,
    exportHandler,
    pdfRef: targetRef,
  };
};

export default useCampaignAnalytic;
