/* eslint-disable react-hooks/exhaustive-deps */
import { faBars, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { locationRequest } from '../../../redux/modules/auth/locationSlice';
import { contactDetailRequest } from '../../../redux/modules/contacts/contactSlice';
import { RootState } from '../../../redux/rootReducer';
import ContactActivity from '../ContactActivity';
import { columnNameToLabel } from '../../../utils/helper';
import useSidebar from '../../../hooks/useSidebar';

const ContactDetails = () => {
    const dispatch=useDispatch();
    const { contactId } = useParams();
    const {contactdetails}= useSelector((state: RootState) => state.contactlist);
    const { states, countries} = useSelector((state: RootState) => state.location);
    const navigate = useNavigate();
    const { onShowSidebar } = useSidebar();

    useEffect(() => {
      dispatch(locationRequest());
        if(contactId){
            dispatch(contactDetailRequest(contactId))
        }
      }, [contactId]);

      const getCountryName = (countryId: string | undefined) => {
        if (!countryId) return '';
        const country = countries.find((c) => c.id.toString() === countryId);
        return country ? country.name : '';
      };
      const getStateName = (stateId: string | undefined) => {
        if (!stateId) return '';
        const state = states.find((s) => s.id.toString() === stateId);
        return state ? state?.name : '';
      };

      const backToContacts = () => {
        navigate('/org/contacts/allContacts')
      }

      const chatOpen = () => {
        const encryptedContactId = contactId;
        navigate(`/org/contact-detail/${encryptedContactId}/chat`, {
          state: { contactPhoneNumber: contactdetails?.contact?.phone_number,
            firstName: contactdetails?.contact?.firstname,
            lastName: contactdetails?.contact?.lastname
          },
        });
      }

  return (
    <div>
          <div className="main-title ">
            <h2 className='back-text'>
              <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Contacts</h2>
              <div className='d-flex justify-content-between align-items-center'>
              <p className='mb-0'><span onClick={()=>backToContacts()}><FontAwesomeIcon icon={faPlay} /> Back to all contacts</span></p>
              <Button
                variant="primary"
                className="btn-theme btn-border"
                onClick={chatOpen}
                disabled={!contactdetails?.contact?.phone_number}
              >
                Chat
              </Button>
              </div>
          </div>
          <div className="contact-details">
            <Row>
              <Col xxl={5} xl={5} lg={12}>
                <div className="info-box">
                  <ul>
                    <li className='d-flex'>
                      <span className='text-lt'>First Name</span>
                      <span className='text-rt'>{contactdetails?.contact?.firstname}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Last Name</span>
                      <span className='text-rt'>{contactdetails?.contact?.lastname}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Address</span>
                      <span className='text-rt'>{contactdetails?.contact?.address}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>City</span>
                      <span className='text-rt'>{contactdetails?.contact?.city}</span>

                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>State</span>
                      <span className='text-rt'>{getStateName(contactdetails?.contact?.state)}</span>

                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Zip Code</span>
                      <span className='text-rt'>{contactdetails?.contact?.zipcode}</span>

                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Country</span>
                      <span className='text-rt'>{getCountryName(contactdetails?.contact?.country)}</span>

                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Email Address</span>
                      <span className='text-rt'>{contactdetails?.contact?.email}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Phone Number</span>
                      <span className='text-rt'>{contactdetails?.contact?.phone_number}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>DOB</span>
                      <span className='text-rt'>{contactdetails?.contact?.DOB !== null ? new Date(contactdetails?.contact?.DOB).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) : ''}</span>
                    </li>
                    <li className='d-flex'>
                      <span className='text-lt'>Tag</span>
                      <span className='text-rt'>
                      {contactdetails?.contact?.tags.map((tag:any, index:number)=>{
                        return <span key={index}>•{tag.name} </span>;
                      })}
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xxl={7} xl={7} lg={12}>
               <ContactActivity email={contactdetails?.contact?.email}/>
              </Col>
              {contactdetails?.contact?.custom_fields_values[0].value && (
              <Col className="mt-4" xxl={5} xl={5} lg={12}>
                <div className="info-box">
                  <ul>
                    {contactdetails?.contact?.custom_fields_values.map(
                      (customField: any, index: number) => {
                        const { custom_field_name, custom_field_type, value } = customField;
                        if (!value) return null;
                        let displayValue;
                        if (custom_field_type === "boolean") {
                          displayValue = value === "true" ? "Yes" : "No";
                        } else if (custom_field_type === "date") {
                          displayValue = new Date(value).toLocaleDateString("en-US",
                            { day: "numeric", month: "short", year: "numeric" }
                          );
                        } else {
                          displayValue = value;
                        }

                        return (
                          <li key={index} className="d-flex">
                            <span className="text-lt">
                              {columnNameToLabel(custom_field_name)}
                            </span>
                            <span className="text-rt">{displayValue}</span>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </Col>
              )}
            </Row>
          </div>
    </div>
  )
}

export default ContactDetails