import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSitemap, faQuestion, faChartLine } from "@fortawesome/free-solid-svg-icons";

export const OrganizationIcon = (props) => (
  <FontAwesomeIcon icon={faSitemap} {...props} />
);

export const HelpdeskIcon = (props) => (
  <FontAwesomeIcon icon={faQuestion} {...props} />
);

export const ActivityIcon = (props) => (
  <FontAwesomeIcon icon={faChartLine} {...props} />
)
