import {
  TextField,
  BooleanField,
  DateField,
} from "../../../component/formFields/formFields";
import { columnNameToLabel } from "../../../utils/helper";
interface Props {
  setCustomFieldPayload: any;
  customFields: any;
  customFieldPayload: any;
}

const CustomFields: React.FC<Props> = ({
  customFieldPayload,
  setCustomFieldPayload,
  customFields,
}) => {
  const onInputChangeHandler = (e: any) => {
    let { name, value, type, checked, dataset } = e.target;
    let id = dataset.id;
    if (type === "checkbox") {
      value = checked + "";
    }
    setCustomFieldPayload({ ...customFieldPayload, [id]: { name, value } });
  };

  const renderField = (field: any) => {
    const updatedColumnName = columnNameToLabel(field.name);
    const commonProps = {
      key: field.name,
      name: field.name,
      id: field.id,
      label: updatedColumnName,
      value: customFieldPayload?.[field.id]?.value,
      onChange: (e: any) => onInputChangeHandler(e),
    };

    switch (field.type) {
      case "text":
        return (
          <div className="col-xl-4 col-md-6 col-12">
            <TextField
              {...commonProps}
              placeholder={`Enter ${updatedColumnName}`}
            />
          </div>
        );
      case "date":
        return (
          <div className="col-xl-4 col-md-6 col-12">
            <DateField {...commonProps} />
          </div>
        );
      case "boolean":
        return (
          <div className="col-xl-4 col-md-6 col-12">
            <BooleanField {...commonProps} />
          </div>
        );
      default:
        return null;
    }
  };

  return <>{customFields?.map((field: any) => renderField(field))}</>;
};

export default CustomFields;
