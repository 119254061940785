/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import XLoader from "../../../component/loader/XLoader";
import useAxios from "../../../hooks/useAxios";
import { API_ENDPOINTS } from "../../../constants/api";

const ActiveNumbers = () => {
  const { fetchData, isLoading } = useAxios();
  const [activeNumbers, setActiveNumbers] = useState<any[]>([]);

  useEffect(() => {
    getActiveNumbers();
  }, []);

  const getActiveNumbers = async () => {
    try {
      const response = await fetchData(API_ENDPOINTS.GET_NUMBERS);
      setActiveNumbers(response?.data);
    } catch (error) {}
  };

  return (
    <div className="table-listing all-contacts users-list position-relative px-0">
      <Table responsive>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Numbers</th>
            <th style={{ width: "50%" }}>Status</th>
          </tr>
        </thead>
          
        <tbody>
          {!isLoading &&
            activeNumbers?.map((number) => {
              return (
                <tr key={number.id}>
                  <td>{number.number}</td>
                  <td>
                    <span className="status-badge green">Active</span>
                  </td>
                </tr>
              );
            })}
          {!isLoading && activeNumbers?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <p className="text-center no-records">
                  No Active Numbers Available.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {isLoading && <XLoader />}
    </div>
  );
};

export default ActiveNumbers;
