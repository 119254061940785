import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactState, CreateSegmentPayload, GetContactRequestParams, GetSegmentRequestParams } from '../auth/types';

interface EditContactRequestPayload {
    contactId: string;
    updatedData: any;
}
interface EditSegmentRequestPayload {
    segmentId: string;
    updatedData: any;
}
const initialState: ContactState = {
    contactlist: [],
    loading: false,
    error: null,
    tags: [],
    createTag:[],
    addedContact:null,
    deletedContact:null,
    editedContact:null,
    contactdetails:null,
    segments: [],
    createSegment:null,
    deleteSegment:null,
    segmentdetails:null,
    editedSegment:null,
};

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        getContactRequest(state, action: PayloadAction<GetContactRequestParams>) {
            state.loading = true;
            state.error = null;
        },
        getContactSuccess(state, action: PayloadAction<any>) {
            state.contactlist = action.payload;
            state.loading = false;
            state.error = null;
        },
        getContactFailure(state, action: PayloadAction<string>) {
            state.contactlist = [];
            state.loading = false;
            state.error = action.payload;
        },
        addContactRequest(state,action: PayloadAction<any>) {
            state.loading = true;
            state.error = null;
        },
        addContactSuccess(state, action: PayloadAction<any>) {
            state.addedContact = action.payload;
            state.loading = false;
            state.error = null;
        },
        addContactFailure(state, action: PayloadAction<string>) {
            state.addedContact = null;
            state.loading = false;
            state.error = action.payload;
        },
        deleteContactRequest(state, action: PayloadAction<string>) {
            state.loading = true;
            state.error = null;
        },
        deleteContactSuccess(state, action: PayloadAction<any>) {
            state.deletedContact = action.payload;
            state.loading = false;
            state.error = null;
        },
        deleteContactFailure(state, action: PayloadAction<string>) {
            state.deletedContact = null;
            state.loading = false;
            state.error = action.payload;
        },
        editContactRequest(state, action: PayloadAction<EditContactRequestPayload>) {
            state.loading = true;
            state.error = null;
        },
        editContactSuccess(state, action: PayloadAction<any>) {
            state.editedContact = action.payload;
            state.loading = false;
            state.error = null;
        },
        editContactFailure(state, action: PayloadAction<string>) {
            state.editedContact = null;
            state.loading = false;
            state.error = action.payload;
        },
        contactDetailRequest(state, action: PayloadAction<string>) {
            state.loading = true;
            state.error = null;
        },
        contactDetailSuccess(state, action: PayloadAction<any>) {
            state.contactdetails = action.payload;
            state.loading = false;
            state.error = null;
        },
        contactDetailFailure(state, action: PayloadAction<string>) {
            state.contactdetails = null;
            state.loading = false;
            state.error = action.payload;
        },
        getTagsRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getTagsSuccess(state, action: PayloadAction<any>) {
            state.tags = action.payload;
            state.loading = false;
            state.error = null;
        },
        getTagsFailure(state, action: PayloadAction<string>) {
            state.tags = [];
            state.loading = false;
            state.error = action.payload;
        },
        createOrFindTagRequest(state,action: PayloadAction<any>) {
            state.loading = true;
            state.error = null;
        },
        createOrFindTagSuccess(state, action: PayloadAction<any>) {
            state.createTag = action.payload;
            state.loading = false;
            state.error = null;
        },
        createOrFindTagFailure(state, action: PayloadAction<string>) {
            state.createTag = [];
            state.loading = false;
            state.error = action.payload;
        },
        getSegmentsRequest: (state,action:PayloadAction<GetSegmentRequestParams>) => {
            state.loading = true;
            state.error = null;
          },
          getSegmentsSuccess: (state, action: PayloadAction<any>) => {
            state.segments = action.payload;
            state.loading = false;
            state.error = null;
          },
          getSegmentsFailure: (state, action: PayloadAction<string>) => {
            state.segments=[];
            state.loading = false;
            state.error = action.payload;
          },
          createSegmentRequest: (state, action: PayloadAction<CreateSegmentPayload>) => {
            state.loading = true;
            state.error = null;          },
          createSegmentSuccess: (state, action: PayloadAction<any>) => {
            state.createSegment = action.payload;
            state.loading = false;
            state.error = null;          },
          createSegmentFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.createSegment=null;
          },
          deleteSegmentRequest(state, action: PayloadAction<string>) {
            state.loading = true;
            state.error = null;
          },
          deleteSegmentSuccess(state, action: PayloadAction<any>) {
            state.deleteSegment = action.payload;
            state.loading = false;
            state.error = null;
          },
          deleteSegmentFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
          },
            segmentDetailRequest(state, action: PayloadAction<string>) {
                state.loading = true;
                state.error = null;
            },
            segmentDetailSuccess(state, action: PayloadAction<any>) {
                state.segmentdetails = action.payload;
                state.loading = false;
                state.error = null;
            },
            segmentDetailFailure(state, action: PayloadAction<string>) {
                state.segmentdetails = null;
                state.loading = false;
                state.error = action.payload;
            },
            updateSegmentRequest(state, action: PayloadAction<EditSegmentRequestPayload>) {
                state.loading = true;
                state.error = null;
            },
            updateSegmentSuccess(state, action: PayloadAction<any>) {
                state.editedSegment = action.payload;
                state.loading = false;
                state.error = null;
            },
            updateSegmentFailure(state, action: PayloadAction<string>) {
                state.editedSegment = null;
                state.loading = false;
                state.error = action.payload;
            },
        clearData: (state) => { 
            state.error = null;
            state.addedContact=null;
            state.deletedContact=null;
            state.editedContact=null;
            state.createSegment=null; 
            state.deleteSegment=null;
            state.editedSegment=null;
            state.segmentdetails=null;
     

          },
    },
});

export const {
    getContactRequest,
    getContactSuccess,
    getContactFailure,
    addContactRequest,
    addContactSuccess,
    addContactFailure,
    deleteContactRequest,
    deleteContactSuccess,
    deleteContactFailure,
    editContactRequest,
    editContactSuccess,
    editContactFailure,
    contactDetailRequest,
    contactDetailSuccess,
    contactDetailFailure,
    getTagsRequest,
    getTagsSuccess,
    getTagsFailure,
    createOrFindTagRequest,
    createOrFindTagSuccess,
    createOrFindTagFailure,
    getSegmentsRequest,
    getSegmentsSuccess,
    getSegmentsFailure,
    createSegmentRequest,
    createSegmentSuccess,
    createSegmentFailure,
    deleteSegmentRequest,
    deleteSegmentSuccess,
    deleteSegmentFailure,
    segmentDetailRequest,
    segmentDetailSuccess,
    segmentDetailFailure,
    updateSegmentRequest,
    updateSegmentSuccess,
    updateSegmentFailure,
    clearData
} = contactSlice.actions;

export default contactSlice.reducer;
