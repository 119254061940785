/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { XModal } from "../../component";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import SingleAnalyticsUI from "../../component/singleAnalyticsUI/SingleAnalyticsUI";

interface SingleCampaignAnalyticsProps {
  selectedCampaignId: number;
  setSelectedCampaignId: any;
}

const SingleCampaignAnalytics: React.FC<SingleCampaignAnalyticsProps> = ({
  selectedCampaignId,
  setSelectedCampaignId,
}) => {
  const [campaignAnalyticsModalShow, setCampaignAnalyticsModalShow] =useState<boolean>(false);
  const [analyticsData, setAnalyticsData] = useState<any>([]);
  const { postData, isLoading } = useAxios();

  useEffect(() => {
    if (selectedCampaignId) {
      setCampaignAnalyticsModalShow(true);
      fetchAnalytics(selectedCampaignId);
    }
  }, [selectedCampaignId]);

  const fetchAnalytics = async (campaignId: any) => {
    const response = await postData(
      API_ENDPOINTS.CAMPAIGN_ANALYTICS_DETAILS_BY_ID,
      { campaignId }
    );
    setAnalyticsData({
      analytics: response?.data?.selectedCampaignAnalytics,
      contacts: response?.data?.selectedCampaignContacts
    })
  };

  return (
    <XModal
      show={campaignAnalyticsModalShow}
      handleClose={() => {
        setCampaignAnalyticsModalShow(false);
        setSelectedCampaignId("");
      }}
      title={"Campaign Analytics"}
      confirmText={"Close"}
      handleConfirm={() => {
        setCampaignAnalyticsModalShow(false);
        setSelectedCampaignId("");
      }}
      body={
        <SingleAnalyticsUI
          data={analyticsData}
          title={"Campaign Name"}
          type={"Campaign Type"}
          loader={isLoading}
        />
      }
    />
  );
};

export default SingleCampaignAnalytics;
