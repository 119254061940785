import { Form, Table } from 'react-bootstrap';
import { CampaignType } from '../../../utils/enums';
import { formatDate } from '../../../utils/helper';
import { AnalyticFilterType } from '../../../utils/interface';
import useBestJourney from './useBestJourney';
import XLoader from '../../../component/loader/XLoader';


type BestJourneyProp = {
    filter: AnalyticFilterType
}
const BestJourney = (props: BestJourneyProp) => {
    const { filter } = props
    const { bestJourney, onTypeChange, isLoading } = useBestJourney(filter)

    return (
        <div className="analytics-table">
            <div className="filter-bar d-flex align-items-center justify-content-between row-item analytics-bar sm-block">
                <div className='sub-title'>Your Best Journey</div>
                <div className='select-box'>
                    <Form.Group className="d-flex align-items-center" controlId="exampleForm.ControlInput1">
                        <Form.Label>Select Journey Type</Form.Label>
                        <Form.Select onChange={onTypeChange}>
                            {/* <option value="">All</option> */}
                            <option value={CampaignType.EMAIL}>{CampaignType.EMAIL}</option>
                            {/* <option value={CampaignType.SMS}>{CampaignType.SMS}</option> */}
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>
            <div className="table-listing contact-list all-contacts">
                {bestJourney?.length > 0 && <Table responsive>
                    <thead>
                        <tr>
                            <th>Journey Name</th>
                            <th>Open Rate</th>
                            <th>Click Rate</th>
                            <th>Hard Bounce</th>
                            <th>Soft Bounce</th>
                            <th>Recipients</th>
                            <th>Journey Type</th>
                            <th>Unsubscribe Rate</th>
                            <th>Sent Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && bestJourney?.map((v, k) => {
                            const name = v.name
                            const openRate = v.open_rate + '%'
                            const clickRate = v.click_rate + '%'
                            const hardBounce = v.hard_bounce + '%'
                            const softBounce = v.soft_bounce + '%'
                            const countactCount = v.contact_count
                            const type = v.type
                            const unsubscribeRate = v.unsubscribe_rate + '%'
                            const sentDate = formatDate(v.created_at)
                            return (<tr key={k}>
                                <td>{name}</td>
                                <td>{openRate}</td>
                                <td>{clickRate}</td>
                                <td>{hardBounce}</td>
                                <td>{softBounce}</td>
                                <td>{countactCount}</td>
                                <td>{type}</td>
                                <td>{unsubscribeRate}</td>
                                <td>{sentDate}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>}

                {!isLoading && bestJourney?.length <= 0 && (
                    <div className="d-flex justify-content-center">NOT FOUND</div>
                )}
            </div>
            <div className='position-relative'>
                {isLoading && <XLoader />}
            </div>
        </div>
    )
}

export default BestJourney