/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { API_ENDPOINTS } from '../../../constants/api';
import useAxios from '../../../hooks/useAxios';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { tableHeadings_AuditLog } from '../../../constants/constants';
import useSidebar from '../../../hooks/useSidebar';
import PaginationNav from '../../../component/pagination/pagination';
import XLoader from '../../../component/loader/XLoader';
import { utcToLocalStringWithTime } from '../../../utils/helper';

interface AuditLogEntry {
    id: number;
    table_name: string;
    event_type: string;
    row_data: any;
    event_timestamp: string;
    changed_by: string;
  }
  
const AuditLog: React.FC = () => {
  const [auditData, setAuditData] = useState<AuditLogEntry[]>([]);
  const { fetchData, isLoading} = useAxios();
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    limit: 10
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const { onShowSidebar } = useSidebar();
  const startingIndex = (queryParams.page - 1) * queryParams.limit;


  useEffect(() => {
    const fetch = async () => {
      try {
        const {  search,page,limit} = queryParams;
        let query = `${API_ENDPOINTS.AUDITLOG}?page=${page}&limit=${limit}`
        if (search) {
            query += `&search=${search}`;
        }
        const response = await fetchData(query);
        setAuditData(response.data.auditData);
        const totalContacts = response.data?.totalCount;
        const totalPages = Math.ceil(totalContacts / queryParams?.limit);
        setTotalPages(totalPages);
      } catch (error) {}
    };
    fetch();
  }, [queryParams]);
  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };


  return (
    <div>
    <div className="main-title d-flex align-items-center justify-content-between">
      <h2 className='d-flex align-items-center'>
        <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Audit Logs</h2>
    </div>
    <div className="table-listing all-contacts users-list position-relative">
      <Table responsive>
        <thead>
        <tr>
            {tableHeadings_AuditLog.map((heading, index) => (
            <th key={index}
            >{heading} 
             </th>
          ))}
            </tr>
        </thead>
          
        <tbody>
          {!isLoading && auditData?.length === 0 &&
          <tr>
            <td colSpan={9}>
          <p className='text-center no-records'>No contact found.</p>
          </td>
          </tr>}
          {!isLoading && auditData && auditData?.map((entry: AuditLogEntry, i: number) => (
            <tr key={entry.id}>
              <td>{startingIndex + i + 1}</td>
              <td>{entry.table_name}</td>
              <td>{entry.event_type}</td>
              <td>{JSON.stringify(entry.row_data)}</td>
              <td>{utcToLocalStringWithTime(entry.event_timestamp)}</td>
              {/* <td>{entry.changed_by}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
      {isLoading && <XLoader />}
    </div>
    {!isLoading && auditData?.length > 0 && (
      <PaginationNav
        totalPages={totalPages}
        currentPage={queryParams.page}
        handlePageChange={handlePageChange}
      />
    )}
  </div>
  );
};

export default AuditLog;
