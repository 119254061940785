import { FC } from "react";
import {Header ,AdminSideNav} from "../../component/index";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import AdminContent from "./AdminContent";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const AdminLayout: FC = () => {
  return (
    <div>
      <div className="dashboard">
        <Header/>
        <div className="d-flex">
          <AdminSideNav/>
          <div className="main-wrapper">
            <AdminContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
