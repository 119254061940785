/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { API_ENDPOINTS } from "../../constants/api";
import useAxios from "../../hooks/useAxios";
import {
    saveCampaign,
    setMessage,
    setStep
} from "../../redux/modules/compaigns/compaignSlice";
import { RootState } from "../../redux/rootReducer";
import { decodeMilliseconds, localeToUtcString, utcToLocalString } from "../../utils/helper";
import { Option } from "../../utils/interface";
import useList from "../../hooks/useList";
import useSegment from "../../hooks/useSegment";
import { toaster } from "../../utils/toast";
import { CampaignType } from "../../utils/enums";

const useCampaign = () => {
    const dispatch = useDispatch();
    const { step, campaignId, loading, error, campaignEditData } = useSelector(
        (state: RootState) => state.campaigns
    );
    const { listMap } = useList({refresh: step === 2})
    const { segmentMap } = useSegment({refresh: step === 2})
    const [domains, setDomains] = useState<Option[]>([]);
    const [templates, setTemplates] = useState<any>({});
    const { postData, fetchData } = useAxios();
    const [templateWithFolderList, setTemplateWithFolderList] = useState<any>([])
    const { value, unit } = decodeMilliseconds(campaignEditData?.time_delay);
    const [activeNumbers, setActiveNumbers] = useState<any[]>([]);

    useEffect(() => {
        if(error) {
          toaster({message: error, success: false})
        }
    }, [error]);

    useEffect(() => {
        if (step === 2) {
            fetchDomains();
            getActiveNumbers();
        }
        if (step === 3) {
            fetchTemplates();
            getTemplateWithFolderList();
        }
    }, [step]);

    const validationSchema = Yup.object().shape({
        ...(step === 1 && {
            type: Yup.string().required("Type is required"),
            title: Yup.string().trim().required('Title is required').test(
                (value) => value.trim() !== ''
              ),
        }),
        ...(step === 2 && {
            toListType: Yup.string().required("To List Type is required"),
            toListId: Yup.array().min(1, 'Select at least one option'),
            scheduleTime: Yup.string().required("Schedule Time is required"),
            limit: Yup.string().test({
                test: function(value) {
                    if (this.parent.bulkEmailsLimitType === 2) {
                        return !!value || this.createError({ message: "Limit is required" });
                    }
                    return true;
                }
            }),
            delayUnit: Yup.string().test({
                test: function(value) {
                    if (this.parent.bulkEmailsLimitType === 2) {
                        return !!value || this.createError({ message: "Time Delay Type is required" });
                    }
                    return true;
                }
            }),
            delayValue: Yup.string().test({
                test: function(value) {
                    if (this.parent.bulkEmailsLimitType === 2) {
                        return !!value || this.createError({ message: "Delay Value is required" });
                    }
                    return true;
                }
            }),
        }),
        ...(step === 3 && {
            templateId: Yup.string().required("Template is required"),
        }),
    });

    const formik1 = useFormik({
        initialValues: {
            title: campaignEditData?.title ?? "",
            type: campaignEditData?.type ?? "",
            isCreateCampaign: true
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });

    const formik2 = useFormik({
        initialValues: {
            toListType: campaignEditData?.to_list_type ?? "",
            toListId: [],
            fromEmailDomain: campaignEditData?.from_email_domain ?? "",
            fromSmsNumber: campaignEditData?.number_id ?? "",
            scheduleTime: campaignEditData?.scheduled_time ? utcToLocalString(campaignEditData?.scheduled_time) : "",
            limit: campaignEditData?.limit ?? "",
            delayUnit: campaignEditData?.time_delay ? unit : "",
            delayValue: campaignEditData?.time_delay ? value : "",
            bulkEmailsLimitType: campaignEditData?.limit ? 2 : 1
        },
        validationSchema: Yup.object().shape({
            ...validationSchema.fields,
            ...(formik1.values.type === CampaignType.EMAIL && Yup.object().shape({
                fromEmailDomain: Yup.string().required("From Email Domain is required"),
            }).fields),
            ...(formik1.values.type === CampaignType.SMS && Yup.object().shape({
                fromSmsNumber: Yup.string().required("From SMS Number is required"),
            }).fields),
        }),
        onSubmit: async (values) => {
            if(values.bulkEmailsLimitType === 1){
                values.limit = ""
                values.delayUnit = ""
                values.delayValue = ""
              }
            handleSubmit(values);
        },
    });

    const formik3 = useFormik({
        initialValues: {
            subject: campaignEditData?.subject ?? "",
            templateId: campaignEditData?.template_id ?? "",
        },
        validationSchema: Yup.object().shape({
            ...validationSchema.fields,
            ...(formik1.values.type === CampaignType.EMAIL && Yup.object().shape({
                subject: Yup.string().trim().required("Subject is required"),
            }).fields),
        }),
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });

    // useEffect(() => {
    //     if (+(campaignEditData?.to_list_type??0) === 1) {
    //         const listIds = campaignEditData?.to_list_id.map((v: number)=>listMap[v])
    //         formik2.setFieldValue('toListId', listIds)
    //     }else{
    //         const listIds = campaignEditData?.to_list_id.map((v: number)=>segmentMap[v])
    //         formik2.setFieldValue('toListId', listIds)
    //     }
    // }, [campaignEditData?.to_list_type, listMap, segmentMap, step]);

    useEffect(() => {
        if(campaignEditData?.to_list_type && formik2.values.toListId.length === 0){
            const map = +(campaignEditData?.to_list_type ?? 0) === 1 ? listMap : segmentMap;
            const ids = (campaignEditData?.to_list_id || []).map(id => map[id]).filter(id => id !== undefined);
            formik2.setFieldValue('toListId', ids);
        }
    }, [campaignEditData?.to_list_type, listMap, segmentMap, step]);

    //initial value set
    useEffect(() => {
        formik2.setFieldValue('templateId', campaignEditData?.template_id);
        formik2.setFieldValue('subject', campaignEditData?.subject);
        formik2.setFieldValue('fromEmailDomain', campaignEditData?.from_email_domain);
    }, [campaignEditData?.template_id, campaignEditData?.subject, campaignEditData?.from_email_domain]);

    const fetchDomains = async () => {
        try {
            const response = await postData(API_ENDPOINTS.GET_ALL_DOMAINS, {status: true});
            const formattedOption = response?.data.map((v: any) => ({
                value: v.id,
                text: v.email,
                key: v.email,
            }));
            setDomains(formattedOption);
        } catch (error) {}
    }

    const fetchTemplates = async () => {
        try {
            const response = await postData(API_ENDPOINTS.GET_ALL_TEMPLATES, {});
            const formattedOption = response?.data?.templates.reduce((acc: any, v: any) => {
                acc[v.id] = {
                    value: v.id,
                    text: v.name,
                    key: v.name,
                    data: v,
                };
                return acc;
            }, {});
            setTemplates(formattedOption);
        } catch (error) {}
    }

    const getTemplateWithFolderList = async () => {
        try {
          const type = formik1?.values?.type;
          const response = await postData(API_ENDPOINTS.GET_ALL_TEMPLATE_WITH_FOLDER, { type });
          setTemplateWithFolderList(response.data);
        } catch (error) {}
      }

    const handleClose = () => {
        dispatch(setStep(0));
    };

    const handleBack = () => {
        if (step === 2) {
            handleClose()
            dispatch(setMessage('Saved as draft'))
        }else{
            dispatch(setStep(step-1));
        }
    };

    const handleSubmit = (values: any) => {
        let payload = {
            ...values,
            step,
            campaignId,
            type: formik1.values.type,
            scheduleTime: localeToUtcString(values.scheduleTime),
            locationId: localStorage.getItem("locationId"),
            isCreateCampaign: formik1.values.isCreateCampaign,
            title: formik1.values.title,
            ...(step === 2 && {
                toListId: formik2.values.toListId?.map((item: any) => item.id) ?? []
            })
        };
        if ((step >= 2) && (new Date(values.scheduleTime) <  new Date())) {
            toaster({message: "scheduled time can't be in past", success: false})
            return
          }
        dispatch(saveCampaign(payload));
    };

    const getActiveNumbers = async () => {
        try {
          const response = await fetchData(API_ENDPOINTS.GET_NUMBERS);
          const formattedOption = response?.data.map((v: any) => ({
            value: v.id,
            text: v.number,
            key: v.number,
        }));
          setActiveNumbers(formattedOption);
        } catch (error) {}
      };


    return {
        formik1,
        formik2,
        formik3,
        loading,
        domains,
        activeNumbers,
        templates,
        templateWithFolderList,
        step,
        handleClose,
        handleBack,
        handleSubmit,
        campaignId,
        campaignEditData,
        listMap,
        segmentMap,
    };
};

export default useCampaign