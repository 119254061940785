import { FC } from "react";
import { Modal, Button } from "react-bootstrap";

interface XModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
  disabled?: boolean;
  title?: string;
  body: React.ReactNode | string;
  size?: "sm" | "lg" | "xl"; // Add size prop
  confirmText?: string; // Optional confirm button text
  cancelText?: string; // Optional cancel button text,
  className?: string;
  backdrop?: 'static' | undefined;
}

const XModal: FC<XModalProps> = ({
  show,
  handleClose,
  handleConfirm,
  title,
  body,
  size,
  confirmText,
  cancelText,
  className = '',
  disabled,
  backdrop = undefined
}) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop={backdrop} centered size={size ?? "sm"} className={`${className} ${disabled && 'disabled'}`}>
      {/* Set size prop */}
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div className={title ? "" : "content"}>
          <div>{body}</div>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        {cancelText && <Button
          variant="primary"
          className="btn-theme btn-border"
          onClick={handleClose}
        >
          {cancelText}
        </Button>}
        {handleConfirm && (
          <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            onClick={handleConfirm}
          >
            {confirmText ?? 'Yes'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default XModal;
