import { call, takeLatest,select,put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { postRequest} from '../../../axios/interceptors';
import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '../../../constants/api';
import { CombinedFormData} from './types';
import {  accountFailure, accountSuccess, setStepThreeData } from './accountSetupSlice';

function* accountSetup(action: PayloadAction<CombinedFormData>): any {
    try {
        const formValues: any = yield select(state => state.accountSetup);
        const formData: any = new FormData();
        const stepForm: any = { ...formValues.stepOne, ...formValues.stepTwo, ...formValues.stepThree };
        for (const key in stepForm) {
            if (stepForm.hasOwnProperty(key)) {
                const value = stepForm[key];
                formData.append(key, value);
            }
        }
        const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.ACCOUNT_SETUP, formData));
        yield put(accountSuccess(response.data));
    } catch (error: any) {
        yield put(accountFailure(error.response.data || error));
    }
}

function* accountSaga() {
    yield takeLatest(setStepThreeData.type, accountSetup);
}

export default accountSaga;
