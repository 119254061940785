/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react'
import Image from 'react-bootstrap/Image';
import { IMAGES } from '../../../../constants/imagePath';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepThreeFormValues } from '../../../../redux/modules/auth/types';
import { setStepThreeData } from '../../../../redux/modules/auth/accountSetupSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import Loader from '../../../../component/loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface StepProps {
  onBack?: () => void;
}

const StepThree: React.FC<StepProps> = ({ onBack }) => {
  const dispatch = useDispatch();
  const selectStepThreeData = useSelector((state: RootState) => state.accountSetup.stepThree);
  const { loading, error, data } = useSelector((state: RootState) => state.accountSetup);
  let navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const StepThreeValidationSchema = Yup.object().shape({
    websiteURL: Yup.string().required('Website URL is required').url('Invalid URL'),
    logo: Yup.string().required('Logo is required'),
  });

  useEffect(() => {
    if (data && data.success === true) {
      toast(data.message);
      navigate('/login')
    }
    else if (error && error.success === false) {
      toast(error.message)
    }
  }, [error, data])
  const formik = useFormik<StepThreeFormValues>({
    initialValues: {
      websiteURL: '',
      logo: null,
    },
    validationSchema: StepThreeValidationSchema,
    onSubmit: (values: StepThreeFormValues) => {
      dispatch(setStepThreeData(values))
    },
  });

  useEffect(() => {
    formik.setValues(selectStepThreeData);
  }, [selectStepThreeData]);
  return (
    <div>
      {/* // -------------------- 3 step -------------------------- */}
      <div className='signup-process'>
        <Container>
          <div className="content">
            <Image src={IMAGES.blueLogo} className='top-logo' />
            <div className="card">
              <h2 className='card-title'>Account Setup</h2>
              <Row className='justify-content-center'>
                <Col xl={10}>
                  {/* <div className="stepper">
                    <ul className='d-flex justify-content-between align-items-center'>
                      <li className='step done'><svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z" fill="white" />
                      </svg>
                      </li>
                      <li className='active'><span></span></li>
                      <li className='step done'><svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z" fill="white" />
                      </svg>
                      </li>
                      <li className='active'><span></span></li>
                      <li className='step active'>3</li>
                    </ul>
                  </div> */}
                  <div className="stepper">
                    <ul className='d-flex justify-content-between align-items-center'>
                      <li className='text-center done'><span className='step d-inline-block'><svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z" fill="white" />
                      </svg></span>
                      </li>
                      <li className='active'><span className='border-line'></span></li>
                      <li className='text-center done'><span className='step d-inline-block'><svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z" fill="white" />
                      </svg></span>
                      </li>
                      <li className='active'><span className='border-line'></span></li>
                      <li className='text-center active'><span className='step d-inline-block'>3</span></li>
                    </ul>
                  </div>
                  {formik.touched.websiteURL && formik.touched.logo && Object.keys(formik.errors).length > 0 && (
                    <div className="error-box" style={{ display: formik.touched && Object.keys(formik.errors).length > 0 ? 'block' : 'none' }}>
                      <span style={{ fontWeight: 'bold' }}> {`There are ${Object.values(formik.errors).length} errors in your form.`}<br /> </span>
                      {Object.values(formik.errors).map((error, index) => (
                        <span key={index}>{error}<br /></span>
                      ))}
                    </div>
                  )}
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="form-title">
                      <h2>Want to import your brand?</h2>
                      <p>Enter your website URL and we’ll import your logo, images, colors and fonts into custom email designs.</p>
                    </div>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Form.Group controlId="formBasicEmail" className='form-group'>
                          <Form.Label>Website URL</Form.Label>
                          <Form.Control type="text" placeholder="Enter"
                            {...formik.getFieldProps('websiteURL')}
                          />
                          <Form.Text className="text-danger error-text">
                            {formik.touched.websiteURL && formik.errors.websiteURL}
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Form.Group controlId="formBasicEmail" className='position-relative form-group'>
                          <Form.Label>Upload Logo</Form.Label>
                          <Form.Control type="file"
                            ref={fileInputRef}
                            accept='image/*'
                            placeholder="Enter"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const file = event.currentTarget.files?.[0];
                              formik.setFieldValue('logo', file);
                            }}
                          />
                          <Form.Text className='text-danger error-text'>{formik.touched.logo && formik.errors.logo}</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className='d-flex justify-content-between align-items-center'>
                      <Button variant="primary" className='btn-theme btn-border' onClick={onBack}>
                        <FontAwesomeIcon icon={faPlay} />Back
                      </Button>
                      <Button variant="primary" type="submit" className='btn-theme' disabled={loading}>
                      {loading && <Loader/>}
                        Submit<FontAwesomeIcon icon={faPlay} />
                      </Button>
                    </div>
                  </Form>
                </Col>

              </Row>


            </div>
          </div>
        </Container>
      </div>

    </div>

  )
}

export default StepThree;