/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoginFormValues } from "../../../redux/modules/auth/types";
import { IMAGES } from "../../../constants/imagePath";
import { clearData, loginRequest } from "../../../redux/modules/auth/authSlice";
import { RootState } from "../../../redux/rootReducer";
import Loader from "../../../component/loader";
import { toast } from "react-toastify";
import { getIPAddress } from "../../../constants/ipaddressService";
import { decodeJwt } from "../../../utils/helper";
import { decryptData } from "../../../utils/encryption";
import { ROLE } from "../../../utils/enums";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { loginError, loginRes, loading } = useSelector((state: RootState) => state.auth);
  const [submittingLoader, setSubmittingLoader] = useState(false);

  useEffect(() => {
    if (loginRes?.data?.isOtpGenerated) {
      navigate("/otp-authentication");
    } else if (loginError?.res?.is_registration_complete === false) {
      toast(loginError.message);
      const activeToken = loginError?.res?.token;
      dispatch(clearData());
      navigate(`/account-setup?activationToken=${activeToken}`);
    }else{
      setSubmittingLoader(false);
      let loginEmail = localStorage.getItem('u_key')
      let loginPassword = localStorage.getItem('u_secret')
      if (loginEmail) {
        loginEmail = decryptData(localStorage.getItem('u_key'))
      }
      if (loginPassword) {
        loginPassword = decryptData(localStorage.getItem('u_secret'))
      }
      toast(loginRes?.message);
      const {token: apiToken='', refreshToken='' } = loginRes?.data || {}
      const token = localStorage.getItem('authToken')
      if (apiToken) localStorage.setItem("authToken", apiToken);
      if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
      if (token  || apiToken) {
        const decodedPayload = decodeJwt(token  || apiToken);
        decodedPayload?.parentRoleId === ROLE.SUPER_ADMIN ? navigate('/Admin/dashboard') : navigate('/org')
      } else if (loginEmail || loginPassword) {
        if (loginEmail) formik.setFieldValue('email', loginEmail);
        if (loginPassword) formik.setFieldValue('password', loginPassword);
        navigate('/login')
      }
    }
  }, [loginError, loginRes]);
  
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
      rememberMe: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: LoginFormValues) => {
      setSubmittingLoader(true);
      const ipAddress = await getIPAddress();
      const { email, password, rememberMe } = values;
      dispatch(loginRequest({ email, password ,ipAddress, rememberMe}));
    },
  });
  const togglePasswordVisibility = () => {
    formik.setFieldValue('showPassword', !formik.values.showPassword);
  };

  return (
    <div>
      <div className="login card-bg">
        <div className="card">
          <Container>
            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="bg-blue d-flex align-items-center"
              >
                <div className="lt-text text-center">
                  <Image src={IMAGES.darkLogo} />
                  <h1 className="title">
                    Improve patient engagement with personalized campaigns
                  </h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className="bg-white">
                <div className="rt-content">
                  <Image src={IMAGES.blueLogo} />
                  <div className="heading-title">
                    <h2>Welcome Back</h2>
                  </div>
                  {loginError && <div className="error-box">{loginError?.message}</div>}
                  <Form
                    className="input-form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <Form.Group controlId="formBasicEmail" className="form-group">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.email &&
                          !!formik.errors.email
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="formBasicPassword"
                      className="position-relative form-group"
                    >
                      <Form.Control
                        type={formik.values.showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.password &&
                          !!formik.errors.password
                        }
                        required
                      />
                      <FontAwesomeIcon
                        icon={formik.values.showPassword ? faEyeSlash : faEye}
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="box-check d-flex justify-content-between align-items-center">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          className="d-flex align-items-center"
                          name="rememberMe"
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                      <p>
                        <span
                          className="text-blue cursor-pointer"
                          onClick={() => navigate("/forget-password")}
                        >
                          Forgot Password?
                        </span>
                      </p>
                    </div>

                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme w-100"
                      disabled={loading || submittingLoader}
                    >
                      {loading || submittingLoader ? <Loader /> : null}
                      Login
                    </Button>

                  </Form>
                  <p className="text-center btm-text mb-0">
                    Need a Campaign Account?{" "}
                    <span
                      className="text-blue cursor-pointer"
                      onClick={() => navigate("/signup")}
                    >
                      Create an account
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
