import { createContext, FC, ReactNode } from "react";
import useJourneyBuilder from "./useJourneyBuilder";

interface JourneyProviderProps {
    children: ReactNode;
}

const JourneyContext = createContext<any>(null);

const JourneyProvider: FC<JourneyProviderProps> = ({ children }) => {
    const journeyState = useJourneyBuilder();
    return (
        <JourneyContext.Provider value={journeyState}>
            {children}
        </JourneyContext.Provider>
    );
};

export { JourneyProvider, JourneyContext };
