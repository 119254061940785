/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import XLoader from "../loader/XLoader";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import { mergeTags } from "./utils/stripo.constant";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useSidebar from "../../hooks/useSidebar";
import { useNavigate } from "react-router-dom";

interface StripoProps {
  html?: string;
  css?: string;
}

export default function Stripo(props: StripoProps) {
  const { html, css } = props;
  const { fetchData } = useAxios()
  const { onShowSidebar } = useSidebar();
  const navigate = useNavigate();

  useEffect(() => {
    if (html || css) {
      const initStripo = () => {
        const apiRequestData = {
          emailId: 123,
        };
        const script = document.createElement("script");
        script.id = "stripoScript";
        script.type = "text/javascript";
        script.src = "https://plugins.stripo.email/static/latest/stripo.js";
        script.onload = function () {
          window.Stripo.init({
            unSubscribeLink: "http://unsubscribe.com",
            // socialNetworks: [
            //   {
            //     name: "twitter",
            //     href: "https://twitter.com/my-twitter",
            //   },
            //   {
            //     name: "facebook",
            //     href: "https://facebook.com/my-facebook",
            //   },
            // ],
            mergeTags: [
              {
                category: "Patient Campaign",
                entries: mergeTags,
              },
            ],
            customAppearanceMergetags: true,
            mergeTagsOptions: {
              useIcon: true,
              title: "merge tag"
            },
            // specialLinks: [
            //   {
            //     category: "eSputnik",
            //     entries: [
            //       { label: "Unsubscribe", value: "https://esputnik.com/unsubscribe" },
            //       {
            //         label: "View in browser",
            //         value: "https://esputnik.com/viewInBrowser",
            //       },
            //     ],
            //   },
            //   {
            //     category: "Other",
            //     entries: [
            //       {
            //         label: "Some special link",
            //         value: "https://some.special.link.url",
            //       },
            //     ],
            //   },
            // ],
            settingsId: "stripoSettingsContainer",
            previewId: "stripoPreviewContainer",
            codeEditorButtonId: "codeEditor",
            undoButtonId: "undoButton",
            redoButtonId: "redoButton",
            locale: "en",
            html: html,
            css: css,
            apiRequestData: apiRequestData,
            userFullName: "Plugin Demo User",
            // versionHistory: {
            //   changeHistoryLinkId: "changeHistoryLink",
            //   onInitialized: function (lastChangeIndoText) {
            //     $("#changeHistoryContainer").show();
            //   }
            // },
            getAuthToken: async (callback: (token: string) => void) => {
              try {
                const response = await fetchData(API_ENDPOINTS.STRIPO_GET_AUTH_TOKEN)
                callback(response.data.token);
              } catch (error) {
              }
            },
            conditionsEnabled: true,
            // customConditionsEnabled: true,
            conditionCategories: [
              {
                "type": "PREDEFINED",
                "category": "Gender",
                "conditions": [
                  {
                    "id": "1",
                    "name": "Men",
                    "description": "Men only",
                    "beforeScript": "<!-- %IF in_array('men', $CATEGORY) -->",
                    "afterScript": "<!-- %/IF% -->"
                  },
                  {
                    "id": "2",
                    "name": "Women",
                    "description": "Women only",
                    "beforeScript": "<!-- %IF in_array('women', $CATEGORY) -->",
                    "afterScript": "<!-- %/IF% -->"
                  }
                ]
              }
            ],
            // onTemplateLoaded:()=>{
            //   context.setIsLoading(false)
            // }
          });
        };
        document.body.appendChild(script);
      }
      initStripo();
    }

    return () => {
      window?.StripoApi?.stop();
    }
  }, [html, css]);





  return (
    <div className="stripo">
      <div className="stripo__header main-title d-flex align-items-center justify-content-start">
        <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
          <FontAwesomeIcon icon={faBars} />
        </span>
        <Button id="codeEditor" className="csv-download-btn-style">View Html</Button>
        <Button className="csv-download-btn-style" onClick={()=>navigate("/org/content/pre-built-templates")}>Pre-Built Templates</Button>
        <button id="undoButton">Undo</button>
        <button id="redoButton">Redo</button>
      </div>
      <div className="stripo__content">
        <div id="stripoSettingsContainer"></div>
        <div id="stripoPreviewContainer">
          <XLoader />
        </div>
      </div>
    </div>
  );
}
