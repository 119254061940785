/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import PaginationNav from "../../../component/pagination/pagination";
import { Form } from "react-bootstrap";
import { debounce, utcToLocalStringWithTime } from "../../../utils/helper";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import XLoader from "../../../component/loader/XLoader";
import useSidebar from "../../../hooks/useSidebar";

interface LoginActivity {
  user_id: string;
  ip_address: string;
  user_agent: string;
  login_status: string;
  failure_reason: string;
  login_timestamp: string;
}

const LogInActivities: React.FC = () => {
  const [loginActivities, setLoginActivities] = useState<LoginActivity[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [queryParams, setQueryParams] = useState({
    search: "",
    page: 1,
    limit: 10,
  });
  const { fetchData, isLoading } = useAxios();
  const { onShowSidebar } = useSidebar();

  useEffect(() => {
    fetchLoginActivities();
  }, [queryParams]);

  const fetchLoginActivities = async () => {
    try {
      const { search, page, limit } = queryParams;
      let query = `${API_ENDPOINTS.LOGIN_ACTIVITIES}?page=${page}&limit=${limit}`;
      if (search) {
        query += `&search=${search}`;
      }
      const response = await fetchData(query);
      const data = response?.data?.loginActivities.map((activity: any) => {
        const userAgentParts = activity.user_agent.split(" ");
        const browserAndVersion =
          userAgentParts[userAgentParts.length - 2] +
          "" +
          userAgentParts[userAgentParts.length - 1];
        return { ...activity, user_agent: browserAndVersion };
      });
      setLoginActivities(data);
      const totalRecords = response?.data?.totalCount;
      const totalPages = Math.ceil(totalRecords / queryParams?.limit);
      setTotalPages(totalPages);
    } catch (error) {}
  };

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleSearchChange = (searchValue: string): void => {
    setQueryParams((prevParams) => ({ ...prevParams, search: searchValue, page: 1 }));
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 500);

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Login Activities
        </h2>
        <div className="filter-bar d-flex align-items-center row-item top-block">
          <div className="position-relative search-bar">
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => debouncedHandleSearchChange(e.target.value)}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        </div>
      </div>
      <div className="table-listing all-contacts users-list position-relative">
        <Table responsive>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Emails</th>
              <th>Ip Address</th>
              <th>User Agent</th>
              <th>Login Status</th>
              <th>Failure Reason</th>
              <th>Login Timestamp</th>
            </tr>
          </thead>
            
          <tbody>
            {loginActivities?.map((activity: any, index: number) => (
              <tr key={activity.id}>
                <td>
                  {(queryParams.page - 1) * queryParams.limit + index + 1}
                </td>
                <td>{activity.email}</td>
                <td>{activity.ip_address}</td>
                <td>{activity.user_agent}</td>
                <td>{activity.login_status}</td>
                <td>{activity.failure_reason ?? "N/A"}</td>
                <td>{utcToLocalStringWithTime(activity.login_timestamp)}</td>
              </tr>
            ))}
            {!isLoading && loginActivities?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="text-center no-records">No record found.</p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {isLoading && loginActivities?.length === 0 && <XLoader />}
      </div>
      {!isLoading && loginActivities?.length !== 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default LogInActivities;
