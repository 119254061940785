import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
} from '../../modules/compaigns/compaignSlice';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../axios/interceptors';
import { API_ENDPOINTS } from '../../../constants/api';
import {
    getContactFailure, getContactRequest, getContactSuccess, getTagsRequest,
    getTagsSuccess,
    getTagsFailure,
    addContactRequest,
    addContactSuccess,
    addContactFailure,
    deleteContactFailure,
    deleteContactRequest,
    deleteContactSuccess,
    editContactRequest,
    editContactSuccess,
    editContactFailure,
    contactDetailRequest,
    contactDetailSuccess,
    contactDetailFailure,
    createOrFindTagRequest,
    createOrFindTagSuccess,
    createOrFindTagFailure,
    getSegmentsRequest,
    getSegmentsSuccess,
    getSegmentsFailure,
    createSegmentSuccess,
    createSegmentFailure,
    createSegmentRequest,
    segmentDetailSuccess,
    segmentDetailFailure,
    segmentDetailRequest,
    updateSegmentRequest,
    updateSegmentSuccess,
    updateSegmentFailure,
    deleteSegmentSuccess,
    deleteSegmentFailure,
    deleteSegmentRequest
} from './contactSlice';

function* getContactSaga(action: ReturnType<typeof getContactRequest>) {
    try {
        const { payload } = action;
        let endpoint = `${API_ENDPOINTS.GET_CONTACT_LIST}?page=${payload.page}&limit=${payload.limit}`;
        const {  search,filters} = payload;
        if (search) {
            endpoint += `&search=${search}`;
        }
        if (filters && filters.length > 0) {
            const filtersString = encodeURIComponent(JSON.stringify(filters));
            endpoint += `&filters=${filtersString}`;
          }
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        yield put(getContactSuccess(response.data));
    } catch (error: any) {
        yield put(getContactFailure(error.response.data || error));
    }
}

function* getTagsSaga(action: ReturnType<typeof getTagsRequest>) {
    try {
        const response: AxiosResponse = yield call(() => getRequest(API_ENDPOINTS.GET_TAGLIST));
        yield put(getTagsSuccess(response.data));
    } catch (error: any) {
        yield put(getTagsFailure(error.response.data || error));
    }
}

function* createOrFindTagSaga(action: ReturnType<typeof createOrFindTagRequest>) {
    const { payload } = action;
    try {      
          const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.CREATE_OR_FIND_TAG,payload));
            yield put(createOrFindTagSuccess(response.data));
            yield put(getTagsRequest());

    } catch (error: any) {
            yield put(createOrFindTagFailure(error.response.data || error));
    }
}

function* addContactSaga(action: ReturnType<typeof addContactRequest>) {
  try {
    const { data, contactId } = action.payload;
    if (data instanceof FormData) {
      const response: AxiosResponse = yield call(() =>
        postRequest(API_ENDPOINTS.CREATE_CONTACTS_WITH_CSV, data)
      );
      yield put(addContactSuccess(response.data));
    } else {
      const response: AxiosResponse = yield call(() =>
        postRequest(`${API_ENDPOINTS.ADD_CONTACT}/${contactId}`, data)
      );
      yield put(addContactSuccess(response.data));
    }
  } catch (error: any) {
    yield put(addContactFailure(error.response.data || error));
  }
}

function* deleteContactSaga(action: ReturnType<typeof deleteContactRequest>) {
    try {
        const contactId = action.payload;
        const response: AxiosResponse = yield call(() => deleteRequest(`${API_ENDPOINTS.DELETE_CONTACT}/${contactId}`));
        yield put(deleteContactSuccess(response.data));
    } catch (error: any) {
        yield put(deleteContactFailure(error.response.data || error));
    }
}

function* editContactSaga(action: ReturnType<typeof editContactRequest>) {
    try {
        const { contactId, updatedData } = action.payload;
        const response: AxiosResponse = yield call(() => putRequest(`${API_ENDPOINTS.EDIT_CONTACT}/${encodeURIComponent(contactId)}`, updatedData));
        yield put(editContactSuccess(response.data));
    } catch (error: any) {
        yield put(editContactFailure(error.response.data || error));
    }
}
function* contactDetailSaga(action: ReturnType<typeof contactDetailRequest>) {
    try {
        const contactId = encodeURIComponent(action.payload);
        const response: AxiosResponse = yield call(() => getRequest(`${API_ENDPOINTS.CONTACT_DETAILS}/${contactId}`));
        yield put(contactDetailSuccess(response.data));
    } catch (error: any) {
        yield put(contactDetailFailure(error.response.data || error));
    }
}

function* getSegmentSaga(action: ReturnType<typeof getSegmentsRequest>) {
    try {
        const {page,limit}= action.payload;
        let endpoint = `${API_ENDPOINTS.GET_SEGMENT_LIST}?page=${page}&limit=${limit}`;
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        yield put(getSegmentsSuccess(response.data));
    } catch (error: any) {
        yield put(getSegmentsFailure(error.response.data || error));
    }
}
function* createSegmentSaga(action: ReturnType<typeof createSegmentRequest>) {
    try {
        const { payload } = action;
        const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.CREATE_SEGMENT, payload));
        yield put(createSegmentSuccess(response.data));
    } catch (error: any) {
        yield put(createSegmentFailure(error.response.data || error));
    }
  }
  function* deleteSegmentSaga(action: ReturnType<typeof deleteSegmentRequest>) {
    try {
        const segmentId = action.payload;
        const response: AxiosResponse = yield call(() => deleteRequest(`${API_ENDPOINTS.DELETE_SEGMENT}/${segmentId}`));
        yield put(deleteSegmentSuccess(response.data));
    } catch (error: any) {
        yield put(deleteSegmentFailure(error.response.data || error));
    }
}

  function* segmentDetailSaga(action: ReturnType<typeof segmentDetailRequest>) {
    try {
        const segmentId = action.payload;
        const response: AxiosResponse = yield call(() => getRequest(`${API_ENDPOINTS.SEGMENT_DETAILS}/${segmentId}`));
        yield put(segmentDetailSuccess(response.data));
    } catch (error: any) {
        yield put(segmentDetailFailure(error.response.data || error));
    }
}

function* updateSegmentSaga(action: ReturnType<typeof updateSegmentRequest>) {
    try {
        const { segmentId, updatedData } = action.payload;
        const response: AxiosResponse = yield call(() => putRequest(`${API_ENDPOINTS.UPDATE_SEGMENT}/${segmentId}`, updatedData));
        yield put(updateSegmentSuccess(response.data));
    } catch (error: any) {
        yield put(updateSegmentFailure(error.response.data || error));
    }
}
function* contactSaga() {
    yield takeLatest(getContactRequest.type, getContactSaga);
    yield takeLatest(addContactRequest.type, addContactSaga);
    yield takeLatest(deleteContactRequest.type, deleteContactSaga);
    yield takeLatest(contactDetailRequest.type, contactDetailSaga);
    yield takeLatest(editContactRequest.type, editContactSaga);
    yield takeLatest(getTagsRequest.type, getTagsSaga);
    yield takeLatest(createOrFindTagRequest.type, createOrFindTagSaga);
    yield takeLatest(getSegmentsRequest.type, getSegmentSaga);
    yield takeLatest(createSegmentRequest.type, createSegmentSaga);
    yield takeLatest(deleteSegmentRequest.type, deleteSegmentSaga);
    yield takeLatest(segmentDetailRequest.type, segmentDetailSaga);
    yield takeLatest(updateSegmentRequest.type, updateSegmentSaga);
}

export default contactSaga;