import { CampaignType } from "../../../utils/enums";

export const mergeTags = [
    {
      label: "First Name",
      value: "{{firstname}}",
      type: "Email-SMS"
    },
    {
      label: "Last Name",
      value: "{{lastname}}",
      type: "Email-SMS"
    },
    {
      label: "Phone Number",
      value: "{{phone_number}}",
      type: "Email-SMS"
    },    
    {
      label: "Address",
      value: "{{address}}",
      type: "Email-SMS"
    },    
    {
      label: "City",
      value: "{{city}}",
      type: "Email-SMS"
    },
    {
      label: "State",
      value: "{{state}}",
      type: "Email-SMS"
    },
    {
      label: "Zipcode",
      value: "{{zipcode}}",
      type: "Email-SMS"
    },
    {
      label: "Email",
      value: "{{email}}",
      type: "Email-SMS"
    },
    {
      label: "DOB",
      value: "{{DOB}}",
      type: "Email-SMS"
    },
    {
      label: "Unsubscribe Link",
      value: "{{unsubscribeURL}}",
      type: CampaignType.EMAIL
    }
  ]