/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Container ,Row ,Col,Button,Form ,Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IMAGES } from "../../../constants/imagePath";
import { CreatePasswordFormValues } from "../../../redux/modules/auth/types";
import { ressetUpdatePassword, updatePasswordRequest } from "../../../redux/modules/auth/authSlice";
import { RootState } from "../../../redux/rootReducer";
import Loader from "../../../component/loader";

const NewPassword: React.FC = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {updatePasswordRes ,loading} = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!updatePasswordRes) { return }
    if(updatePasswordRes?.success)navigate("/login")
    toast(updatePasswordRes?.message)
    dispatch(ressetUpdatePassword());
  }, [updatePasswordRes])

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and include at least one letter, one number, and one special character.'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  const formik = useFormik<CreatePasswordFormValues>({
    initialValues: {
      password: "",
      confirmPassword:"",
      showPassword: false,
      showConfirmPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: CreatePasswordFormValues) => {
      const { password } = values;
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('activationToken');
      dispatch(updatePasswordRequest({ password , token }));
    },
  });

  const togglePasswordVisibility = () => {
    formik.setFieldValue('showPassword', !formik.values.showPassword);
    formik.setFieldValue('password', formik.values.password, true);
  };

  const toggleConfirmPasswordVisibility = () => {
    formik.setFieldValue('showConfirmPassword', !formik.values.showConfirmPassword);
    formik.setFieldValue('confirmPassword', formik.values.confirmPassword, true);
  };
  return (
    <div>
      <div className="login card-bg">
        <div className="card">
          <Container>
            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="bg-blue d-flex align-items-center"
              >
                <div className="lt-text text-center">
                  <Image src={IMAGES.darkLogo} />
                  <h1 className="title">
                    Improve patient engagement with personalized campaigns
                  </h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className="bg-white">
                <div className="rt-content">
                  <Image src={IMAGES.blueLogo} />
                  <div className="heading-title">
                  <h2>Set New Password</h2>
                  </div>
                  <Form
                    className="input-form"
                    onSubmit={formik.handleSubmit}
                    noValidate
                  >
                    <Form.Group
                      controlId="formBasicPassword"
                      className="position-relative form-group"
                    >
                      <Form.Control
                        type={formik.values.showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter New Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.password && !!formik.errors.password}
                        required
                      />
                      <FontAwesomeIcon
                        icon={formik.values.showPassword ? faEyeSlash : faEye}
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      controlId="formBasicPassword"
                      className="position-relative form-group"
                    >
                      <Form.Control
                        type={formik.values.showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                        required
                      />
                      <FontAwesomeIcon
                        icon={formik.values.showConfirmPassword ? faEyeSlash : faEye}
                        className="eye-icon"
                        onClick={toggleConfirmPasswordVisibility}
                      />
                      <Form.Control.Feedback type="invalid">{formik.errors.confirmPassword}</Form.Control.Feedback>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme w-100"
                      disabled={loading}
                    >
                      {loading && <Loader/>}
                      Reset Password
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
