import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  CustomFieldPayload, GetUserListRequestParams, SettingsState } from '../auth/types';

const initialState: SettingsState = {
    predefinedFieldList: [],
    loading: false,
    error: null,
    customField:null,
    userlist: []
};

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getPredefinedFieldRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getPredefinedFieldSuccess(state, action: PayloadAction<any>) {
            state.predefinedFieldList = action.payload;
            state.loading = false;
            state.error = null;
        },
        getPredefinedFieldFailure(state, action: PayloadAction<string>) {
            state.predefinedFieldList = [];
            state.loading = false;
            state.error = action.payload;
        },
        createCustomFieldRequest(state, action:PayloadAction<CustomFieldPayload>) {
            state.loading = true;
            state.error = null;
        },
        createCustomFieldSuccess(state, action: PayloadAction<any>) {
            state.customField = action.payload;
            state.loading = false;
            state.error = null;
        },
        createCustomFieldFailure(state, action: PayloadAction<string>) {
            state.customField = null;
            state.loading = false;
            state.error = action.payload;
        },
        getUserListRequest(state, action: PayloadAction<GetUserListRequestParams>) {
            state.loading = true;
            state.error = null;
        },
        getUserListSuccess(state, action: PayloadAction<any>) {
            state.userlist = action.payload;
            state.loading = false;
            state.error = null;
        },
        getUserListFailure(state, action: PayloadAction<string>) {
            state.userlist = [];
            state.loading = false;
            state.error = action.payload;
        },
        clearData: (state) => { 
            state.error = null;     
            state.customField = null;
          },
    },
});

export const {
    getPredefinedFieldRequest,
    getPredefinedFieldSuccess,
    getPredefinedFieldFailure,
    createCustomFieldRequest,
    createCustomFieldSuccess,
    createCustomFieldFailure,
    getUserListRequest,
    getUserListSuccess,
    getUserListFailure,
    clearData
} = settingSlice.actions;

export default settingSlice.reducer;
