/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { mapMaker } from '../utils/options';

export type TriggerType = {
  id: number,
  name: string
  description: string
}

export type TriggerMapType = {
  [key: string]: TriggerType
}

const useTrigger = () => {
  const [triggerMap, setTriggerMap] = useState<TriggerMapType>({});
  const { fetchData } = useAxios();
  const triggers = Object.values(triggerMap)


  useEffect(() => {
    const fetchTriggers = async () => {
      const response = await fetchData(API_ENDPOINTS.GET_TRIGGERS);
      if (response?.success) {
        setTriggerMap(mapMaker(response.data, { key: 'id', value: 'id', text: 'name' }));
      }
    };

    fetchTriggers();
  }, []);


  return {
    triggerMap,
    triggers
  };
};

export default useTrigger;
