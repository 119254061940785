import { Chart } from "react-chartjs-2";

type BarLineChartType = {
    labels: string[];
    deliveryDataset:number[]
    openDataset: number[]
}

const BarLineChart = (props: BarLineChartType) => {
    const {labels, openDataset, deliveryDataset} = props

    const data = {
        labels,
        datasets: [
            {
                type: 'line' as const,
                label: 'Open Rate',
                borderColor: '#13294B',
                borderWidth: 2,
                fill: false,
                data: openDataset,
            },
            {
                type: 'bar' as const,
                label: 'Delivery Rate',
                backgroundColor: '#7BAFD4',
                data: deliveryDataset,
                borderColor: 'white',
                borderWidth: 2,
            }
        ],
    };

    return (
        <Chart type='bar' data={data} />
    )
}

export default BarLineChart