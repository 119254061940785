/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { mapMaker } from '../utils/options';

export type RuleType = {
  id: number,
  event: string
}

export type ActionType = {
  id: number,
  action: string
}

type RulesMapType = {
  [key: string]: RuleType
}

type ActionMapType = {
  [key: string]: ActionType
}

const useRuleActions = () => {
  const [rulesMap, setRulesMap] = useState<RulesMapType>({});
  const [actionsMap, setActionsMap] = useState<ActionMapType>({});
  const { fetchData } = useAxios();
  const [rules, actions] = [Object.values(rulesMap), Object.values(actionsMap)]


  useEffect(() => {
    const fetchRuleAction = async () => {
      const response = await fetchData(API_ENDPOINTS.GET_JORNEY_RULE_ACTION);
      if (response?.success) {
        setRulesMap(mapMaker(response.data.rule, { key: 'id', value: 'id', text: 'event' }));
        setActionsMap(mapMaker(response.data.action, { key: 'id', value: 'id', text: 'action' }));
      }
    };

    fetchRuleAction();
  }, []);


  return {
    rulesMap,
    actionsMap,
    rules,
    actions,
  };
};

export default useRuleActions;
