import { CampaignStatus, CampaignType, ToListType, UserStatus } from "./enums";

export const campaignStatusOptions = Object.entries(CampaignStatus).map(
  ([key, value]) => ({
    key,
    text: value,
    value,
  })
);

export const userStatusOptions = [
  { key: '1', value: UserStatus.ACTIVE, text: UserStatus.ACTIVE },
  { key: '2', value: UserStatus.DEACTIVE, text: UserStatus.DEACTIVE },
]

export const campaignTypeOptions = Object.entries(CampaignType).map(
  ([key, value]) => ({
    key,
    text: value,
    value,
  })
);

export const toListTypeOptions = Object.entries(ToListType).map(
  ([key, value]) => ({
    key,
    text: key.charAt(0) + key.slice('1').toLowerCase(),
    value,
  })
);

export const optionMaker = (data, { key = 'name', text = 'name', value = 'id' } = {}) => {
  const formattedOption = data.reduce((acc, v, index) => {
    acc[v[value]] = {
      value: v[value],
      text: v[text],
      key: `${v[key]}-${index}`,
    };
    return acc;
  }, {});
  return formattedOption
}

export const mapMaker = (data, { key = 'name', text = 'name', value = 'id' } = {}) => {
  const formattedOption = data.reduce((acc, v, index) => {
    acc[v[value]] = {
      value: v[value],
      text: v[text],
      label: v[text],
      key: `${v[key]}-${index}`,
      ...v
    };
    return acc;
  }, {});
  return formattedOption
} 

export const Months = [
  { text: 'January', value: '01', key: '01' },
  { text: 'February', value: '02', key: '02' },
  { text: 'March', value: '03', key: '03' },
  { text: 'April', value: '04', key: '04' },
  { text: 'May', value: '05', key: '05' },
  { text: 'June', value: '06', key: '06' },
  { text: 'July', value: '07', key: '07' },
  { text: 'August', value: '08', key: '08' },
  { text: 'September', value: '09', key: '09' },
  { text: 'October', value: '10', key: '10' },
  { text: 'November', value: '11', key: '11' },
  { text: 'December', value: '12', key: '12' }
];
