/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";

export type AnalyticDateFilterType = {
  dateRange: "lastMonth" | "lastYear" | "custom";
  from?: string;
  to?: string;
};

export type BestCampaignResponseType = {
  title: string;
  type: string;
  scheduled_time: string;
  open: number;
  click: number;
  unsubscribe: number;
  open_rate: string;
  contact_count: number;
  delivered_rate: number;
  click_rate: number;
  unsubscribe_rate: number;
  hard_bounce: string;
  soft_bounce: string;
};

const useBestCampaign = (filter: AnalyticDateFilterType) => {
  const [bestCampaign, setBestCampaign] = useState<
    BestCampaignResponseType[] | []
  >([]);
  const [type, setType] = useState("");
  const { postData, isLoading } = useAxios();

  useEffect(() => {
    if (
      filter.dateRange &&
      (filter.dateRange !== "custom" || (filter.from && filter.to))
    ) {
      getBestCampaign();
    }
  }, [type, filter]);

  const getBestCampaign = async () => {
    const response = await postData(API_ENDPOINTS.GET_BEST_CAMPAIGN, {
      filter: { ...filter, type },
    });
    setBestCampaign(response?.data);
  };

  const onTypeChange = (e: ChangeEvent<any>) => {
    const { value } = e.target;
    setType(value);
  };
  return { bestCampaign, onTypeChange, isLoading };
};

export default useBestCampaign;
