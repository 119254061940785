/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import CreateJourney from './createJourney';
import { tableHeadings_Journey } from './../../constants/constants';
import { API_ENDPOINTS } from '../../constants/api';
import useAxios from '../../hooks/useAxios';
import { utcToLocalStringWithTime } from '../../utils/helper';
import useSidebar from '../../hooks/useSidebar';
import PaginationNav from '../../component/pagination/pagination';
import XLoader from '../../component/loader/XLoader';
import { PermissionObject } from '../../redux/modules/auth/types';
import SingleJourneyAnalytics from './SingleJourneyAnalytics';

interface JourneyProps {
  permission?: PermissionObject
}

const Journey: React.FC<JourneyProps> = (props) => {
  const { permission } = props;
  const { fetchData, postData, isLoading } = useAxios();
  const [journeyList, setJourneyList] = useState<any[]>([]);
  const [journeyEditData, setJourneyEditData] = useState();
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    limit: 10
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [createJourneyModalShow, setCreateJourneyModalShow] = useState<boolean>(false);
  const { onShowSidebar } = useSidebar();
  const [selectedJourneyId, setSelectedJourneyId] = useState<any>(null)

  useEffect(() => {
    const fetch = async () => {
      try {
      const {  search,page,limit} = queryParams;
        let query = `${API_ENDPOINTS.GET_JOURNEY_LIST}?page=${page}&limit=${limit}`
        if (search) {
            query += `&search=${search}`;
        }
        const response = await fetchData(query);
        setJourneyList(response.journeyList);
        const totalJourney = response?.totalCount;
        const totalNoOfPages = Math.ceil(totalJourney / queryParams?.limit);
        setTotalPages(totalNoOfPages);
      } catch (error) {}
    };
    fetch();
  }, [queryParams]);

  useEffect(() => {
    !createJourneyModalShow && setJourneyEditData(undefined)
  }, [createJourneyModalShow])

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleJourneyEdit = (journey: any) => {
    setJourneyEditData(journey)
    handelShowCreateModalToggle()
  }

  const handelShowCreateModalToggle = ()=>{
    setCreateJourneyModalShow(!createJourneyModalShow)
  }

  const isActiveChangeHandle = async (id: number, checked: boolean) => {
    const updatedJourneyList = journeyList.map((entry) => {
      if (entry.id === id) {
        entry.active = checked;
      }
      return entry;
    });
    setJourneyList(updatedJourneyList);
    await postData(API_ENDPOINTS.UPDATE_IS_ACTIVE_JOURNEY, { journeyId: id });
  };

  const onJourneyNameClick = async (journeyId: number) => {
    setSelectedJourneyId(journeyId)
  }

  return (
    <div>
      {createJourneyModalShow && <CreateJourney journeyEditData={journeyEditData} show={createJourneyModalShow} handelToggle={handelShowCreateModalToggle}/>}
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Journeys</h2>
          {(permission?.edit) && (
            <Button
              variant="primary"
              type="submit"
              className="btn-theme"
              onClick={handelShowCreateModalToggle}
            >Create New Journey
            </Button>
          )}
      </div>
      <div className="table-listing contact-list all-contacts journeys position-relative">
        <Table responsive>
          <thead>
            <tr>
            {tableHeadings_Journey.map((heading, index) => (
            <th key={index}
            // onClick={() => handleSortClick(heading)}
            >{heading} 
            </th>
          ))}
            </tr>
          </thead>
          
          <tbody>
          {!isLoading && journeyList?.length === 0 &&
          <tr>
            <td colSpan={9}>
          <p className='text-center no-records'>No contact found.</p>
          </td>
          </tr>}
          {!isLoading && journeyList && journeyList?.map((entry: any) => (
            <tr key={entry.id} >
              <td className='cursor-pointer' onClick={()=>onJourneyNameClick(entry.id)}>{entry.name}</td>
              <td>{entry.open}</td>
              <td>{entry.click}</td>
              <td>{utcToLocalStringWithTime(entry.created_at)}</td>
              <td>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={entry.active ? "Active" : "In Active"}
                  checked={entry.active ? true : false}
                  onChange={(e) => isActiveChangeHandle(entry.id, e.target.checked)}
                />
              </td>
              <td>
                {entry.status === "Draft" ? (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    onClick={() => {
                      if (permission?.edit) handleJourneyEdit(entry);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEye}
                    onClick={() => {
                      if (permission?.edit) handleJourneyEdit(entry);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <SingleJourneyAnalytics
          selectedJourneyId={selectedJourneyId}
          setSelectedJourneyId={setSelectedJourneyId}
        />
        {isLoading && <XLoader />}
      </div>
      {!isLoading && journeyList?.length > 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={queryParams.page}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  )
}

export default Journey