/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Image, Table } from "react-bootstrap";
import {
  faBars,
  faCircleXmark,
  faMagnifyingGlass,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../constants/api";
import { debounce, formatDate } from "../../utils/helper";
import XConfirm from "../../component/confirm/XConfirm";
import { toaster } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import PaginationNav from "../../component/pagination/pagination";
import XLoader from "../../component/loader/XLoader";
import { CampaignType } from "../../utils/enums";
import { XModal } from "../../component";
import { IMAGES } from "../../constants/imagePath";

type DataType = {
  [key: string]: any;
};

type OptionsMapping = {
  [key: string]: { sortBy: string; orderBy: string };
};

type FilterType = {
  search: string;
  sortBy: string;
  orderBy: string;
  folder_id: number;
  pageNumber: number;
  itemPerPage: number;
  type: string;
};

const SmsContentLibrary = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [folders, setFolders] = useState<any>({});
  const [deleteTemplateId, setDeleteTemplateId] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const itemPerPage = 10;
  const navigate = useNavigate();
  const { onShowSidebar } = useSidebar();
  const [previewSms, setPreviewSms] = useState<string>("");

  const [filter, setFilter] = useState<FilterType>({
    search: "",
    sortBy: "updated_at",
    orderBy: "desc",
    folder_id: 0,
    pageNumber: 1,
    itemPerPage: itemPerPage,
    type: CampaignType.SMS,
  });

  const totalPages = Math.ceil(totalCount / itemPerPage);

  const { fetchData, postData, isLoading } = useAxios();

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    fetchTemplate();
  }, [filter]);

  const columns = [
    {
      title: "Preview",
      type: "img",
      onClick: (value: any) => {
        setPreviewSms(value.content);
      },
      imgData: {
        src: IMAGES.smsPreviewThumbnail,
      },
    },
    {
      title: "Name",
      dataField: "name",
    },
    {
      title: "Last Updated",
      dataField: "updated_at",
      wrapperFunc: formatDate,
    },
  ];

  const actions = {
    title: "Action",
    type: "action",
    data: [
      {
        title: "Delete",
        onClick: (v: any) => {
          setDeleteTemplateId(v.id);
        },
        className: "delete",
        icon: faCircleXmark,
      },
      {
        title: "Edit",
        onClick: (v: any) => {
          navigate(`/org/content/create-sms-content/${v.id}`, {
            state: { editTemplateData: v },
          });
        },
        className: "edit",
        icon: faPenToSquare,
      },
    ],
  };

  const handlePageChange = (pageNumber: number) => {
    setFilter((prevFilter) => ({ ...prevFilter, pageNumber }));
  };

  const handleSearchChange = (search: string) => {
    setFilter((prevFilter) => ({ ...prevFilter, search, pageNumber: 1 }));
  };

  const handleFolderChange = (folderId: number) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      folder_id: folderId,
      pageNumber: 1,
    }));
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;

    const optionsMapping: OptionsMapping = {
      "1": { sortBy: "updated_at", orderBy: "desc" }, // Newest First
      "2": { sortBy: "updated_at", orderBy: "asc" }, // Oldest First
      "3": { sortBy: "name", orderBy: "asc" }, // A-Z
      "4": { sortBy: "name", orderBy: "desc" }, // Z-A
    };

    setFilter((prevFilter) => ({
      ...prevFilter,
      ...optionsMapping[selectedValue],
    }));
  };

  const fetchTemplate = async () => {
    try {
      const response = await postData(API_ENDPOINTS.GET_ALL_TEMPLATES, filter);
      setData(response?.data?.templates ?? []);
      setTotalCount(response?.data?.totalCount ?? 0);
    } catch (error) {}
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 500);

  const fetchFolders = async () => {
    try {
      const type = CampaignType.SMS;
      const response = await fetchData(
        `${API_ENDPOINTS.GET_ALL_FOLDERS}/${type}`
      );
      const formattedOption = response?.data.reduce((acc: any, v: any) => {
        acc[v.id] = {
          value: v.id,
          text: v.name,
          label: v.name,
          key: v.name,
          data: v,
        };
        return acc;
      }, {});
      setFolders(formattedOption);
    } catch (error) {}
  };

  const deleteHandler = async () => {
    try {
      const response = await postData(API_ENDPOINTS.DELETE_TEMPLATE, {
        templateId: deleteTemplateId,
      });
      const { message, success } = response;
      if (success) {
        setData((prevData) =>
          prevData.filter((v) => v.id !== deleteTemplateId)
        );
        setDeleteTemplateId("");
      }
      toaster({ message, success });
    } catch (error) {
      toaster({ message: "An error occurred", success: false });
      setDeleteTemplateId("");
    }
  };

  return (
    <div>
      {previewSms && (
        <XModal
          title="SMS Preview"
          show={!!previewSms}
          handleClose={() => setPreviewSms("")}
          size="xl"
          body={
            <div style={{ maxHeight: "67vh", overflowY: "auto" }}>
              <div
                style={{ pointerEvents: "none" }}
                dangerouslySetInnerHTML={{
                  __html: previewSms ?? "",
                }}
              />
            </div>
          }
        />
      )}
      {deleteTemplateId && (
        <XConfirm
          show={!!deleteTemplateId}
          onCancel={() => setDeleteTemplateId("")}
          onConfirm={deleteHandler}
          isLoading={isLoading}
        />
      )}
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          SMS Content Library
        </h2>
        <Button
          variant="primary"
          onClick={() => navigate("/org/content/create-sms-content")}
          className="btn-theme"
        >
          Create SMS Template
        </Button>
      </div>
      <div className="content-library">
        <div className="filter-bar d-flex align-items-center justify-content-between w-100">
          <div className="position-relative search-bar">
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => debouncedHandleSearchChange(e.target.value)}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <div className="select-box d-flex align-items-center w-100 justify-content-end">
            <Form.Group
              className="d-flex align-items-center filter-box"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Select Folder</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={filter.folder_id}
                onChange={({ target: { value } }) => handleFolderChange(+value)}
              >
                <option>Select</option>
                {Object.values(folders).map(({ value, label }: any, k) => {
                  return (
                    <option key={k} value={value}>
                      {label}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Sort By</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSortChange}
              >
                <option value="">Select</option>
                <option value="1">Newest First</option>
                <option value="2">Oldest First</option>
                <option value="3">A-Z</option>
                <option value="4">Z-A</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>

        <div>
          <div className="table-listing contact-list position-relative">
            <Table responsive>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index}>{column.title}</th>
                  ))}
                  {actions && <th>{actions.title}</th>}
                </tr>
              </thead>
                
              <tbody>
                {!isLoading && data?.length === 0 && (
                  <tr>
                    <td colSpan={9}>
                      <p className="text-center no-records">No record found.</p>
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  data.map((value, index) => (
                    <tr key={index}>
                      {columns.map((column, colIndex) => {
                        const onClickHandler = column?.onClick ?? (() => {});
                        return (
                          <td
                            key={`tr-td-${colIndex}`}
                            onClick={() => onClickHandler(value)}
                          >
                            {column.dataField &&
                              !column?.wrapperFunc &&
                              value[column.dataField]}
                            {column.dataField &&
                              column?.wrapperFunc &&
                              column?.wrapperFunc(value[column.dataField])}
                            {column.type === "img" && (
                              <Image src={column.imgData.src} />
                            )}
                          </td>
                        );
                      })}

                      {actions && (
                        <td className="action-badges">
                          {actions.data.map((action, actionIndex) => {
                            const onClickHandler =
                              action?.onClick ?? (() => {});

                            return (
                              <button
                                key={actionIndex}
                                onClick={() => onClickHandler(value)}
                                className={action.className}
                              >
                                <span className="d-flex align-items-center">
                                  {action.title}{" "}
                                  <FontAwesomeIcon icon={action.icon} />
                                </span>
                              </button>
                            );
                          })}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
            {isLoading && <XLoader />}
          </div>
          {!isLoading && data?.length !== 0 && (
            <PaginationNav
              totalPages={totalPages}
              currentPage={filter.pageNumber}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SmsContentLibrary;
