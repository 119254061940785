import { useState } from 'react';
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import { UserDeatail } from '../../../utils/interface';
import { setSelectedLocationId, setUserData } from '../../../redux/modules/auth/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { setLocationIdInLocalStorage } from '../../../utils/helper';
  

interface CompanyHook {
    companyDetail: UserDeatail;
    fetchCompanyDetail: () => void;
    isLoading: boolean,
    locationModalShow: boolean,
    setLocationModalShow: (v:boolean) => void;
}

const useCompany = (): CompanyHook => {
    const dispatch = useDispatch();
    const companyDetail = useSelector((state: RootState) => state.user);

    // const [companyDetail, setCompanyDetail] = useState<any>();
    const [locationModalShow, setLocationModalShow] = useState<boolean>(false);
    const { fetchData, isLoading } = useAxios()

    const fetchCompanyDetail = async () => {
        const response = await fetchData(API_ENDPOINTS.GET_USER_INFO)
        if (response?.success) {
            // setCompanyDetail(response?.data ?? {})
            dispatch(setUserData(response?.data ?? {}))

            //location id set start ------
            const locationId = setLocationIdInLocalStorage(response?.data?.orgLocations)
            dispatch(setSelectedLocationId(locationId))
            //location id set end -----
        }
    }

    return {
        companyDetail,
        isLoading,
        fetchCompanyDetail,
        locationModalShow,
        setLocationModalShow
    };
};

export default useCompany