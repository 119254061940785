import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Row from "react-bootstrap/Row";
import BarLineChart from "../../component/chart/BarLineChart";
import AnalyticCard from "../../component/common/AnalyticCard";
import AnalyticDateFilter from "../../component/common/AnalyticDateFilter";
import XLoader from "../../component/loader/XLoader";
import { IMAGES } from "../../constants/imagePath";
import useDashboard from "./useDashboard";
import useSidebar from "../../hooks/useSidebar";

const Dashboard: React.FC = () => {
  const { analyticData, isLoading, error, handleFilterChange, filter, labels, deliveryDataset, openDataset } = useDashboard()
  const { onShowSidebar } = useSidebar();

  return (
    <>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Dashboard
        </h2>

      </div>
      {(isLoading || error) && <XLoader />}
      {!isLoading && (<>
        <div className="analytic-cards mb-0">
          <Row>
            <AnalyticCard
              title="Deliveries"
              value={`${analyticData?.total.delivered}`}
              icon={IMAGES.DeliveriesIcon}
            />
            <AnalyticCard
              title="Open Rate"
              value={`${analyticData?.total.openedRate ?? 0} %`}
              icon={IMAGES.OpenRateIcon}
            />
            <AnalyticCard
              title="Click Rate"
              value={`${analyticData?.total.clickedRate ?? 0} %`}
              icon={IMAGES.ClickRateIcon}
            />
            <AnalyticCard
              title="Total Contacts"
              value={`${analyticData?.total.contacts}`}
              icon={IMAGES.UnsubscribeIcon}
            />
          </Row>
        </div>
        {/* 
        <div className="analytic-cards">
          <div className="sub-title">SMS</div>
          <Row>
            <AnalyticCard
              title="Deliveries"
              value="9,575"
              icon={IMAGES.DeliveriesIcon}
            />
            <AnalyticCard
              title="Open Rate"
              value="24,64%"
              icon={IMAGES.OpenRateIcon}
            />
            <AnalyticCard
              title="Click Rate"
              value="12,24%"
              icon={IMAGES.ClickRateIcon}
            />
            <AnalyticCard
              title="Unsubscribe Rate"
              value="23"
              icon={IMAGES.UnsubscribeIcon}
            />
            <AnalyticCard
              title="Bounce Rate"
              value="12,24%"
              icon={IMAGES.BounceIcon}
            />
          </Row>
        </div> */}

        <BarLineChart labels={labels} openDataset={openDataset} deliveryDataset={deliveryDataset} />

        <div className="analytic-cards mb-0 mt-5">
          <div className="sub-title d-flex align-items-center justify-content-between">
            <div>
              Email
            </div>
           <AnalyticDateFilter value={filter} onChange={handleFilterChange}/>
          </div>

          <Row>
            <AnalyticCard
              title="Deliveries"
              value={`${analyticData?.email.delivered}`}
              icon={IMAGES.DeliveriesIcon}
            />
            <AnalyticCard
              title="Open Rate"
              value={`${analyticData?.email.openedRate ?? 0} %`}
              icon={IMAGES.OpenRateIcon}
            />
            <AnalyticCard
              title="Click Rate"
              value={`${analyticData?.email.clickedRate ?? 0} %`}
              icon={IMAGES.ClickRateIcon}
            />
            <AnalyticCard
              title="Unsubscribe Rate"
              value={`${analyticData?.email.unsubscribedRate ?? 0} %`}
              icon={IMAGES.UnsubscribeIcon}
            />
            <AnalyticCard
              title="Bounce Rate"
              value={`${analyticData?.email.bounceRate ?? 0} %`}
              icon={IMAGES.BounceIcon}
            />
            <AnalyticCard
              title="Hard Bounce"
              value={`${analyticData?.email.hardBounce}`}
              icon={IMAGES.BounceIcon}
            />
            <AnalyticCard
              title="Soft Bounce"
              value={`${analyticData?.email.softBounce}`}
              icon={IMAGES.BounceIcon}
            />
          </Row>
        </div>
      </>)}
    </>
  );
};

export default Dashboard;
