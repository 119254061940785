import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../pages/auth/login';
import Signup from '../pages/auth/signup/registration';
import AccountSetup from '../pages/auth/signup/accountSetup';
import NewPassword from '../pages/auth/forgetPassword/newPassword';
import ForgetPassword from '../pages/auth/forgetPassword/index';
import TwoStepAuthentication from '../pages/auth/login/twoStepAuthentication';
import Layout from '../pages/layout/Layout';
import AdminLayout from '../pages/layout/AdminLayout';
import Unsubscribe from '../pages/unsubscribe';
import OTPAuthentication from '../pages/auth/login/otpAuthentication';

const Routing: React.FC = () => {
    return (
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route element={<Login />} path="/" />
        <Route
          element={<TwoStepAuthentication />}
          path="/two-step-authentication"
        />
        <Route element={<OTPAuthentication />} path="/otp-authentication" />
        <Route path="/account-setup" element={<AccountSetup />} />
        <Route path="/unsubscribe/:unsubscribeToken" element={<Unsubscribe />} />
        <Route path="/org" element={<Navigate to="dashboard" replace />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/org/*" element={<Layout />} />
          <Route path="/Admin/*" element={<AdminLayout />} />
        </Route>
        <Route path="*" element={404} />
      </Routes>
    );
}

export default Routing;