import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getRequest, postRequest, putRequest } from '../../../axios/interceptors';
import { API_ENDPOINTS } from '../../../constants/api';
import { deleteListsFailure, deleteListsRequest, deleteListsSuccess, getListsFailure, getListsRequest, getListsSuccess , addListsRequest,
    addListsSuccess,addListsFailure} from './listsSlice';

function* getListsSaga(action: ReturnType<typeof getListsRequest>) {
   try {
        const { payload } = action;
        let endpoint = `${API_ENDPOINTS.GET_LISTS}?page=${payload.page}&limit=${payload.limit}`;
        const {  search } = payload;
        if (search) {
            endpoint += `&search=${search}`;
        }
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        yield put(getListsSuccess(response.data));
    } catch (error: any) {
        yield put(getListsFailure(error.response.data || error));
    }
}
function* deleteListSaga(action: ReturnType<typeof deleteListsRequest>) {
    try {
        const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.DELETE_LISTS ,action.payload));
        yield put(deleteListsSuccess(response.data));
    } catch (error: any) {
        yield put(deleteListsFailure(error.response.data || error));
    }
}
function* addListSaga(action: ReturnType<typeof addListsRequest>) {
    try {
        if (action.payload instanceof FormData) {
            const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.CREATE_LIST_WITH_CSV_IMPORT, action.payload));
            yield put(addListsSuccess(response.data));
        } else {
            const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.CREATE_LISTS ,action.payload));
            yield put(addListsSuccess(response.data));
        }
    } catch (error: any) {
        yield put(addListsFailure(error.response.data || error));
    }
}
function* listsSaga() {
    yield takeLatest(getListsRequest.type, getListsSaga);
    yield takeLatest(deleteListsRequest.type, deleteListSaga);
    yield takeLatest(addListsRequest.type, addListSaga);
}
export default listsSaga;