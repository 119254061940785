import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { copyToClipboard } from "../../../utils/helper";

interface DnsDetailAccordianProps {
  DnsDetailData: any;
}

const DnsDetailCard: React.FC<DnsDetailAccordianProps> = ({
  DnsDetailData,
}) => {
  const handleCopy = (value: string) => {
    copyToClipboard(value)
  };

  return (
    <Accordion defaultActiveKey="0" className="locations-modal">
      Follow these steps to verify your domain
      <Accordion.Item eventKey="0">
        <Accordion.Header>Receiving records(MX)</Accordion.Header>
        <Accordion.Body>
          <Row>
            {DnsDetailData.dns_Record.MX?.map((v: any, k: any) => {
              return (
                <Col xl={12}>
                  <ul className="mb-4">
                    <li>
                      <span className="text-tp text-type">
                        <span style={{ color: "#13294B" }}>
                          <strong>Hostname: </strong>
                          {v?.hostname ?? "N/A"}
                        </span>
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(v?.hostname ?? "N/A")}
                          className="ms-2"
                          style={{ color: "#13294B" }}
                        />
                      </span>
                      <div style={{ marginLeft: "10px" }} className="d-flex">
                        <span className="text-btm text-value me-5">
                          <strong>Value: </strong> {v?.value ?? "N/A"}
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => handleCopy(v?.value ?? "N/A")}
                            className="ms-2"
                          />
                        </span>
                        <span className="text-btm text-value">
                          <strong>Priority: </strong>
                          {v?.priority ?? "N/A"}
                          <FontAwesomeIcon
                            icon={faCopy}
                            onClick={() => handleCopy(v?.priority ?? "N/A")}
                            className="ms-2"
                          />
                        </span>
                      </div>
                    </li>
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Sending records(SPF)</Accordion.Header>
        <Accordion.Body>
          <Row>
            {DnsDetailData.dns_Record.SPF?.map((v: any, k: any) => {
              return (
                <Col xl={12}>
                  <ul className="mb-4">
                    <li>
                      <span
                        style={{ color: "#13294B" }}
                        className="text-tp text-type"
                      >
                        <strong>Hostname: </strong>
                        {v?.hostname ?? "N/A"}
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(v?.hostname ?? "N/A")}
                          className="ms-2"
                        />
                      </span>
                      <span
                        style={{ marginLeft: "10px" }}
                        className="text-btm text-value"
                      >
                        <strong>Value: </strong>
                        {v?.value ?? "N/A"}
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(v?.value ?? "N/A")}
                          className="ms-2"
                        />
                      </span>
                    </li>
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Sending records(DKIM)</Accordion.Header>
        <Accordion.Body>
          <Row>
            {DnsDetailData.dns_Record.DKIM?.map((v: any, k: any) => {
              return (
                <Col xl={12}>
                  <ul className="mb-4">
                    <li>
                      <span
                        style={{ color: "#13294B" }}
                        className="text-tp text-type"
                      >
                        <strong>Hostname: </strong>
                        {v?.hostname ?? "N/A"}
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(v?.hostname ?? "N/A")}
                          className="ms-2"
                        />
                      </span>
                      <span
                        style={{ marginLeft: "10px" }}
                        className="text-btm text-value"
                      >
                        <strong>Value: </strong>
                        {v?.value ?? "N/A"}
                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(v?.value ?? "N/A")}
                          className="ms-2"
                        />
                      </span>
                    </li>
                  </ul>
                </Col>
              );
            })}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DnsDetailCard;
