/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IMAGES } from "../../../constants/imagePath";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../../constants/api";
import { postRequest } from "../../../axios/interceptors";
import { toast } from "react-toastify";
import { decodeJwt } from "../../../utils/helper";
import { ROLE } from "../../../utils/enums";
import { toaster } from "../../../utils/toast";

interface FormValues {
  otp: string;
}

const OTPAuthentication: React.FC = () => {
  const navigate = useNavigate();
  const { loginRes, loginPayload } = useSelector(
    (state: RootState) => state.auth
  );

  const validationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      submitOtp(values);
    },
  });

  useEffect(() => {
    if (loginRes?.data?.isOtpGenerated === undefined || "") {
      navigate("/login");
    }
  }, []);

  const submitOtp = async (values: FormValues) => {
    let payload = {
      loginPayload: loginPayload,
      otp: values.otp,
    };
    try {
      const res = await postRequest(API_ENDPOINTS.ANSWER, payload);
      if (res?.data.success === false) toast(res?.data?.message);
      else {
        toast(res?.data?.message);
        localStorage.setItem("authToken", res?.data?.data?.token);
        localStorage.setItem("refreshToken", res?.data?.data?.refreshToken);
        const decodedPayload = decodeJwt(res?.data?.data?.token);
        decodedPayload?.parentRoleId === ROLE.SUPER_ADMIN
          ? navigate("/Admin/dashboard")
          : navigate("/org");
      }
      formik.resetForm();
    } catch (error: any) {
      const { message } = error.response.data;
      toaster({ message, success: false });
    }
  };

  return (
    <div>
      <div className="login card-bg">
        <div className="card">
          <Container>
            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="bg-blue d-flex align-items-center"
              >
                <div className="lt-text text-center">
                  <h1 className="title">
                    Improve patient engagement with personalized campaigns
                  </h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className="bg-white">
                <div className="rt-content">
                  <Image src={IMAGES.blueLogo} />

                  <div className="heading-title">
                    <h2>Verify Your Account</h2>
                    <p>We've sent a code to {loginRes?.requestPayload?.email}</p>
                  </div>
                  <Form
                    className="input-form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <Form.Group
                      controlId="formBasicEmail"
                      className="form-group"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Verification Code"
                        {...formik.getFieldProps("otp")}
                        isInvalid={formik.touched.otp && !!formik.errors.otp}
                      />
                      {formik.touched.otp && formik.errors.otp && (
                        <div className="text-danger error-text">
                          {formik.errors.otp}
                        </div>
                      )}
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme w-100"
                    >
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default OTPAuthentication;
