import { FC } from "react";
import { Modal, Button } from "react-bootstrap";

interface ConfirmationModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  isLoading?:boolean
}

const XConfirm: FC<ConfirmationModalProps> = ({
  show,
  onCancel,
  onConfirm,
  title = "Confirmation",
  message = "Are you sure?",
  confirmLabel = "Yes",
  cancelLabel = "Close",
  isLoading = false
}) => {
  return (
    <Modal show={show} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content">
          <p>{message}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary"
          className="btn-theme btn-border"
          onClick={onCancel}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : cancelLabel}
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="btn-theme"
          onClick={onConfirm}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default XConfirm;
