import { FC, ReactNode } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface XButtonProps {
  variant?: string;
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  loadingText?: string;
  buttonText?: ReactNode | string; 
}

const XButton: FC<XButtonProps> = ({
  variant = 'primary',
  type = 'button',
  className = 'btn-theme',
  disabled = false,
  onClick = ()=>{},
  loading = false,
  loadingText = 'Loading...',
  buttonText = 'Submit',
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? <Spinner animation="border" size="sm" /> : null}
      {loading ? ` ${loadingText}` : buttonText}
    </Button>
  );
};

export default XButton;
