import { all } from 'redux-saga/effects';
import authSaga from './modules/auth/authSaga';
import accountSaga from './modules/auth/accountSetupSaga';
import locationSaga from './modules/auth/locationSaga';
import campaignSaga from './modules/compaigns/compaignSaga';
import userSaga from './modules/auth/userSaga';
import contactSaga from './modules/contacts/contactSaga';
import listSaga from './modules/lists/listsSaga';
import settingsSaga from './modules/settings/settingsSaga';



export default function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(), 
    locationSaga(),
    campaignSaga(),
    userSaga(),
    contactSaga(),
    listSaga(),
    settingsSaga(),
  ]);
}