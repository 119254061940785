/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faBars, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { clearData, createCustomFieldRequest, getPredefinedFieldRequest } from '../../../redux/modules/settings/settingsSlice';
import { CustomFieldPayload, columnTypes } from '../../../redux/modules/auth/types';
import { tableHeadings_CustomFields, tableHeadings_Predefined_Fields } from '../../../constants/constants';
import useSidebar from '../../../hooks/useSidebar';
import XButton from '../../../component/button/XButton';
import XDeleteModal from '../../../component/modal/XDeleteModal';
import { toaster } from '../../../utils/toast';
import { API_ENDPOINTS } from '../../../constants/api';
import useAxios from "../../../hooks/useAxios";


const CustomField: React.FC = () => {
  const validationSchema = Yup.object().shape({
    customFields: Yup.array().of(
      Yup.object().shape({
        columnName: Yup.string().trim()
          .required('Column Name is required')
          .matches(/^[A-Za-z_0-9 ]*$/, 'Column Name can only contain letters, space and underscores')
          .matches(/^[^0-9]*$/, 'Column Name cannot contain numbers'),
        columnType: Yup.string().required('Column Type is required'),
      })
    ),
  });
  const dispatch = useDispatch();
  const { predefinedFieldList, customField, loading, error } = useSelector((state: RootState) => state.settings);

  const formik = useFormik({
    initialValues: { customFields: [{ columnName: '', columnType: '' }] },
    validationSchema,
    onSubmit: async (values) => {
      const payload: CustomFieldPayload = {
        customFields: values.customFields,
      };
      dispatch(createCustomFieldRequest(payload))
    },
  });

  const { values, handleChange, handleSubmit, setFieldValue } = formik;
  const { onShowSidebar } = useSidebar();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState<any| null>(null);
  const { deleteData } = useAxios();

  useEffect(() => {
    dispatch(getPredefinedFieldRequest());
  }, []);

  useEffect(() => {
    formik.resetForm();
    toaster({message: customField?.message, success: customField?.success})
    dispatch(clearData())
  }, [error, customField])

  const addCustomField = () => {
    setFieldValue('customFields', [...values.customFields, { columnName: "", columnType: "" }]);
  };

  const removeCustomField = (index: number) => {
    const updatedFields = [...values.customFields];
    updatedFields.splice(index, 1);
    setFieldValue('customFields', updatedFields);
  };

  const handleDeleteIconClick = (id: number) => {
    setSelectedCustomField(id)
    setShowDeleteModal(true);
  }

  const handleDeleteField = async (id : number)=>{
    try {
      const response = await deleteData(`${API_ENDPOINTS.DELETE_CUSTOM_FIELD}/${id}`);
      if (response && response.success) {
        setShowDeleteModal(false)
        dispatch(getPredefinedFieldRequest());
        toaster({ success: true, message: response.message });
      }
    } catch (error) {
      toaster({ success: false, message: "error fetching users" });
    }
  }

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between ">
        <h2 className='back-text'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Custom Fields for Contacts</h2>
      </div>
      <div className="add-contact table-listing custom-fields">
        <h4 className='mb-4'>
          Built-In Fields
        </h4>
        <Table responsive>
          <thead>
            <tr>
            {tableHeadings_Predefined_Fields.map((heading, index) => (
            <th key={index} style={{ width: '50%' }}>{heading}</th>
          ))}
            </tr>
          </thead>
          <tbody>
            {!loading && predefinedFieldList?.predefinedFields?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className='text-center no-records'>No Predefined Fields found.</p>
                </td>
              </tr>)}
            {predefinedFieldList?.predefinedFields?.map((field: any ,index:number) => (
              <tr key={index}>
                <td>{field?.name}</td>
                <td>{field?.type}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="add-contact table-listing custom-fields">
        <h4 className='mb-4'>
          Added Custom Fields
        </h4>
        <Table responsive>
          <thead>
            <tr>
            {tableHeadings_CustomFields.map((heading, index) => (
            <th key={index} style={{ width: '50%' }}>{heading}</th>
          ))}
            </tr>
          </thead>
          <tbody>
            {!loading && predefinedFieldList?.customFields?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className='text-center no-records'>No custom Fields found.</p>
                </td>
              </tr>)}
            {predefinedFieldList?.customFields?.map((field: any ,index:number) => (
              <tr key={index}>
                <td>{field?.name}</td>
                <td>{field?.type}</td>
                <td className='action-badges'>
                  <button onClick={() => handleDeleteIconClick(field?.id)}>
                    <span className='d-flex align-items-center'>
                      <span>Delete <FontAwesomeIcon icon={faCircleXmark} /></span>
                    </span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <XDeleteModal
          show= {showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title="Delete Custom Field"
          body={<span style={{ fontSize: '16px' }}>Are you sure you want to delete the Custom field? This action will also delete all corresponding contact custom values and cannot be undone.</span>}
          onConfirm={() => {
            if (selectedCustomField) {
              handleDeleteField(selectedCustomField);
            }
            setShowDeleteModal(false);
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      </div>
      <div className="add-contact">
        <h4 className='mb-4'>
          Custom Fields
        </h4>
        <Form onSubmit={handleSubmit} className="mb-3">
          {values.customFields.map((field, index) => (
            <Row key={index} className="align-items-baseline">
              <Col xl={5} lg={4} md={4} sm={4} xs={12}>
                <Form.Group className="form-group" controlId={`columnName-${index}`}>
                  <Form.Control
                    type="text"
                    placeholder="Enter column name"
                    name={`customFields[${index}].columnName`}
                    value={field.columnName}
                    onChange={handleChange}
                    isInvalid={formik.touched.customFields && formik.touched.customFields[index] && !!((formik.errors.customFields as any)?.[index]?.columnName)}
                  />
                  <Form.Control.Feedback type="invalid">{((formik.errors.customFields as any)?.[index]?.columnName)}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={5} lg={4} md={4} sm={4} xs={12}>
                <Form.Group className="form-group" controlId={`columnType-${index}`}>
                  <Form.Select
                    name={`customFields[${index}].columnType`}
                    value={field.columnType}
                    onChange={handleChange}
                    isInvalid={formik.touched.customFields && formik.touched.customFields[index] && !!((formik.errors.customFields as any)?.[index]?.columnType)}
                  >
                    <option value="">Select Column Type</option>
                    {columnTypes.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{((formik.errors.customFields as any)?.[index]?.columnType)}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Button variant="danger" onClick={() => removeCustomField(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Col>
            </Row>
          ))}
          <div className='d-flex justify-content-between align-items-center'>
            <Button className='btn-theme' variant="primary" onClick={addCustomField}>
              <FontAwesomeIcon icon={faPlus} /> Add Field
            </Button>
            <XButton
              variant="primary"
              type="submit"
              buttonText="Submit"
              loading={loading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CustomField;
