import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlay, faPlus } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import XInput from '../../../component/form/XInput';
import { toaster } from '../../../utils/toast';
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import LocationCard from './LocationCard';
import XBackButton from '../../../component/button/XBackButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import useSidebar from '../../../hooks/useSidebar';
import { useState } from 'react';
import XButton from '../../../component/button/XButton';

const EditCompany = () => {
  const { role_id } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const { postData, isLoading } = useAxios()
  const { companyDetail = {} } = location.state;
  // const { questions } = useAuthQuestion()
  const { onShowSidebar } = useSidebar();
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      email: companyDetail?.email ?? '',
      firstName: companyDetail?.first_name ?? '',
      lastName: companyDetail?.last_name ?? '',
      organizationName: companyDetail?.business_name ?? '',
      logo: companyDetail?.filename,
      websiteURL: companyDetail?.website_url ?? '',
      phoneNumber: companyDetail?.phone_number ?? '',
      locations: companyDetail?.locations ?? [{
        country_id: '',
        state_id: '',
        city: '',
        address: '',
        zipcode: '',
      }],
      question_id: companyDetail?.question_id ?? '',
      answer: companyDetail?.two_factor_answer ?? '',
    },
    validationSchema: Yup.object().shape({
      question_id: Yup.string().test('isRequired', 'Question is required when Answer is provided', function (value) {
        const answer = this.resolve(Yup.ref('answer'));
        return !answer || !!value; // Question ID is required when answer is provided
      }),
      answer: Yup.string(),
      email: Yup.string().required('Email is required'),
      firstName: Yup.string().trim().required('First Name is required'),
      lastName: Yup.string().trim().required('Last Name is required'),
      ...(+role_id === 3 && {
        organizationName: Yup.string().trim().required('Organization Name is required'),
        logo: Yup.mixed().required('Logo is required'),
        websiteURL: Yup.string().trim().required('Website URL is required'),
      }),
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(/^[0-9]+$/, 'Phone number must contain only numeric characters'),
      locations: Yup.array().of(
        Yup.object().shape({
          address: Yup.string().trim().required('Address is required').test(
            (value) => value.trim() !== ''
          ),
          zipcode: Yup.string()
            .required('Zipcode is required')
            .matches(/^\d{5}$/, 'Zipcode must be a number of 5 digits'),
          country_id: Yup.string().required('country is required'),
          state_id: Yup.string().required('state is required'),
          city: Yup.string().trim().required('city is required').test(
            (value) => value.trim() !== ''
          ),
        })
      ).min(1, 'At least one location is required'),
    }),
    onSubmit: async (values: any, actions: any) => {
      handelCompanyUpdate(values)
    }

  });

  const handelCompanyUpdate = async (values: any) => {
    try {
      const { firstName, lastName, email, organizationName, websiteURL, locations, phoneNumber, logo, question_id, answer } = values
      let payload = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber,
        business_name: organizationName,
        website_url: websiteURL,
        locations,
        previousLogo: companyDetail?.filename,
        question_id: answer ? question_id : null,
        answer
      }
      const formData = new FormData();
      formData.append('body', JSON.stringify(payload));
      formData.append('logo', logo);

      const response = await postData(API_ENDPOINTS.UPDATE_USER_INFO, formData)
      const { message, success } = response
      if (success) {
        navigate('/org/settings/profile')
      }
      toaster({ message, success })
    } catch (error) {
      toaster({ message: 'something went wrong', success: false })
    }
  }

  const addLocationHandler = () => {
    const blankLocationObject = [{
      country_id: '',
      state_id: '',
      city: '',
      address: '',
      zipcode: '',
    }]
    formik.setFieldValue('locations', [...formik.values.locations, ...blankLocationObject])
  }

  const removeLocationHandler = (index: number) => {
    const newLocations = [...formik.values.locations];
    if (newLocations.length > 1) {
      newLocations.splice(index, 1);
      formik.setFieldValue('locations', newLocations);
    }
  }

  const handleImageChange = (selectedImage: any) => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  return (
    <div>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <div className="main-title ">
          <h2>
            <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>
            <p><span onClick={() => navigate("/org/settings/profile")}><FontAwesomeIcon icon={faPlay} />Edit Profile</span></p>
          </h2>
        </div>
        <div className="add-contact">

          <Row>
            <Col xl={4} md={6} xs={12}>

              <XInput label='Email Address' name='email' formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput name='firstName' label='First Name' formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput name='lastName' label='Last Name' formik={formik} />
            </Col>
            {+role_id === 3 && <>
              <Col xl={4} md={6} xs={12}>
                <XInput name='organizationName' label='Organization Name' formik={formik} />
              </Col>
              <Col xl={4} md={6} xs={12}>
                <Form.Group className={`form-group position-relative`}>
                  <Form.Label>Upload Business Logo</Form.Label>
                  <div className='position-relative'>
                  <Form.Control type='file' name='logo' accept='image/*' onChange={(e) => {
                    const file = (e.currentTarget as HTMLInputElement).files?.[0];
                    handleImageChange(file);
                    formik.setFieldValue('logo', file);
                  }} />
                  {previewImage ? (
                    <img className='upload-image' alt="bussiness-Logo-Preview" height={50} width={50} src={previewImage} />
                  ) : (
                    <img className='upload-image' alt='bussiness-Logo' height={50} width={50} src={companyDetail?.logoUrl} />
                  )}
                  </div>
                  {formik?.errors?.logo && formik?.touched?.logo && typeof formik.errors.logo === 'string' && (
                    <Form.Control.Feedback type="invalid">
                      {formik?.errors?.logo}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col xl={4} md={6} xs={12}>
                <XInput label='Website URL' name='websiteURL' formik={formik} />
              </Col>
            </>
            }


            {/* <Col xl={6} md={6} xs={12}>
              <XSelect
                name={`question_id`}
                label="Select Two Factor Question"
                options={Object.values(questions)}
                formik={formik}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <XInput label='Two Factor Answer' name='answer' formik={formik} />
            </Col> */}

            <Col xl={4} md={6} xs={12}>
              <XInput label='Phone Number' name='phoneNumber' formik={formik} />
            </Col>

          </Row>
          {+role_id === 3 && <p className="text-end add-link" >
          <span className='btn-add' onClick={addLocationHandler}><FontAwesomeIcon icon={faPlus} /> <span>Add Location</span></span>
          </p>}
          {
            formik.values.locations.map((v: any, index: number) => (
              <LocationCard
                key={index}
                index={index}
                locationData={v}
                formik={formik}
                removeLocationHandler={removeLocationHandler}
              />
            ))
          }

          {formik?.errors?.hasOwnProperty('locations') && (
            <span style={{ color: 'red' }}>All fields are required</span>
          )}


        </div>
        <div className="btns">
          <div className="d-flex justify-content-between align-items-center">
            <XBackButton />
            <XButton
              type="submit"
              buttonText={<>Save Changes<FontAwesomeIcon icon={faPlay} /></>}
              loading={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditCompany;
