export const CampaignStatus = {
  DRAFT: "Draft",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  CREATED: "Created",
};

export const UserStatus = {
  ACTIVE: "Active",
  DEACTIVE: "Deactive",
};

export const ROLE = {
  ORG: 3,
  SUPER_ADMIN: 2,
  SUPER_ADMIN_STAFF: 4,
};

export const MailgunEventType = {
  DELIVERED: 'delivered',
  OPENED: 'opened',
  CLICKED: 'clicked',
  FAILED: 'failed',
  COMPLAINED: 'complained',
  UNSUBSCRIBED: 'unsubscribed',
};

export const CampaignType = {
  EMAIL: "Email",
  SMS: "SMS",
};

export const JourneyAction = {
  EMAIL: 1,
  SMS: 2,
};

export const EXECUTION_TYPE = {
  CURRENT: "current",
  CURRENT_NEW_BOTH: "currentAndNewAdded",
};

export const TRIGGER = {
  LIST: 1,
  SEGMENT: 2,
  SEPECIFIC_DATE: 3
};

export const ToListType = {
  LISTS: 1,
  SEGMENTS: 2,
};

export const optionCreater = (enums= []) => {
  return enums.map((v) => ({
    key: v?.name,
    text: v?.name ?? '',
    value: v?.id ?? '',
  }));
};

export const TimeUnit = {
  MILLISECONDS: 'milliseconds',
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
};

export const MODULES = {
  CAMPAIGN: 1,
  JOURNEYS: 2,
  CONTACTS: 3,
  LISTS: 4,
  SEGMENTS: 5,
  CONTENT_LIBRARY: 6,
  USERS: 7,
  DOMAINS: 8,
  CUSTOM_FIELDS: 9,
  PERMISSION: 12,
  ORGANIZATIONS: 10,
  AUDIT_LOG: 11,
  HELPDESK_ADMIN: 12,
  HELPDESK_ORG: 13,
  LOGIN_ACTIVITY: 14,
}
