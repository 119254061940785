import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
    getCampaignsRequest,
    getCampaignsSuccess,
    getCampaignsFailure,
    saveCampaign,
    saveCampaignFailure,
    saveCampaignSuccess,
} from '../../modules/compaigns/compaignSlice';
import { getRequest, postRequest } from '../../../axios/interceptors';
import { API_ENDPOINTS } from '../../../constants/api';
import { PayloadAction } from '@reduxjs/toolkit';

function* getCampaignSaga(action: ReturnType<typeof getCampaignsRequest>) {
    try {
        const { payload } = action;
        let endpoint = `${API_ENDPOINTS.GET_COMPAIGNS}?status=${payload.status}&type=${payload.type}&page=${payload.page}&limit=${payload.limit}`;
        const {  sortBy, search } = payload;
        if (sortBy) {
            endpoint += `&sortBy=${sortBy}`;
        }
        if (search) {
            endpoint += `&search=${search}`;
        }
        const response: AxiosResponse = yield call(() => getRequest(endpoint));
        yield put(getCampaignsSuccess(response.data));
    } catch (error: any) {
        yield put(getCampaignsFailure(error.response.data || error));
    }
}

function* saveCampaignSaga(action: PayloadAction<any>) {
    try {
        const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.CREATE_CAMPAIGN, action.payload));
        yield put(saveCampaignSuccess({...response.data, requestPayload: action.payload}));
    } catch (error: any) {
        yield put(saveCampaignFailure(error.response.data || error));
    }
}

 function* campaignSaga() {
    yield takeLatest(getCampaignsRequest.type, getCampaignSaga);
    yield takeLatest(saveCampaign.type, saveCampaignSaga);
}

export default campaignSaga;