import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSidebar from "../../../hooks/useSidebar";

const AdminDashboard = () => {
  const { onShowSidebar } = useSidebar();

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Dashboard
        </h2>
      </div>
      <div>
        <h2>Hello Admin</h2>
      </div>
    </div>
  );
};

export default AdminDashboard;
