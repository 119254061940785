import { call, put, takeLatest } from 'redux-saga/effects';
import { getRequest} from '../../../axios/interceptors';
import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '../../../constants/api';
import { getAllLocationFailure, getAllLocationSuccess, getAllLocations } from './userSlice';

function* fetchAllLocation() {
  try {
    const response: AxiosResponse = yield call(() => getRequest(API_ENDPOINTS.GET_ALL_LOCATIONS));
    yield put(getAllLocationSuccess(response.data));
  } catch (error:any) {
    yield put(getAllLocationFailure(error ?? {message: 'something went wrong'}));
  }  
}

function* userSaga() {
    yield takeLatest(getAllLocations.type, fetchAllLocation);
}

export default userSaga;
