import { Form, Table } from 'react-bootstrap';
import { CampaignType } from '../../../utils/enums';
import { formatDate } from '../../../utils/helper';
import useBestCampaign, { AnalyticDateFilterType } from './useBestCampaign';
import XLoader from '../../../component/loader/XLoader';

type BestCampaignProp = {
    filter: AnalyticDateFilterType
}
const BestCampaign = (props: BestCampaignProp) => {
    const { filter } = props
    const { bestCampaign, onTypeChange, isLoading } = useBestCampaign(filter)
    return (
        <div className="analytics-table">
            <div className="filter-bar d-flex align-items-center justify-content-between row-item analytics-bar sm-block">
                <div className='sub-title'>Your Best Campaigns</div>
                <div className='select-box'>
                    <Form.Group className="d-flex align-items-center" controlId="exampleForm.ControlInput1">
                        <Form.Label>Select Campaign Type</Form.Label>
                        <Form.Select onChange={onTypeChange}>
                            {/* <option value="">All</option> */}
                            <option value={CampaignType.EMAIL}>{CampaignType.EMAIL}</option>
                            {/* <option value={CampaignType.SMS}>{CampaignType.SMS}</option> */}
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>
            <div className="table-listing contact-list all-contacts">
                {bestCampaign?.length > 0 && <Table responsive>
                    <thead>
                        <tr>
                            <th>Campaign Name</th>
                            <th>Open Rate</th>
                            <th>Click Rate</th>
                            <th>Hard Bounce</th>
                            <th>Soft Bounce</th>
                            <th>Recipients</th>
                            <th>Campaign Type</th>
                            <th>Unsubscribe Rate</th>
                            <th>Sent Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && bestCampaign && bestCampaign?.map((v, k) => {
                            const name = v.title
                            const openRate = v.open
                            const clickRate = v.click
                            const hardBounce = v.hard_bounce + '%'
                            const softBounce = v.soft_bounce + '%'
                            const countactCount = v.contact_count
                            const type = v.type
                            const unsubscribeRate = v.unsubscribe_rate + '%'
                            const sentDate = formatDate(v.scheduled_time)
                            return (<tr key={k}>
                                <td>{name}</td>
                                <td>{((openRate / countactCount) * 100).toFixed(2) + "%"}</td>
                                <td>{((clickRate / countactCount) * 100).toFixed(2) + "%"}</td>
                                <td>{hardBounce}</td>
                                <td>{softBounce}</td>
                                <td>{countactCount}</td>
                                <td>{type}</td>
                                <td>{unsubscribeRate}</td>
                                <td>{sentDate}</td>
                            </tr>)
                        })}
                    </tbody>
                </Table>}
                {!isLoading && bestCampaign?.length === 0 && (
                    <div className="d-flex justify-content-center">NOT FOUND</div>
                )}
            </div>
            <div className=' position-relative'>
                {isLoading && <XLoader />}
            </div>
        </div>
    )
}

export default BestCampaign