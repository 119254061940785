import { API_ENDPOINTS } from "../constants/api";
import { openInNewTab } from "../utils/helper";
import useAxios from "./useAxios";

const useHelpdesk = () => {
  const { postData } = useAxios();

  const openHelpdesk = async () => {
    try {
      const response = await postData(API_ENDPOINTS.OPEN_HELPDESK);
      const {
        success,
        data: { accessToken, applicationName },
      } = response;
      if (success) {
        const url = `${process.env.REACT_APP_HELPDESK_URL}/externalconnect?accessToken=${accessToken}&applicationName=${applicationName}`;
        openInNewTab(url);
      }
    } catch (error) {}
  };

  return { openHelpdesk };
};

export default useHelpdesk;
