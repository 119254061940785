/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { mapMaker } from '../utils/options';

interface Props {
  refresh?: boolean;
}

export type SegmentType = {
  id: number;
  name: string;
  description: string;
};

export type SegmentMapType = {
  [key: string]: SegmentType;
};

const useSegment = ({ refresh = true }: Props = {}) => {
  const [segmentMap, setSegmentMap] = useState<SegmentMapType>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchData } = useAxios();

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await fetchData(API_ENDPOINTS.GET_SEGMENT_LIST);
        if (response?.success) {
          setSegmentMap(mapMaker(response.segments, { key: 'id', value: 'id', text: 'name' }));
        }
      } catch (error) {} 
        finally {
        setLoading(false);
      }
    };

    if (refresh) {
      fetchSegments();
    }
  }, [refresh]);

  return {
    segmentMap,
    loading,
  };
};

export default useSegment;
