import { faArrowsSplitUpAndLeft, faBars, faCirclePlus, faClock, faEllipsisVertical, faEnvelope, faMessage, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import XButton from '../../component/button/XButton';
import useSidebar from '../../hooks/useSidebar';
import { decodeMilliseconds } from '../../utils/helper';
import JourneyStepModal from './JourneyStepModal';
import TriggerModal from './TriggerModal';
import './journeyBuilder.css';
import { JourneyStep } from './useJourneyBuilder';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useContext } from 'react';
import { JourneyContext } from './JourneyContext';
import { CampaignType } from '../../utils/enums';

const JourneyBuilder: React.FC = () => {
  const { parentId, setParentIdHandler, addStep, journeySteps, isRule, updateStep, actionsMap, rulesMap, handelBack, handelSubmit, isLoading, isTriggerModalShow, triggerModalToggleHandler, triggerMap, listMap, segmentMap, addTriggerDataHandler, triggerData, templates, isJourneyCompleted, deleteNode, deleteAction, journeyPayload } = useContext(JourneyContext)
  const { onShowSidebar } = useSidebar();

  const renderTree = (nodes: JourneyStep[]) => {
    const { value, unit } = decodeMilliseconds(nodes[0]?.data?.delaySeconds)
    return (
      <div>
        {(nodes.length > 0 && nodes[0].rule_id) && (
          <div className="add-point position-relative">
            <span className='add-hover'>
              <button type="button">
                <div className='d-flex root-content align-items-center'>
                  {rulesMap[nodes[0]?.rule_id]?.event === "time delay" ? <FontAwesomeIcon icon={faClock} /> : <FontAwesomeIcon icon={faArrowsSplitUpAndLeft} />}
                  <div className='text-start'>
                    <span>{rulesMap[nodes[0]?.rule_id]?.event}</span>
                    {rulesMap[nodes[0]?.rule_id]?.event === "time delay" && <p style={{ marginBottom: '0px' }}>{`${value} ${unit}`} </p>}
                  </div>
                </div>
                <div>
                  <div className='jb-menu'>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <FontAwesomeIcon icon={faEllipsisVertical} id="dropdown-basic" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                          setParentIdHandler({ parent: nodes[0].parent_id, isRule: true, current_id: nodes[0].id })
                        }}
                        >Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          deleteNode({ parentId: nodes[0].parent_id })
                        }}
                        >Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </button>
            </span>
          </div>
        )}

        <ul>
          {Array.isArray(nodes) && nodes?.map((node) => (
            <li key={node.id}>
              <div className="label-icons">
                <div className="add-point position-relative">
                  <span className='add-hover'>
                    <button type="button" className={`${!node.action_id && 'opacityZero'}`}>
                      <div className='d-flex root-content align-items-center'>
                        <FontAwesomeIcon icon={node.action_id && actionsMap[node.action_id]?.action === CampaignType.SMS ? faMessage : faEnvelope} />
                        <div className='text-start'>
                          <span>{node.action_id && actionsMap[node.action_id]?.action}</span>
                          {(node.action_id && node?.data?.subject) && <p>Subject: {node?.data?.subject}</p>}
                        </div>
                      </div>
                      <div>
                        <div className='jb-menu'>
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <FontAwesomeIcon icon={faEllipsisVertical} id="dropdown-basic" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => {
                                setParentIdHandler({ parent: node.parent_id, isRule: false, current_id: node.id })
                              }}>Edit</Dropdown.Item>
                              <Dropdown.Item onClick={() => {
                                deleteAction({ currentId: node.id })
                              }}
                              >Delete</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </button>
                  </span>
                </div>

                {(node.children.length < 1) && <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => <Tooltip id="button-tooltip" {...props}>
                    Add Node
                  </Tooltip>}
                >
                  <button className={`border-0 p-0 ${!node.action_id && 'opacityZero'}`} type="button" onClick={() => {
                    if (!node.action_id) return false
                    setParentIdHandler({ parent: node.id, isRule: true, current_id: node.id })
                  }}>
                    <FontAwesomeIcon className='triggerActionIcon' icon={faCirclePlus} />
                  </button>
                </OverlayTrigger>}

                <div className='add-point'>
                  <span className={`add-icon ${node.rule_type === 2 ? 'icon-right' : ''}`}>
                    <div className='add-icon center'>
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => <Tooltip id="button-tooltip" {...props}>
                          Add Node
                        </Tooltip>}
                      ><FontAwesomeIcon onClick={() => setParentIdHandler({ parent: node.parent_id, current_id: node.id, isRule: false })} icon={faCirclePlus} />
                      </OverlayTrigger>
                    </div>

                    {/* <span onClick={() => setParentIdHandler({ parent: node.parent_id, current_id: node.id, isRule: false })} className={`dashed-btn ${node.rule_type === 2 ? 'dashed-btn right' : ''}`}>Add Action</span> */}
                  </span>
                </div>
                {(node.rule_id === 1 || node.rule_id === 2) && <span className={`btn-sm ${node.rule_type === 2 ? 'red' : ''}`}>
                  {node.rule_type === 1 ? 'Yes' : 'No'}
                </span>}

              </div>
              {node.children.length > 0 && (
                <ul>
                  {renderTree(node.children)}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="main-title d-flex justify-content-between">
        <div >
          <h2>
            <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span> {!isJourneyCompleted ? "Set up the new journey" : "Detail journey"}
          </h2>
          <p className='mb-0 cursor-pointer'><span onClick={handelBack}><FontAwesomeIcon icon={faPlay} /> Back to all journeys</span></p>
        </div>

        <XButton
          variant="primary"
          buttonText={isJourneyCompleted ? "Close" : "Add Journey"}
          className="btn-theme"
          onClick={handelSubmit}
          loading={isLoading}
        />
      </div>
      <div className="start-journey text-center">
        <ul className="tree">
          <li className='rootLi'>
            <div className="add-point position-relative">
              <span className='add-hover'>
                <button type="button" className='trigger-highlight'>
                  <div className='d-flex root-content align-items-center'>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <div className='text-start'>
                      <span>Trigger Added</span>
                      {!!triggerData?.triggerId && <p style={{ marginBottom: '0px' }}>Trigger: {triggerMap?.[triggerData?.triggerId]?.name}</p>}
                      {!!triggerData?.subject && <p>Subject: {triggerData.subject}</p>}
                    </div>
                  </div>
                  <div>
                    <div className='jb-menu'>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <FontAwesomeIcon icon={faEllipsisVertical} id="dropdown-basic" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={triggerModalToggleHandler}>Edit trigger</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </button>
              </span>
            </div>

            {(!!journeyPayload?.delayValue && !!journeyPayload?.delayUnit) && (<div className="add-point position-relative">
              <span className='add-hover'>
                <button type="button">
                  <div className='d-flex root-content align-items-center'>
                    <FontAwesomeIcon icon={faClock} />
                    <div className='text-start'>
                      <span>Time Delay</span>
                      {(!!journeyPayload?.delayValue && !!journeyPayload?.delayUnit) && <p style={{ marginBottom: '0px' }}>{journeyPayload?.delayValue} {journeyPayload?.delayUnit}</p>}
                    </div>
                  </div>
                  <div>
                  </div>
                </button>
              </span>
            </div>)}

            {!!triggerData?.triggerId && (<div>
              {!(triggerData?.templateId) && <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => <Tooltip id="button-tooltip" {...props}>
                  Add Action
                </Tooltip>}
              >
                <button className='border-0 p-0' type="button" onClick={() =>
                  setParentIdHandler({ parent: 1, isRule: false, current_id: 1 })
                }>
                  <FontAwesomeIcon className='triggerActionIcon' icon={faCirclePlus} />
                </button>
              </OverlayTrigger>}

              {triggerData?.templateId && (
                <>
                  <div className="add-point position-relative">
                    <span className='add-hover'>
                      <button type="button" className='trigger-highlight'>
                        <div className='d-flex root-content align-items-center'>
                          <FontAwesomeIcon icon={actionsMap[triggerData?.action_id]?.action === CampaignType.SMS ? faMessage : faEnvelope } />
                          <div className='text-start'>
                            <span>Trigger {actionsMap[triggerData?.action_id]?.action} Action Added</span>
                            {!!triggerData?.subject && <p>Subject: {triggerData.subject}</p>}
                          </div>
                        </div>
                        <div>
                          <div className='jb-menu'>
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <FontAwesomeIcon icon={faEllipsisVertical} id="dropdown-basic" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() =>
                                  setParentIdHandler({ parent: 1, isRule: false, current_id: 1 })
                                }>Edit trigger action</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </button>
                    </span>
                  </div>

                  {journeySteps.children.length < 1 && <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => <Tooltip id="button-tooltip" {...props}>
                      Add Node
                    </Tooltip>}
                  >
                    <button className='border-0 p-0' type="button" onClick={() => setParentIdHandler({ parent: 1, isRule: true, current_id: 1 })}>
                      <FontAwesomeIcon className='triggerActionIcon' icon={faCirclePlus} />
                    </button>
                  </OverlayTrigger>}
                  {renderTree(journeySteps.children)}
                </>
              )}

            </div>)}
          </li>
        </ul>

      </div>
      <JourneyStepModal {...{ parentId, setParentIdHandler, addStep, isRule, updateStep, templates }} />
      {isTriggerModalShow && <TriggerModal addTriggerDataHandler={addTriggerDataHandler} listMap={listMap} segmentMap={segmentMap} triggerMap={triggerMap} show={isTriggerModalShow} handleClose={triggerModalToggleHandler} />}
    </div>
  )
};

export default JourneyBuilder;
