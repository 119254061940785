export const API_ENDPOINTS = {
    FETCH_DATA: '/users',
    REGISTER:'/auth/register',
    EMAIL_VERIFY:'/auth/email-verify',
    ACCOUNT_SETUP:'/auth/account-setup',
    LOGIN:'/auth/login',
    RESETPASSWORD:'/auth/reset-Password',
    UPDATEPASSWORD:'/update-Password',
    LOCATION_DATA:'/auth/locations',
    ANSWER:'/auth/answer-verify',
    GET_COMPAIGNS:'/campaigns',
    GET_ALL_LOCATIONS: 'user/locations',
    ADD_EDIT_USER: 'user/addUser',
    GET_CONTACT_LIST:'/get-contacts',
    ADD_CONTACT:'/add-contact',
    DELETE_CONTACT:'/delete-contact',
    EDIT_CONTACT:'/edit-contact',
    CONTACT_DETAILS:'/contact-detail',
    GET_TAGLIST:'/get-tags',
    CREATE_CAMPAIGN:'/campaigns/create',
    GET_ALL_DOMAINS: 'domains',
    GET_ALL_TEMPLATES: 'getAllTemplates',
    CREATE_TEMPLATE: 'template',
    DELETE_TEMPLATE: 'deleteTemplate',
    EDIT_TEMPLATE: 'editTemplate',
    GET_ALL_FOLDERS: "folders",
    CREATE_FOLDER: "folder",
    CREATE_OR_FIND_TAG:'/create-or-find-tag',
    GET_SEGMENT_LIST:'/get-segment-list',
    GET_LISTS: '/getList',
    DELETE_LISTS: '/deleteList',
    CREATE_LISTS:'/createList',
    GET_COUNTRIES:'/countries',
    GET_STATES:'/states',
    GET_CITIES:'/cities',
    GET_ALL_CONTACTS:'./getAllContacts',
    VERIFY_DOMAIN: 'domain/sendVerificationLink',
    DOMAIN_DETAIL:'domain/detail',
    GET_DOMAIN: 'domain/listVerifiedDomain',
    CREATE_SEGMENT:'/create-segment',
    DELETE_SEGMENT:'/delete-segment',
    SEGMENT_DETAILS:'/segment-detail',
    UPDATE_SEGMENT:'/update-review-segment',
    USE_SEGMENT:'/use-segment',
    UPDATE_USE_SEGMENT:'/update-use-segment',
    GET_USER_INFO:'/user/getUserInfo',
    GET_ROLES:'/roles',
    GET_AUTH_QUESTIONS:'/authQuestions',
    UPDATE_USER_INFO:'/user/updateUser',
    ADD_CUSTOM_FIELD:'/add-custom-field',
    GET_PREDEFINED_FILEDS:'/get-predefined-fields',
    CREATE_LIST_WITH_CSV_IMPORT:'/createListWithCSVImport',
    GET_USER_LIST:'/get-users-list',
    GET_ROLES_LIST:'/roles',
    DELETE_USER:'/user-delete',
    GET_PERMISSIONS: '/permissions',
    STRIPO_GET_AUTH_TOKEN: '/stripo/getAuthToken',
    GET_DASHBOARD_ANALYTIC: '/dashboard/getDasboardAnalytics',
    UPDATE_PERMISSION:'/createUpdatePermissions',
    GET_BEST_CAMPAIGN:'/analytic/campaign/best',
    AUDITLOG:'auditLog',
    CREATE_JOURNEY: '/create-journey',
    GET_MONTHLY_PERFORMACE:'/analytic/campaign/monthPerformance',
    GET_JORNEY_RULE_ACTION:'/get-journey-rule-action',
    GET_TRIGGERS:'/get-all-triggers',
    GET_CAMPAIGN_ANALYTICS:'/analytic/campaign',
    GET_JOURNEY_LIST: '/get-journey',
    CREATE_JOURNEY_RULE_ACTION: '/create-Journey-RulesAction',
    GET_ALL_TEMPLATE_WITH_FOLDER: '/getAllTemplatesWithFolder',
    GET_JOURNEY_DETAILS: '/get-journey-detail',
    GET_JOURNEY_ANALYTICS:'/analytic/journey',
    GET_BEST_JOURNEY:'/analytic/journey/best',
    GET_MONTHLY_JOURNEY_PERFORMACE:'/analytic/journey/monthPerformance',
    GET_ALL_ORGANIZATION:'./getOrganizations',
    ADD_UPDATE_ORGANIZATION:'/admin/addOrganization',
    CONTACT_ANALYTIC:'/contact-analytic',
    UPDATE_IS_ACTIVE_ORGANIZATION: '/update-is-active-organization',
    UPDATE_IS_ACTIVE_JOURNEY: '/update-is-active-journey',
    CREATE_CONTACTS_WITH_CSV:'/createContactWithCSV',
    UNSUBSCRIBE: '/unsubscribe',
    DELETE_CUSTOM_FIELD: '/delete-custom-field',
    CREATE_SMS_TEMPLATE: '/create-sms-template',
    GET_ALL_SMS_TEMPLATES: '/getAllSmsTemplates',
    DELETE_SMS_TEMPLATE: '/deleteSmsTemplate',
    EDIT_SMS_TEMPLATE: '/editSmsTemplate',
    OPEN_HELPDESK: '/helpdesk/open',
    GET_AVAILABLE_NUMBERS_TO_BUY:'/get-available-numbers-to-buy',
    GET_NUMBERS: '/get-numbers',
    BUY_NUMBER: '/buy-number',
    GET_UNUSED_ACTIVE_NUMBERS: '/unused-active-numbers',
    ASSIGN_NUMBER_TO_ORG: '/assign-number-to-org',
    TOGGLE_UNSUBSCRIBE: '/contact/toggle-unsubscribe',
    TOGGLE_FEATURE: '/org/toggle-feature',
    LOGOUT: '/auth/logout',
    CONTACT_CHAT: '/send-sms',
    GET_CONTACTS_CONVERSATION: '/get-contacts-conversation',
    LOGIN_ACTIVITIES: '/get-login-activities',
    GET_ALL_PRE_BUILT_TEMPLATES: '/get-pre-built-templates',
    IMPORT_PRE_BUILT_TEMPLATE: '/import-pre-built-template',
    JOURNEY_ANALYTICS_DETAILS_BY_ID: '/journey-analytics-details',
    CAMPAIGN_ANALYTICS_DETAILS_BY_ID: '/campaign-analytics-details',
  };