/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import XInput from '../../../component/form/XInput';
import XSelect from '../../../component/form/XSelect';
import useLocationData from '../../../hooks/useLocationData';
import { Location } from '../../../utils/interface';

interface LocationCardProps {
    index: number;
    formik: any;
    locationData: Location
    removeLocationHandler: (index: number) => void;
}

const LocationCard: React.FC<LocationCardProps> = ({
    index,
    formik,
    removeLocationHandler,
    locationData
}) => {
    const { countries, states, fetchStates } = useLocationData()

    const locationFieldPrefix = `locations[${index}]`;

    useEffect(() => {
        if (locationData?.country_id) {
          fetchStates(locationData?.country_id)
        }
      }, [locationData?.country_id])

    // useEffect(() => {
    //     if (locationData?.state_id) {
    //       fetchCities(locationData?.state_id)
    //     }
    //   }, [locationData?.state_id])

    return (
        <div className="add-location-box">
            <Row>
                <Col xl={4} md={6} xs={12}>
                    <XSelect
                        name={`${locationFieldPrefix}.country_id`}
                        label="Country"
                        options={Object.values(countries)}
                        formik={formik}
                        onChange={(e)=>{
                            formik.handleChange(e)
                            formik.setFieldValue(`${locationFieldPrefix}.state_id`, '');
                        }}
                    />
                </Col>
                <Col xl={4} md={6} xs={12}>
                    <XSelect
                        name={`${locationFieldPrefix}.state_id`}
                        label="State"
                        options={Object.values(states)}
                        formik={formik}
                    />
                </Col>
                <Col xl={4} md={6} xs={12}>
                    <XInput
                        name={`${locationFieldPrefix}.city`}
                        label="City"
                        placeholder='City'
                        formik={formik}
                    />
                </Col>
                <Col xl={4} md={6} xs={12}>
                    <XInput name={`${locationFieldPrefix}.address`} label="Address" placeholder="Enter your address" formik={formik} />
                </Col>
                <Col xl={4} md={6} xs={12}>
                    <XInput name={`${locationFieldPrefix}.zipcode`} label="ZIP Code" placeholder="eg: 12345" formik={formik} />
                </Col>
            </Row>
            <div className="d-flex justify-content-end">
                {formik.values.locations.length > 1 && (
                    <Button
                        variant="primary"
                        className="btn-theme btn-red d-flex align-items-center"
                        onClick={() => removeLocationHandler(index)}
                    >
                        Delete <FontAwesomeIcon icon={faCircleXmark} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default LocationCard;
