/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Image from 'react-bootstrap/Image';
import { IMAGES } from '../../../../constants/imagePath';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { emailVerifyRequest } from '../../../../redux/modules/auth/authSlice';
import { EmailVerifyState, StepOneFormValues, StepProps } from '../../../../redux/modules/auth/types';
import { setStepOneData } from '../../../../redux/modules/auth/accountSetupSlice';
import { RootState } from '../../../../redux/rootReducer';
import { getIPAddress } from '../../../../constants/ipaddressService';


const StepOne: React.FC<StepProps> = ({ onNext }) => {
    let activationToken: any;
    const dispatch = useDispatch();
    const selectStepOneData = useSelector((state: RootState) => state.accountSetup.stepOne);
    const queryParams = new URLSearchParams(window.location.search);
    activationToken = queryParams.get('activationToken');
    const StepOneValidationSchema = Yup.object({
        firstName: Yup.string().trim()
            .matches(/^[A-Za-z\s]+$/, 'First Name must only contain letters')
            .required('First Name is required'),
        lastName: Yup.string().trim()
            .matches(/^[A-Za-z\s]+$/, 'Last Name must only contain letters')
            .required('Last Name is required'),
        businessName: Yup.string().trim()
            .matches(/^[A-Za-z\s]+$/, 'Business Name must only contain letters')
            .required('Business Name is required'),
        phoneNumber: Yup.string()
            .matches(/^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/, 'Phone number must only contain digits')
            .required('Enter your phone number')
    });

    useEffect(() => {
        formik.setValues(selectStepOneData);
    }, [selectStepOneData]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        activationToken = queryParams.get('activationToken');
        if (activationToken) {
            const payload: EmailVerifyState = {
                token: activationToken
            }
            dispatch(emailVerifyRequest(payload));
        }
    }, [activationToken]);

    const formik = useFormik<StepOneFormValues>({    
        initialValues: {
            firstName: '',
            lastName: '',
            businessName: '',
            phoneNumber: '',
        },
        validationSchema: StepOneValidationSchema,
        onSubmit: async (values: StepOneFormValues) => {
            const ipAddress = await getIPAddress();
            const payload = {
                firstName: values.firstName,
                lastName: values.lastName,
                businessName: values.businessName,
                phoneNumber: values.phoneNumber,
                activationToken,
                ip_address:ipAddress
            }
            dispatch(setStepOneData(payload));
            onNext(
            )
        },
    });

    return (
        <div>
            {/* // -------------------- 1 step -------------------------- */}
            <div className='signup-process'>
                <Container>
                    <div className="content">
                        <Image src={IMAGES.blueLogo} className='top-logo' />
                        <div className="card">
                            <h2 className='card-title'>Account Setup</h2>
                            <Row className='justify-content-center'>
                                <Col xl={10}>
                                    {/* <div className="stepper">
                                        <ul className='d-flex justify-content-between align-items-center'>
                                            <li className='step active'>1</li>
                                            <li className='active'><span></span></li>
                                            <li className='step'>2</li>
                                            <li><span></span></li>
                                            <li className='step'>3</li>
                                        </ul>
                                    </div> */}
                                     <div className="stepper">
              <ul className='d-flex justify-content-between align-items-center'>
                <li className='text-center active'><span className='step d-inline-block'>1</span></li>
                <li className='active'><span className='border-line'></span></li>
                <li className='text-center'><span className='step d-inline-block'>2</span></li>
                <li><span className='border-line'></span></li>
                <li className='text-center'><span className='step d-inline-block'>3</span></li>
              </ul>
            </div>
                                    {formik.touched.firstName && formik.touched.lastName && formik.touched.businessName && formik.touched.phoneNumber && Object.keys(formik.errors).length > 0 && (
                                        <div className="error-box" style={{ display: formik.touched && Object.keys(formik.errors).length > 0 ? 'block' : 'none' }}>
                                            <span style={{fontWeight:'bold'}}> {`There are ${Object.values(formik.errors).length} errors in your form.`}<br /> </span>
                                            {Object.values(formik.errors).map((error, index) => (
                                                <span key={index}>{error}<br /></span>               
                                            ))}
                                        </div>
                                    )}
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className="form-title">
                                            <h2>Tell us a bit about you</h2>
                                        </div>
                                        <Row>
                                            <Col xl={6} lg={6} md={6} sm={12}>
                                                <Form.Group controlId="formBasicEmail" className='form-group'>
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter"
                                                        {...formik.getFieldProps('firstName')}/>
                                                    <Form.Text className="text-danger error-text">{formik.touched.firstName && formik.errors.firstName}</Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={12}>
                                                <Form.Group controlId="formBasicEmail" className='form-group'>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter"
                                                        {...formik.getFieldProps('lastName')}/>
                                                    <Form.Text className="text-danger error-text">{formik.touched.lastName && formik.errors.lastName}</Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={12}>
                                                <Form.Group controlId="formBasicEmail" className='form-group'>
                                                    <Form.Label>Business Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter"
                                                        {...formik.getFieldProps('businessName')}
                                                    />
                                                    <Form.Text className="text-danger error-text">{formik.touched.businessName && formik.errors.businessName}</Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} sm={12}>
                                                <Form.Group controlId="formBasicEmail" className='form-group'>
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter"
                                                        {...formik.getFieldProps('phoneNumber')}/>
                                                    <Form.Text className="text-danger error-text">{formik.touched.phoneNumber && formik.errors.phoneNumber}</Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <div className='text-end'>
                                            <Button variant="primary" type="submit" className='btn-theme' >
                                                Next<FontAwesomeIcon icon={faPlay} />
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

  )
}

export default StepOne;