import { faBars, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row } from 'react-bootstrap';
import AnalyticCard from '../../../component/common/AnalyticCard';
import AnalyticDateFilter from '../../../component/common/AnalyticDateFilter';
import { IMAGES } from "../../../constants/imagePath";
import TrackBarChart from '../campaignAnalytics.tsx/TrackBarChart';
import BestJourney from './BestJourney';
import useJourneyAnalytic from './useJourneyAnalytic';
import MonthlyPerformanceChart from '../campaignAnalytics.tsx/MonthlyPerformanceChart';
import useSidebar from '../../../hooks/useSidebar';

const JourneyAnalytics = () => {
  const { filter, handleFilterChange, stats, pdfRef, exportHandler } = useJourneyAnalytic()
  const { onShowSidebar } = useSidebar();

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between sm-block">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Journey Analytics</h2>
        <div className="filter-bar d-flex align-items-center row-item analytics-bar sm-block">
          <div className='select-box'>
            <AnalyticDateFilter value={filter} onChange={handleFilterChange} />
          </div>
          <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            onClick={exportHandler}
          >Export <FontAwesomeIcon icon={faFileExport} />
          </Button>
        </div>
      </div>
      <div className="analytic-cards">
        <div className="sub-title">Monitor Performance</div>
        <Row >
          <AnalyticCard
            title="Deliveries"
            value={`${stats?.deliveredRate ?? 0} %`}
            icon={IMAGES.DeliveriesIcon}
          />
          <AnalyticCard
            title="Open Rate"
            value={`${stats?.openedRate ?? 0} %`}
            icon={IMAGES.OpenRateIcon}
          />
          <AnalyticCard
            title="Click Rate"
            value={`${stats?.clickedRate ?? 0} %`}
            icon={IMAGES.ClickRateIcon}
          />
          <AnalyticCard
            title="Unsubscribe Rate"
            value={`${stats?.unsubscribedRate ?? 0} %`}
            icon={IMAGES.UnsubscribeIcon}
          />
        </Row>
      </div>
      <div ref={pdfRef}>
        <BestJourney filter={filter} />
      </div>
      <div className='mt-5'></div>
      <TrackBarChart stats={stats} />

      <div className='mt-5'></div>
      <MonthlyPerformanceChart type='journey' />
    </div>
  )
}

export default JourneyAnalytics
