/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toaster } from '../../../utils/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useLocationData from '../../../hooks/useLocationData';
import { UserStatus } from '../../../utils/enums';

interface UseUserHook {
    formik: any;
    countries: any
    states: any
    cities: any
    fetchCities: any
    fetchStates: any
    id: string | undefined
    isLoading: boolean
}

const useUser = (): UseUserHook => {
    const { countries, states, cities, fetchCities, fetchStates } = useLocationData()
    const [userData, setUserData] = useState<any>({});
    const { postData, fetchData, isLoading } = useAxios()
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) fetchUser()
    }, [id])

    useEffect(() => {
        if (id) {
            formik.setValues({
                first_name: userData?.first_name ?? '',
                last_name: userData?.last_name ?? '',
                email: userData?.email ?? '',
                phone_number: userData?.phone_number ?? '',
                address: userData?.locations?.[0]?.address ?? '',
                zipcode: userData?.locations?.[0]?.zipcode ?? '',
                country_id: userData?.locations?.[0]?.country_id ?? '',
                state_id: userData?.locations?.[0]?.state_id ?? '',
                city: userData?.locations?.[0]?.city ?? '',
                status_id: userData?.is_active === true ? UserStatus.ACTIVE : UserStatus.DEACTIVE,
                role_id: userData?.role_id ?? '',
            });
        }
    }, [userData]);    

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            address: '',
            zipcode: '',
            country_id: '',
            state_id: '',
            city: '',
            status_id: '',
            role_id: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email is required'),
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            phone_number: Yup.string()
            .matches(/^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/, 'Phone number must only contain digits')
            .required('Enter your phone number'),
            address: Yup.string().required('Address is required'),
            zipcode: Yup.string().matches(/^\d{5}$/, 'Zipcode must be a number of 5 digits').required('Zipcode is required'),
            country_id: Yup.string().required('Country is required'),
            state_id: Yup.string().required('State is required'),
            city: Yup.string().required('City is required'),
            status_id: Yup.string().required('Status is required'),
            role_id: Yup.string().required('Role is required'),
        }),
        onSubmit: async (values, actions) => {
            handelSubmit(values)
        },
    });

    useEffect(() => {
        if (formik.values.country_id) {
          fetchStates(formik.values.country_id)
        }
      }, [formik.values.country_id])

    // useEffect(() => {
    //     if (formik.values.state_id) {
    //       fetchCities(formik.values.state_id)
    //     }
    //   }, [formik.values.state_id])

    const handelSubmit = async (values: any) => {
        try {
            const { first_name, last_name, email, phone_number, address, state_id, country_id, zipcode, city, role_id, status_id } = values
            let payload = {
                first_name, last_name, phone_number, is_active: status_id === UserStatus.ACTIVE ? true: false, role_id,
                ...(!id && {
                    email
                }),
                address, state_id, country_id, zipcode, city,
                id
            }
            const response = await postData(API_ENDPOINTS.ADD_EDIT_USER, payload)
            const { message, success } = response
            if (success) {
                navigate(-1)
            }
            toaster({ message, success })
        } catch (error) {
            toaster({ message: 'Something went wrong', success: false })
        }
    }

    const fetchUser = async () => {
        const response = await fetchData(`${API_ENDPOINTS.GET_USER_INFO}/${id}`)
        if (response.success) {
            setUserData(response?.data ?? {})
        }
    }

    return {
        formik,
        id,
        countries, states, cities, fetchCities, fetchStates, isLoading
    };
};

export default useUser;
