import { BrowserRouter} from 'react-router-dom';

import Routing from './routing/Routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App: React.FC = () => (
  <>
    <BrowserRouter>
   <Routing/>
  </BrowserRouter>
  <ToastContainer />
  </>

);


export default App;