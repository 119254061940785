/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {Container, Row, Col, Button, Form, Image} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../redux/rootReducer";
import Loader from "../../../component/loader";
import { API_ENDPOINTS } from "../../../constants/api";
import { postRequest } from "../../../axios/interceptors";
import { toast } from "react-toastify";
import { IMAGES } from "../../../constants/imagePath";
import { decodeJwt } from "../../../utils/helper";
import { toaster } from "../../../utils/toast";
import { ROLE } from "../../../utils/enums";

  interface FormValues {
    answer: string;
  }

const TwoStepAuthentication: React.FC = () => {
  let navigate = useNavigate();
  const { loginRes ,loading, loginPayload} = useSelector((state: RootState) => state.auth);
  const [securityQuestions] = useState<any>(loginRes?.result?.authentication_question);

  const validationSchema = Yup.object({ answer: Yup.string().required("Email is required"),  });

  const formik = useFormik<FormValues>({
    initialValues: {answer: ''},
    validationSchema: validationSchema,
    onSubmit: async (values: FormValues) => {
      submitAnswer(values);
    },
  });

  useEffect(()=>{
    if(loginRes?.result?.authentication_question === undefined || "") {
      navigate("/login");
    }
  },[])

  const submitAnswer = async(values: FormValues)=>{
    let payload=  {
      loginPayload:loginPayload,
      answer:values.answer
    }
    try {
      const res = await postRequest(API_ENDPOINTS.ANSWER,payload)
      if(res?.data.success === false) toast(res?.data?.message);
     else{
      toast(res?.data?.message)
      localStorage.setItem("authToken", res?.data?.data?.token);
      localStorage.setItem("refreshToken", res?.data?.data?.refreshToken);
      const decodedPayload = decodeJwt(res?.data?.data?.token);
      decodedPayload?.parentRoleId === ROLE.SUPER_ADMIN ? navigate('/Admin/dashboard') : navigate('/org')
     } 
      formik.resetForm();
    } catch (error:any) {
      const { message }  = error.response.data
      toaster({message, success: false})
    } 
  }
  return (
    <div>
      <div className="login card-bg">
        <div className="card">
          <Container>
            <Row>
              <Col
                xl={6}
                lg={12}
                md={12}
                sm={12}
                className="bg-blue d-flex align-items-center"
              >
                <div className="lt-text text-center">

                  <h1 className="title">Improve patient engagement with personalized campaigns</h1>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} sm={12} className="bg-white">
                <div className="rt-content">
                <Image src={IMAGES.blueLogo} />

                <div className="heading-title">
                  <h2>Two Step Authentication</h2>
                  </div>
                  <Form
                    className="input-form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    {securityQuestions &&
                      securityQuestions.map((question: any) => (
                        <Form.Group
                          controlId="formBasicEmail" className="form-group"
                           key={question.id}
                        >
                          <Form.Label>{question.questions}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter"
                            name='answer'
                            onChange={formik.handleChange}
                            isInvalid={
                              formik.touched.answer &&
                              !!formik.errors.answer
                            }
                            required
                            autoComplete="off"
                          />
                                  <Form.Control.Feedback >
                                       {formik.errors.answer}
                                  </Form.Control.Feedback>
                        </Form.Group>
                     ))}
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-theme w-100"
                      disabled={loading}
                    >
                    {loading && <Loader/>}
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default TwoStepAuthentication;
