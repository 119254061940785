import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlay } from '@fortawesome/free-solid-svg-icons';

const ViewUser = () => {

  return (
    <div>
      <div className="main-title ">
        <h2 className='back-text'>
          <span className="d-lg-none"><FontAwesomeIcon icon={faBars} /></span>User Details</h2>
        <p><span><FontAwesomeIcon icon={faPlay} /> Back to All Users</span></p>
      </div>
      <div className="contact-details">

        <div className="info-box">
          <Row>
            <Col xxl={6} xl={6} lg={12}>
              <ul>
                <li className='d-flex'>
                  <span className='text-lt'>First Name</span>
                  <span className='text-rt'>Jordan</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Last Name</span>
                  <span className='text-rt'>Mathew</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Email Address</span>
                  <span className='text-rt'>jordan@gmail.com</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Phone Number</span>
                  <span className='text-rt'>64245-75845</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Address</span>
                  <span className='text-rt'>795 Rodney Street</span>
                </li>
              </ul>
            </Col>
            <Col xxl={6} xl={6} lg={12}>
              <ul>
                <li className='d-flex'>
                  <span className='text-lt'>City</span>
                  <span className='text-rt'>Maryland Heights</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>State</span>
                  <span className='text-rt'>Missouri</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Zip Code</span>
                  <span className='text-rt'>63043</span>
                </li>
                <li className='d-flex'>
                  <span className='text-lt'>Country</span>
                  <span className='text-rt'>USA</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ViewUser
