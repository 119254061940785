import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from '../../../redux/modules/contacts/contactSlice';
import moment from 'moment';
import { API_ENDPOINTS } from '../../../constants/api';
import useAxios from '../../../hooks/useAxios';
import { toast } from 'react-toastify';
import useSidebar from '../../../hooks/useSidebar';


const ReviewSegment: React.FC = () => {
  const navigate = useNavigate();
  const { postData, putData } = useAxios();
  const { segmentId } = useParams();
  const dispatch = useDispatch();
  const { createSegment,editedSegment, loading } = useSelector((state: RootState) => state.contactlist);
  const { onShowSidebar } = useSidebar();

  const handleUseSegment = async() => {
    try {
      const payload = {
        name:segmentId?editedSegment?.segmentName: createSegment?.segmentName,
        filters:segmentId?editedSegment?.filters: createSegment?.filters,
      }
      if(segmentId){
        const response = await putData(`${API_ENDPOINTS.UPDATE_USE_SEGMENT}/${segmentId}`, payload);
        if(response.success === true){
          toast(response.message)
              navigate('/org/contacts/segments')
        }
      }else{
        const response = await postData(API_ENDPOINTS.USE_SEGMENT, payload);
        if(response.success === true){
          toast(response.message)
              navigate('/org/contacts/segments')
        }
      }
    } catch (error) {}
  }

  const handleBackToEditing = () => {
    if(segmentId){
      navigate(`/org/edit-segment/${segmentId}`);
    }
    else{
      navigate('/org/contacts/createSegment')
    }
    dispatch(clearData());
  }
  const currentDate = moment().format('MM/DD/YY hh:mm A');

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between ">
        <h2 className='back-text'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Review Segment</h2>
      </div>
      <div className="add-contact table-listing review-contact-segment">
        <div className="review-segment">
          <h2 className='segment-name'>{ segmentId ? editedSegment.segmentName :createSegment?.segmentName}</h2>
          <div className="contact-count">
            <p className='d-flex align-items-center'><span className='count'>{ segmentId ? editedSegment?.contactCount?.contactCount: createSegment?.contactCount?.contactCount}</span> <span className='text'>contacts in your segment</span>
              <span className='date-time'>as of {currentDate}</span>
            </p>
          </div>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>Email Address</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Address </th>
              <th>Phone Number </th>
            </tr>
          </thead>
          <tbody>
          {(!loading && segmentId? editedSegment:createSegment)?.contactCount?.contacts?.length === 0 && (
            <tr>
              <td colSpan={9}>
            <p className='text-center no-records'>No Contacts found.</p>
            </td>
            </tr>)}
            {(segmentId? editedSegment:createSegment).contactCount.contacts?.map((contact: any) => (
              <tr key={contact.id}>
                <td>{contact?.email}</td>
                <td>{contact?.firstname}</td>
                <td>{contact?.lastname}</td>
                <td>{contact?.address}</td>
                <td>{contact?.phone_number}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <Button variant="primary" className='btn-theme btn-border' onClick={handleBackToEditing}>
          <FontAwesomeIcon icon={faPlay} /> Back to Editing
        </Button>
        <Button variant="primary" type="submit" className='btn-theme' onClick={handleUseSegment}>
          Use Segment<FontAwesomeIcon icon={faPlay} />
        </Button>
      </div>
    </div>
  )
}

export default ReviewSegment
