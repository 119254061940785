/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import { AnalyticFilterType } from "../../../utils/interface";

export type BestJourneyResponseType = {
  name: string;
  type: string;
  created_at: string;
  open: number;
  click: number;
  unsubscribe: number;
  open_rate: string;
  contact_count: number;
  delivered_rate: number;
  click_rate: number;
  unsubscribe_rate: number;
  hard_bounce: string;
  soft_bounce: string;
};

const useBestJourney = (filter: AnalyticFilterType) => {
  const [bestJourney, setBestjourney] = useState<
    BestJourneyResponseType[] | []
  >([]);
  const [type, setType] = useState("");
  const { postData, isLoading } = useAxios();

  useEffect(() => {
    if (
      filter.dateRange &&
      (filter.dateRange !== "custom" || (filter.from && filter.to))
    ) {
      getBestJourney();
    }
  }, [type, filter]);

  const getBestJourney = async () => {
    const response = await postData(API_ENDPOINTS.GET_BEST_JOURNEY, {
      filter: { ...filter, type },
    });
    setBestjourney(response?.data);
  };

  const onTypeChange = (e: ChangeEvent<any>) => {
    const { value } = e.target;
    setType(value);
  };
  return { bestJourney, onTypeChange, isLoading };
};

export default useBestJourney;
