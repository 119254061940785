import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreatePasswordPayload, LoginFormPayload, ResetFormValues,LoginFormValues , EmailVerifyState, RegisterPayload} from "./types";
import { encryptData } from '../../../utils/encryption';

interface AuthState {
  data: any;
  loading: boolean;
  error: any;
  resetPasswordRes: any;
  updatePasswordRes: any;
  loginRes: any;
  loginError:any;
  loginPayload: any;
}

const initialState: AuthState = {
  data: null,
  loading: false,
  error: null,
  loginRes: null,
  resetPasswordRes: null,
  updatePasswordRes: null,
  loginError:null,
  loginPayload: {
    email: "",
    password: "",
    ipAddress: ''
  }
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest(state, action: PayloadAction<LoginFormPayload>) {
      state.loading = true;
      state.loginError = null;
      state.loginRes = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.loginRes = action.payload;
      const requestPayload = action.payload.requestPayload
      const rememberMe = requestPayload.rememberMe
      state.loginPayload = {email: requestPayload.email, password: requestPayload.password, ipAddress: requestPayload.ipAddress}
      if (rememberMe) {
        localStorage.setItem('u_key', encryptData(requestPayload.email))
        localStorage.setItem('u_secret', encryptData(requestPayload.password))
      }else{
        localStorage.setItem('u_key', '')
        localStorage.setItem('u_secret', '')
      }
    },
    loginFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.loginError = action.payload;
      },
      registerRequest(state, action: PayloadAction<RegisterPayload>) {
          state.loading = true;
          state.error = null;
          state.data=null;
      },
      registerSuccess(state, action: PayloadAction<any>) {
          state.loading = false;
          state.data = action.payload;
      },
      registerFailure(state, action: PayloadAction<string>) {
          state.loading = false;
          state.error = action.payload;
      },
      emailVerifyRequest(state, action: PayloadAction<EmailVerifyState>) {
        state.loading = true;
        state.error = null;
    },
    emailVerifySuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.data = action.payload;
    },
    emailVerifyFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
    },

    resetPasswordRequest(state, action: PayloadAction<ResetFormValues>) {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resetPasswordRes = action.payload;
    },
    resetPasswordFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updatePasswordRequest(state, action: PayloadAction<CreatePasswordPayload>) {
      state.loading = true;
      state.error = null;
    },
    updatePasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.updatePasswordRes = action.payload;
    },
    updatePasswordFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    ressetUpdatePassword: (state) => {
      state.loading = false;
      state.updatePasswordRes = null;
    },
    clearData: (state) => { 
      state.loginError = null;
      state.loginRes=null;
      state.data =null;
      state.error= null;
    },
    logout(state) {
      state.loading = false;
      state.data = null;
      state.error = null;
      state.loginRes = null;
      state.resetPasswordRes = null;
      state.updatePasswordRes = null;
      state.loginError = null;
    },
  },
});


export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  ressetUpdatePassword,
  emailVerifyRequest,
  emailVerifySuccess,
  emailVerifyFailure,
  clearData,
  logout 
} = userSlice.actions;
export default userSlice.reducer;
