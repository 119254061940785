import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Form, Container, NavDropdown, Image } from "react-bootstrap";
import { IMAGES } from "../../constants/imagePath";
import { logout } from "../../redux/modules/auth/authSlice";
import { setSelectedLocationId } from "../../redux/modules/auth/userSlice";
import { RootState } from "../../redux/rootReducer";
import { ROLE } from "../../utils/enums";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import XLoader from "../loader/XLoader";
import { goToDashboard } from "../../utils/helper";

const Header: FC = () => {
  const dispatch = useDispatch();
  const { orgLocations, first_name, selectedLocationId, role_id } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { postData, isLoading } = useAxios()
  const { logoUrl } = useSelector((state: RootState) => state.user);
  
  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLocationId = event.target.value;
    dispatch(setSelectedLocationId(selectedLocationId))
    localStorage.setItem('locationId', selectedLocationId);
    window.location.reload();
  };

  const handleLogout = async () => {
    const response = await postData(API_ENDPOINTS.LOGOUT);
    if(response.success) {
      dispatch(logout());
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expandedItems");
      navigate("/login");
    }
  };


  return (
    <Navbar expand="lg" fixed="top">
      {isLoading && <XLoader />}
      <Container fluid>
        <Navbar.Brand >
          <Image 
            src={IMAGES.blueLogo}
            className="cursor-pointer"
            onClick={() => goToDashboard(navigate, role_id)}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
            <Form className="d-flex align-items-center nav-options">
              {/* {role_id !== 2 && (
                <div className="position-relative search-bar">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
              )} */}
              <div className="location-profile d-flex">
                {role_id !== ROLE.SUPER_ADMIN && role_id !== ROLE.SUPER_ADMIN_STAFF && (
                  <Form.Group
                    controlId="formBasicEmail"
                    className="d-flex align-items-center select-location"
                  >
                    <Form.Label>Location</Form.Label>
                    <Form.Select aria-label="Default select example"
                      onChange={handleLocationChange}
                      value={selectedLocationId}
                    >
                      {/* <option value="">Select</option> */}
                      {orgLocations?.map((v) => {
                        const { id, address } = v
                        return <option key={`locationDrpdown-${id}`} value={id}>{address}</option>
                      })}
                    </Form.Select>
                  </Form.Group>
                )}
                <div className="profile d-flex">
                  <Image
                    height={45}
                    width={45}
                    src={logoUrl && role_id !== ROLE.SUPER_ADMIN ? logoUrl : IMAGES.profile}
                    style={{ borderRadius: "50%" }}
                  />
                  <NavDropdown
                    title={first_name}
                    id="navbarScrollingDropdown"
                    align="end"
                  >
                    {role_id !== ROLE.SUPER_ADMIN && ( // hide profile option for super admin
                      <>
                        <NavDropdown.Item onClick={() => {
                          localStorage.setItem('expandedItems', JSON.stringify([]));
                          navigate('/org/settings/profile')
                        }}>
                        Profile
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>
                    )}
                    <NavDropdown.Item onClick={() => handleLogout()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;

