/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useRuleActions from "../../hooks/useRuleActions";
import { StepPayloadType } from "./useJourneyStepModal";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../constants/api";
import { toaster } from "../../utils/toast";
import useAxios from "../../hooks/useAxios";
import useTrigger from "../../hooks/useTrigger";
import useList from "../../hooks/useList";
import useSegment from "../../hooks/useSegment";
import { Option } from "../../utils/interface";
import useFolderTemplates from "../../hooks/useFolderTemplates";

export interface JourneyStep extends ConditionStateType {
    id: number;
    name: string;
    parent_id: number;
    action_id: number | undefined | null;
    rule_id: number | undefined;
    data: any;
    children: JourneyStep[];
}

export interface Steps {
    id: number;
    name: string;
    children: JourneyStep[];
}

export interface ConditionStateType {
    rule_id: number | undefined;
    rule_type?: number;
    rule_type_id?: number;
}

export interface TriggerDataType {
    triggerId: number;
    toListType?: number
    toListId?: Option[];
    dateField?: string
    dayBefore?: number
    executionType?: string
    fromId?: number;
    subject?: string;
    templateId?: number,
    action_id?: number | null | undefined,
    delaySeconds?: number
}

export interface setParentIdHandlerType {
    parent: number;
    isRule: boolean;
    current_id: number
}

const useJourneyBuilder = () => {
    const [parentId, setParentId] = useState<number>(0);
    const [currentId, setCurrentId] = useState<number>(0);
    const [parentNode, setParentNode] = useState<JourneyStep | null | Steps | TriggerDataType>(null);
    const [selectedNode, setSelectedNode] = useState<JourneyStep | null | Steps | TriggerDataType>(null);
    const [triggerData, setTriggerData] = useState<TriggerDataType>({
        triggerId: 0,
    });
    const [isRule, setIsRule] = useState<boolean>(true);
    const [triggerActionModal, setTriggerActionModal] = useState<boolean>(false);
    const [journeySteps, setJourneySteps] = useState<Steps>({
        id: 1,
        name: "Root",
        children: []
    });
    const { rulesMap, actionsMap } = useRuleActions()
    const { triggerMap } = useTrigger()
    const { listMap } = useList()
    const { segmentMap } = useSegment()
    const { templates, smsTempalate } = useFolderTemplates()
    const navigate = useNavigate()
    const { postData, isLoading, error } = useAxios();
    const location = useLocation();
    const { journeyStatus, journeyEditData, journeyPayload } = location.state || {};
    const isJourneyCompleted = journeyStatus === "completed"
    const [isTriggerModalShow, setIsTriggerModalShow] = useState<boolean>(!isJourneyCompleted);

    useEffect(() => {
        toaster({ message: error, success: false });
    }, [error])

    useEffect(() => {
        let node = null
        if (parentId) {
            node = findNode({ id: parentId })
        }
        if (parentId === 1) {
            node = {...node, ...triggerData}
        }
        setParentNode(node)
    }, [parentId])

    useEffect(() => {
        let node = null
        if (currentId) {
            node = findNode({ id: currentId })
        }
        if (currentId === 1) {
            node = {...node, ...triggerData}
        }
        setSelectedNode(node)
    }, [currentId])

    useEffect(() => {
        if (isJourneyCompleted) {
            getJourneyDetail()
            addTriggerDataHandler({
                triggerId: journeyEditData?.trigger_id,
                fromId: journeyEditData?.domain.id,
                subject: journeyEditData?.subject,
                templateId: journeyEditData?.template.id,
                toListId: journeyEditData?.listids,
                toListType: journeyEditData?.to_list_type,
                action_id: 0 // nedd to change as per edit data
            })
        }
    }, [])

    const getJourneyDetail = async () => {
        try {
            const response = await postData(API_ENDPOINTS.GET_JOURNEY_DETAILS, { journeyId: journeyPayload?.journeyId });
            const data = response.data[0]
            setJourneySteps({
                id: 1,
                name: "Root",
                children: data
            })
        } catch (error) {}
    }

    const addStep = (payload: StepPayloadType[]) => {
        const newNodes = payload.map((v: StepPayloadType, k: number) => {
            return {
                id: k + Date.now(),
                name: `Node ${k + Date.now()}`,
                parent_id: parentId,
                rule_id: v.rule_id,
                rule_type: v.rule_type,
                action_id: v.action_id,
                data: { templateId: 0, ...(v?.delaySeconds && { delaySeconds: v.delaySeconds }) },
                children: []
            };
        })
        const updatedTree = addNode(journeySteps, newNodes);
        setJourneySteps(updatedTree);
        setParentId(0)
        setCurrentId(0)
    };

    const addNode = (root: Steps, newNode: JourneyStep[]): Steps => {
        const updatedTree: Steps = {
            ...root,
            children: addToChildren(root.children, newNode)
        };
        return updatedTree;
    };

    const addToChildren = (nodes: JourneyStep[], newNode: JourneyStep[]): JourneyStep[] => {
        if (parentId === 1) {
            return [...newNode]
        }
        const a = nodes.map((node) => {
            if (node.id === parentId) {
                return { ...node, children: [...newNode] }
            } else {
                return { ...node, children: addToChildren(node.children, newNode) }
            }
        }
        );
        return a
    };

    const updateStep = (updatedStep: JourneyStep, currId = currentId) => {
        const { data, action_id } = updatedStep
        if (currId === 1) {
                const { fromId, subject, templateId } = data
                addTriggerDataHandler({ ...triggerData, fromId, subject, templateId, action_id })
        } else {
            const updatedTree = updateNode(journeySteps, currId, updatedStep);
            setJourneySteps(updatedTree);
        }

        setParentId(0)
        setCurrentId(0)
    };

    const updateNode = (root: Steps, stepId: number, updatedStep: JourneyStep): Steps => {
        const updatedTree: Steps = {
            ...root,
            children: updateInChildren(root.children, stepId, updatedStep)
        };
        return updatedTree;
    };

    const updateInChildren = (nodes: JourneyStep[], stepId: number, updatedStep: JourneyStep): JourneyStep[] => {
        return nodes.map(node => {
            if (node.id === stepId) {
                return {
                    ...node, ...updatedStep, data: { ...node.data, ...updatedStep.data },
                    //if action changed from email to sms remove children because there have probality of open condition
                    ...((node?.action_id !== updatedStep.action_id && node?.action_id === 1) && {
                        children: []
                    })
                };
            } else {
                return {
                    ...node,
                    children: updateInChildren(node.children, stepId, updatedStep)
                };
            }
        });
    };

    const deleteChildren = ({ children, parentId }: { children: JourneyStep[], parentId: number }): JourneyStep[] => {
        return children.map(node => {
            if (node.id === parentId) {
                return { ...node, children: [] };
            } else {
                return {
                    ...node,
                    children: deleteChildren({ children: node.children, parentId })
                };
            }
        });
    };

    const deleteNode = ({ parentId }: { parentId: number }) => {
        if (isJourneyCompleted) return
        const updatedTree: Steps = {
            ...journeySteps,
            children: parentId === 1 ? [] : deleteChildren({ children: journeySteps.children, parentId })
        };
        setJourneySteps(updatedTree);
        setParentId(0)
        setCurrentId(0)
    };

    const deleteAction = ({ currentId }: { currentId: number }) => {
        if (isJourneyCompleted) return
        updateStep({ data: { from: '', subject: '', templateId: 0, fromId: 0 }, action_id: null, children: [] as JourneyStep[] } as JourneyStep, currentId)
    };

    const findChildren = ({ children, id }: { children: JourneyStep[], id: number }): JourneyStep | null => {
        for (const node of children) {
            if (node.id === id) {
                return { ...node };
            } else {
                const foundNode = findChildren({ children: node.children, id });
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return null;
    };

    const findNode = ({ id }: { id: number }) => {
        if (id === 1) {
            return journeySteps
        }

        return findChildren({ children: journeySteps.children, id })
    };


    const setParentIdHandler = ({ parent, isRule, current_id }: setParentIdHandlerType) => {
        if (isJourneyCompleted) return
        setParentId(parent);
        setIsRule(isRule)
        setCurrentId(current_id)
    };

    const handelBack = () => {
        navigate(-1)
    }

    const triggerModalToggleHandler = () => {
        if (isJourneyCompleted) return
        setIsTriggerModalShow(!isTriggerModalShow)
    }

    const triggerActionModalToggleHandler = () => {
        if (isJourneyCompleted) return
        setTriggerActionModal(!triggerActionModal)
    }

    const validateJourneyNode = (children: JourneyStep[]): string => {
        for (let obj of children) {
          if (obj.action_id !== undefined) {
            if (obj.children && obj.children.length > 0) {
              let res = validateJourneyNode(obj.children);
              return res;
            }
            return ''
          }
        }
        return "Please add trigger action";
    };
    
    const validate = () => {
      const { triggerId, templateId } = triggerData;
      if (!triggerId || !templateId ) {
        return "Please add trigger action";
      }
      if (journeySteps.children.length > 0) {
        return validateJourneyNode(journeySteps.children);
      }
      return "";
    };

    const handelSubmit = async () => {
        if (isJourneyCompleted) {
            navigate("/org/journey");
            return
        }
        let validationErrorMsg = validate();
        if (validationErrorMsg) {
          toaster({ message: validationErrorMsg, success: false });
          return;
        }
        const toListIdOnly = triggerData.toListId?.reduce((acc: number[], curr) => {
            acc.push(+curr.value);
            return acc;
        }, []);
        const payload = {
            node: journeySteps,
            journeyId: journeyPayload?.journeyId,
            triggerData: {
                ...triggerData,
                toListId: toListIdOnly
            },
        }
        const response = await postData(API_ENDPOINTS.CREATE_JOURNEY_RULE_ACTION, payload);
        if (response?.success) {
            navigate("/org/journey");
        }
        toaster({ message: response?.message, success: response?.success });
    }

    const addTriggerDataHandler = (data: TriggerDataType) => {
        setTriggerData({ ...triggerData, ...data })

        if (triggerData?.action_id !== data.action_id && triggerData?.action_id === 1) {
            setJourneySteps({
                id: 1,
                name: "Root",
                children: []
            })
        }
    }

    return {
        journeySteps,
        addStep,
        parentId,
        setParentIdHandler,
        isRule,
        updateStep,
        rulesMap,
        actionsMap,
        handelBack,
        handelSubmit,
        isLoading,
        triggerModalToggleHandler,
        isTriggerModalShow,
        triggerMap,
        listMap,
        segmentMap,
        addTriggerDataHandler,
        triggerData,
        triggerActionModalToggleHandler,
        triggerActionModal,
        templates,
        isJourneyCompleted,
        deleteNode,
        selectedNode,
        deleteAction,
        journeyPayload,
        currentId,
        smsTempalate,
        parentNode
    };
};

export default useJourneyBuilder;
