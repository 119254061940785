/* eslint-disable react-hooks/exhaustive-deps */
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import { toaster } from '../../../utils/toast';
import useSidebar from '../../../hooks/useSidebar';
import XLoader from '../../../component/loader/XLoader';

interface Option {
  id: number;
  value: string;
  roleName: string;
}

interface Module {
  module_id: number;
  module_name: string;
  delete:boolean,
  edit:boolean,
  view:boolean,
}

const Permission = () => {
  const { fetchData,postData, isLoading } = useAxios();
  const [roles, setRoles] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>('');
  const [modulesPermissions, setModulePermissions] = useState<Module[]>([]);
  const { onShowSidebar } = useSidebar();

  useEffect(() => {
  roles.length === 0 && getRoleList();
  }, [roles]);

  const getRoleList = async () => {
    try {
      const response = await fetchData(API_ENDPOINTS.GET_ROLES);
      setRoles(response?.data ?? []);
      if(response?.data.length > 0) {
        setSelectedOption(response?.data[0].id);
        getModulesPermissionsList(response?.data[0].id)
      }
    } catch (error) {
      toaster({ success: false, message: "error fetching in roles" });
    }
  }
  const getModulesPermissionsList = async (id : string) => {
    try {
      let endpoint = `${API_ENDPOINTS.GET_PERMISSIONS}/${id}`;
      const response = await fetchData(endpoint);
      // Filter out module_id 7 and 9 (user and custom_field)
      const filteredData = response?.data.filter((x:any) => x.module_id !== 7 && x.module_id !== 9);
      setModulePermissions(filteredData ?? []);
    } catch (error) {
      toaster({ success: false, message: "error fetching in modules" });
    }
  }

  const handlePermissionChange = (moduleId: number , type: string , checked: boolean) => {
    setModulePermissions((prevModules) =>{
        return prevModules.map((module) =>
        module.module_id === moduleId ? { ...module, [type]:checked } : module
      )
    })
    saveModulePermissions(moduleId,type,checked);
  };

  const saveModulePermissions = async (moduleId: number , type: string ,checked: boolean) => {
    try {
      const payload ={
        role_id:selectedOption,
        module_id:moduleId,
        [type]:checked
      };
     await postData(API_ENDPOINTS.UPDATE_PERMISSION ,payload);
    } catch (error) {
      toaster({ success: false, message: "error fetching in modules" });
    }
  };
  function findRoleIdByName(roleName: string| null): number {
    const role = roles.find((r) => r.roleName === roleName);
    return role ? role.id : 1;
  }

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    const role_id=findRoleIdByName(event.target.value)
    getModulesPermissionsList((role_id).toString())
  };

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between sm-block">
        <h2 className='d-flex align-items-center'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>Permissions</h2>
        <div className="filter-bar d-flex align-items-center row-item analytics-bar sm-block">
          <div className='select-box'>
            <Form.Group controlId="exampleForm.SelectCustom" className='d-flex align-items-center'>
              <Form.Label>Select an Role</Form.Label>
              <Form.Select value={selectedOption as string} onChange={handleOptionChange}>
                {roles.length && roles.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.roleName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>

      
      <div className="add-contact table-listing review-contact-segment">
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Module</th>
              <th style={{ width: "70%" }}>Permissions</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && modulesPermissions?.map((module) => (
              <tr  key={module.module_id}>
                <td>{module.module_name.replace(/_/g, " ")}</td>
                <td>
                  <span className='d-flex'>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="View"
                      checked={module.view}
                      onChange={(event) => handlePermissionChange(module.module_id,'view' ,event.target.checked)}
                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Edit"
                      checked={module.edit}
                      onChange={(event) => handlePermissionChange(module.module_id ,'edit' ,event.target.checked)}
                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Delete"
                      checked={module.delete}
                      onChange={(event) => handlePermissionChange(module.module_id ,'delete' ,event.target.checked)}
                    />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className='position-relative'>
        {isLoading && <XLoader />}
      </div>
    </div>
  );
};

export default Permission;
