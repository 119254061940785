/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { optionMaker } from '../utils/options';

interface Role {
  id: number;
  roleName: string;
  value: string;
  text: string;
  key: string;
}

interface UseRolesResult {
  roles: Role[];
  error: string | null;
  loading: boolean;
  fetchRoles: () => Promise<void>;
}

const useRoles = (): UseRolesResult => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchData } = useAxios();

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const response = await fetchData(`${API_ENDPOINTS.GET_ROLES}`);
      setRoles(optionMaker(response.data, { key: 'roleName', value: 'id', text: 'roleName' }));
    } catch (error) {
      setError('Error fetching roles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return {
    roles,
    error,
    loading,
    fetchRoles,
  };
};

export default useRoles;
