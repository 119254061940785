import React from "react";
import { Table } from "react-bootstrap";
import XLoader from "../loader/XLoader";

interface Props {
  title: string;
  type: string;
  loader: boolean;
  data: any;
}

const SingleAnalyticsUI: React.FC<Props> = ({ title, type, loader, data }) => {
  return (
    <div className="table-listing single-analytics">
      {loader && <XLoader />}
      <Table responsive>
        <thead>
          <tr>
            <th>{title}</th>
            <th>Open Rate</th>
            <th>Click Rate</th>
            <th>Hard Bounce</th>
            <th>Soft Bounce</th>
            <th>Recipients</th>
            <th>{type}</th>
          </tr>
        </thead>
        <tbody>
          {!loader &&
            data?.analytics?.length > 0 &&
            data?.analytics?.map((v: any, i: any) => (
              <tr key={i}>
                <td>{v.title || v.name}</td>
                <td>{(type === "Campaign Type" ? (v.open / v.contact_count * 100).toFixed(2) : v.open_rate) + "%"}</td>
                <td>{(type === "Campaign Type" ? (v.click / v.contact_count * 100).toFixed(2) : v.click_rate) + "%"}</td>
                <td>{v.hard_bounce + "%"}</td>
                <td>{v.soft_bounce + "%"}</td>
                <td>{v.contact_count}</td>
                <td>{v.type}</td>
              </tr>
            ))}
          {!loader && data?.analytics?.length === 0 && (
            <tr>
              <td colSpan={9}>
                <p className="text-center no-records">No analytics found.</p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {data?.contacts?.length > 0 && (
        <div className="table-responsive analytics-listing">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {data?.contacts?.map((v: any) => (
                <tr key={v.id}>
                  <td>{v.firstname}</td>
                  <td>{v.lastname}</td>
                  <td>{v.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SingleAnalyticsUI;
