import { ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';

interface XSelectProps {
    label?: string;
    name: string;
    className?: string;
    options: { value: string; text: string; key: string }[];
    formik?: any;
    onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
    disabled?: boolean;
}

const XSelect: FC<XSelectProps> = ({ name, label = name, className, options, onChange,disabled, formik, ...props }) => {

    return (
        <Form.Group className={`form-group ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Select
               disabled={disabled || false}
                {...{
                    name,
                    id: name,
                    ...(formik && {
                        ...formik.getFieldProps(name),
                        isInvalid: formik?.touched?.[name] && !!formik?.errors?.[name],
                    }),
                    ...(onChange && {
                        onChange: (e)=>{
                            onChange?.(e);
                            if (formik) {
                                formik?.handleChange(e);
                            } 
                        }
                    })
                }}
            >
                <option value=''>Select</option>
                {options.map((option) => (
                    <option key={option.key} value={option.value}>
                        {option.text}
                    </option>
                ))}
            </Form.Select>
            {formik?.errors?.[name] && (
                <Form.Control.Feedback type="invalid">
                    {formik?.errors?.[name]}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default XSelect;
