/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import { usePDF } from "react-to-pdf";
import {
  AnalyticFilterType,
  AnalyticStatsType,
} from "../../../utils/interface";

const useJourneyAnalytic = () => {
  const [filter, setFilter] = useState<AnalyticFilterType>({
    dateRange: "lastMonth",
  });
  const [stats, setStats] = useState<AnalyticStatsType>();
  const { postData } = useAxios();
  const { toPDF, targetRef } = usePDF({ filename: "journey_analytics.pdf" });

  useEffect(() => {
    if (
      filter.dateRange &&
      (filter.dateRange !== "custom" || (filter.from && filter.to))
    ) {
      fetchJourneyAnalytics();
    }
  }, [filter]);

  const fetchJourneyAnalytics = async () => {
    const response = await postData(API_ENDPOINTS.GET_JOURNEY_ANALYTICS, {
      filter,
    });
    setStats(response?.data);
  };

  const handleFilterChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (name === "dateRange" && value !== "custom") {
      setFilter({ dateRange: value });
    } else {
      setFilter((preFilter) => ({ ...preFilter, [name]: value }));
    }
  };

  const exportHandler = () => {
    toPDF();
  };

  return {
    handleFilterChange,
    filter,
    stats,
    exportHandler,
    pdfRef: targetRef,
  };
};

export default useJourneyAnalytic;
