import { useDispatch, useSelector } from "react-redux";
import { setShowSidebarSlice } from "../redux/modules/sideNav/sideNavSlice"; // Import your action creator
import { RootState } from "../redux/rootReducer";

const useSidebar = () => {
  const { sideBarStatus} = useSelector((state: RootState) => state.sidenav);
  const dispatch = useDispatch();

  const onShowSidebar = () => {
    dispatch(setShowSidebarSlice({ changeSidebarStatus: false }));
  };

  const onCloseSidebar = () => {
    dispatch(setShowSidebarSlice({ changeSidebarStatus: true }));
  };

  return { onShowSidebar, onCloseSidebar, sideBarStatus };
};

export default useSidebar;
