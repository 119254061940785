/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { XModal } from '../../component';
import { StepPayloadType } from './useJourneyStepModal';
import { RuleType } from '../../hooks/useRuleActions';
import { JourneyContext } from './JourneyContext';

interface Props {
    show: boolean;
    handleClose: () => void;
    stepPayload: StepPayloadType
    updateStepPayloadHandler: (payload: StepPayloadType) => void
    conditions?: RuleType[] | [];
    addStep: (payload: StepPayloadType[]) => void
}

const JourneyConditionModal: FC<Props> = ({ show, handleClose, updateStepPayloadHandler, stepPayload, conditions, addStep }) => {
    const { selectedNode } = useContext(JourneyContext)

    const [conditionId, setConditionId] = useState<number | undefined>(selectedNode?.rule_id ?? 0);

    useEffect(() => {
        updateStepPayloadHandler({ delaySeconds: 0 })
    }, [])

    const handleAdd = () => {
        if (conditionId) {
            const delaySeconds = 60000
            addStep([{ rule_id: conditionId, rule_type: 1, delaySeconds }, { rule_id: conditionId, rule_type: 2, delaySeconds }])
            handleClose()
        }
    };

    return (
        <XModal
            cancelText="Cancel"
            confirmText="Add"
            size="lg"
            title="Select the campaign journey condition"
            show={show}
            body={
                <>
                    {conditions && (<div className="content d-flex align-items-center justify-content-between">
                        <Form.Group controlId="formBasicRadiobox" className="d-flex align-items-center journey-builder-radios">
                            <Form.Label style={{ flex: 'none' }}></Form.Label>
                            {conditions?.map((v) => (<Form.Check
                                key={`condition-${v.id}`}
                                type="radio"
                                label={v.event}
                                className="d-flex align-items-center custom-radio-btns "
                                name="condition_id"
                                value={v.id}
                                id={`condition-${v.id}`}
                                checked={conditionId === v.id}
                                onChange={() => setConditionId(v.id)}
                            />))}
                        </Form.Group>
                    </div>)}

                    {/* {!conditions && (<div className="content d-flex align-items-center justify-content-between">
                        <Form.Group controlId="formBasicRadiobox" className="d-flex gap-5 align-items-center">
                            <Form.Label style={{ flex: 'none' }}>Condition Type</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                className="d-flex align-items-center"
                                name="condition_type_id"
                                value="1"
                                checked={conditionTypeId === 1}
                                onChange={() => setConditionTypeId(1)}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                className="d-flex align-items-center"
                                name="condition_type_id"
                                value="2"
                                checked={conditionTypeId === 2}
                                onChange={() => setConditionTypeId(2)}
                            />
                        </Form.Group>
                    </div>)} */}
                </>
            }
            handleClose={handleClose}
            handleConfirm={handleAdd}
        />
    );
};

export default JourneyConditionModal;
