/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faCircleXmark, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createSegmentRequest, segmentDetailRequest, updateSegmentRequest } from '../../../redux/modules/contacts/contactSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../component';
import { RootState } from '../../../redux/rootReducer';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import useSidebar from '../../../hooks/useSidebar';
import { toaster } from '../../../utils/toast';

interface Filter {
  id: number;
  field: string;
  operator: string;
  value: string;
  logical_operator: 'AND' | 'OR' | null;
}

const CreateSegment: React.FC = () => {
  const { segmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createSegment,segmentdetails,editedSegment, error, loading } = useSelector((state: RootState) => state.contactlist);
  const [filtersExist, setFiltersExist] = useState(false);
  const { onShowSidebar } = useSidebar();

  const validationSchema = Yup.object().shape({
    segmentName: Yup.string().trim().required('Segment Name is required').test(
      (value) => value.trim() !== ''
    ),
    filters: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().notOneOf(['Select'], 'Field is required').required('Field is required'),
        operator: Yup.string().required('Operator is required'),
        value: Yup.string().trim().required('Value is required'),
        logical_operator: Yup.string().nullable(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      segmentName: '',
      filters: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const locationId = localStorage.getItem('selectedLocation');
      const payload = {
        name: values.segmentName,
        filters: values.filters,
        location_id: locationId !== null ? locationId : ''
      }
      if (segmentId) {
        dispatch(updateSegmentRequest({segmentId:segmentId, updatedData:payload}));
      } else {
        dispatch(createSegmentRequest(payload));
      }
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (formik.values.filters.length === 0) {
      formik.setFieldValue('filters', [
        {
          id: 1,
          field: 'Select',
          operator: 'Select',
          value: '',
          logical_operator: null,
        },
      ]);
      setFiltersExist(true);
    }
  }, [formik.values.filters]);

  useEffect(() => {
    if (createSegment && createSegment?.success === true) {
      toast(createSegment?.message);
      navigate('/org/contacts/reviewSegment')
    }
    else if (!segmentId && error && error?.success === false) {
      toaster({success: false, message: error.message});
    }
    if(segmentId){
      if (editedSegment && editedSegment?.success === true) {
        toast(editedSegment?.message);
        navigate(`/org/contacts/reviewSegment/${segmentId}`);
      }
      else if (error && error?.success === false) {
        toast(error?.message)
      }
    }
  }, [error, createSegment,editedSegment])

  const handleAddFilter = (field: string) => {
    formik.setFieldValue('filters', [
      ...formik.values.filters,
      {
        id: formik.values.filters.length + 1,
        field,
        operator: 'Select',
        value: '',
        logical_operator: 'AND',
      },
    ]);
    setFiltersExist(true);

  };

  const handleRemoveFilter = (id: number) => {
    formik.setFieldValue(
      'filters',
      (formik.values.filters as Filter[]).filter((filter) => filter.id !== id)
    );
    setFiltersExist(!!formik.values.filters.length);
  };

  const handleFilterChange = (id: number, field: string, operator: string, value: string, logical_operator: string) => {
    formik.setFieldValue(
      'filters',
      formik?.values?.filters?.map((filter: Filter) =>
        filter.id === id
          ? { ...filter, field, operator, value, logical_operator: logical_operator as 'AND' | 'OR' }
          : filter
      )
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if(segmentId){
      dispatch(segmentDetailRequest(segmentId))
    }
    return () => {
      formik.resetForm();
    };
  }, []);
  
  useEffect(() => {
    if (segmentdetails && segmentdetails.success) {
      const { segmentName, filters } = segmentdetails;
      const mappedFilters = filters.map((filter:any) => ({
        id: filter.id,
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
        logical_operator: filter.logical_operator,
      }));
  
      formik.setValues({
        segmentName,
        filters: mappedFilters,
      });
    }
  }, [segmentdetails]);
  
  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between ">
        <h2 className='back-text'>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>{segmentId? "Edit a Segment" :"Create a Segment"}</h2>

      </div>
      <div className="add-contact">
        <Form onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        >   
          <Row>
            <Col xl={12} md={12} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Segment Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter segment name"
                  {...formik.getFieldProps('segmentName')}
                  onKeyDown={handleKeyDown}
                  isInvalid={formik.touched.segmentName && !!formik.errors.segmentName}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.segmentName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div className="advance-search">
            {filtersExist && formik.values.filters?.map((filter: Filter, index) => (
              <div className="item" key={filter.id}>
                <div className="choose-option d-flex align-items-center justify-content-between">
                  {index > 0 && (
                    <>
                      <span className="text">
                        <span
                          className={filter.logical_operator === 'AND' ? 'active' : ''}
                          onClick={() => handleFilterChange(filter.id, filter.field, filter.operator, filter.value, 'AND')}
                        >
                          AND
                        </span>
                        <span
                          className={filter.logical_operator === 'OR' ? 'active' : ''}
                          onClick={() => handleFilterChange(filter.id, filter.field, filter.operator, filter.value, 'OR')}
                        >
                          OR
                        </span>
                      </span>
                      <span onClick={() => handleRemoveFilter(filter.id)}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </span>
                    </>
                  )}
                </div>
                <div className="inputs">
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Form.Group className="form-group" controlId={`field-${filter.id}`}>
                        <Form.Select
                          aria-label="Default select example"
                          {...formik.getFieldProps(`filters[${index}].field`)}
                          isInvalid={formik.touched.filters && formik.touched.filters[index] && !!((formik.errors.filters as any)?.[index]?.field)}
                        >
                          <option value="">Select</option>
                          <option value="Tags">Tags</option>
                          <option value="Email">Email</option>
                          <option value="FirstName">First Name</option>
                          <option value="Address">Address</option>
                          <option value="Phone_Number">Phone Number</option>
                          <option value="DOB">DOB</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{((formik.errors.filters as any)?.[index]?.field)}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Form.Group className="form-group" controlId={`operator-${filter.id}`}>
                        <Form.Select
                          aria-label="Default select example"
                          {...formik.getFieldProps(`filters[${index}].operator`)}
                          isInvalid={formik.touched.filters && formik.touched.filters[index] && !!((formik.errors.filters as any)?.[index]?.operator)}
                        >
                          <option value="Select">Select</option>
                          <option value="Contains">Contains</option>
                          <option value="DoesNotContain">Does not contain</option>
                          <option value="isExactly">Is Exactly</option>
                          <option value="notExactly">Not Exactly</option>
                          {(formik.values.filters as any[])[index]?.field === 'DOB' && (
                              <>
                                <option value="isBefore">Is Before</option>
                                <option value="isAfter">Is After</option>
                              </>
                          )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{((formik.errors.filters as any)?.[index]?.operator)}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Form.Group className="form-group" controlId={`value-${filter.id}`}>
                        <Form.Control
                          type={((formik.values.filters as any[])?.[index]?.field) === 'DOB' ? 'date' : 'text'}
                          placeholder="Enter value"
                          {...formik.getFieldProps(`filters[${index}].value`)}
                          isInvalid={formik.touched.filters && formik.touched.filters[index] && !!((formik.errors.filters as any)?.[index]?.value)}
                        />
                        <Form.Control.Feedback type="invalid">{((formik.errors.filters as any)?.[index]?.value)}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </div>
            ))}
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-theme mb-4"
                  onClick={() => handleAddFilter('')}
                ><FontAwesomeIcon icon={faPlus} className='ms-0' /> Add Filter
                </Button>
              </div>
              <div className="btns">
              <div className='d-flex justify-content-between align-items-center'>
                <Button variant="primary" className='btn-theme btn-border' onClick={()=>navigate('/org/contacts/segments')}>
                  <FontAwesomeIcon icon={faPlay} />Back
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="btn-theme"
              >
                {loading && <Loader />}
                Review Segment
              </Button>
                {/* <Button variant="primary" type="submit" className='btn-theme' >
                  {loading && <Loader />}
                  Submit<FontAwesomeIcon icon={faPlay} />
                </Button> */}
              </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CreateSegment
