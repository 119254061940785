/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./preBuildTemplate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faDownload } from "@fortawesome/free-solid-svg-icons";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import PaginationNav from "../../../component/pagination/pagination";
import XLoader from "../../../component/loader/XLoader";
import { useNavigate } from "react-router-dom";
import useSidebar from "../../../hooks/useSidebar";

const PreBuiltTemplates: React.FC = () => {
  const [preBuiltTemplatesList, setPreBuiltTemplatesList] = useState([]);
  const { postData, isLoading } = useAxios();
  const [totalPages, setTotalPages] = useState<number>(0);
  const navigate = useNavigate();
  const { onShowSidebar } = useSidebar();
  const [filter, setFilter] = useState<any>({
    type: "BASIC",
    sort: "NEW",
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    fetchPreBuiltTemplates();
  }, [filter]);

  const fetchPreBuiltTemplates = async () => {
    try {
      const response = await postData(
        API_ENDPOINTS.GET_ALL_PRE_BUILT_TEMPLATES,
        filter
      );
      setPreBuiltTemplatesList(response?.data?.preBuiltTemplates);
      const totalTemplates = response?.data?.total;
      const totalNoOfPages = Math.ceil(totalTemplates / filter?.limit);
      setTotalPages(totalNoOfPages);
    } catch (error) {}
  };

  const handlePageChange = (pageNumber: number) => {
    setFilter((prevParams: any) => ({ ...prevParams, page: pageNumber }));
  };

  const importPreBuiltTemplate = async (templateId: number) => {
    const response = await postData(API_ENDPOINTS.IMPORT_PRE_BUILT_TEMPLATE, {
      templateId,
    });
    const { html, css } = response?.data;
    navigate(`/org/content/create-email-content`, {
      state: { preBuildTemplate: { html, css } },
    });
  };

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={() => onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Pre-Built Templates
        </h2>
      </div>
      <div className="templates-container">
        <div className="cards-grid">
          {!isLoading && preBuiltTemplatesList?.length === 0 && (
            <p className="text-center no-records">No record found.</p>
          )}
          {preBuiltTemplatesList?.map((template: any) => (
            <div className="card-container" key={template.templateId}>
              <img
                src={template.logo}
                alt={template.name}
                className="card-image"
              />
              <div className="card-hover">
                <button
                  className="import-button"
                  onClick={() => importPreBuiltTemplate(template.templateId)}
                >
                  <FontAwesomeIcon icon={faDownload} /> Import
                </button>
              </div>
              <div className="card-name">{template.name}</div>
            </div>
          ))}
        </div>
      </div>
      {isLoading && <XLoader />}
      {!isLoading && preBuiltTemplatesList?.length !== 0 && (
        <PaginationNav
          totalPages={totalPages}
          currentPage={filter.page}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PreBuiltTemplates;
