/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlay, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { XModal } from '../../../component';
import LocationsAccordian from './LocationsAccordian';
import useCompany from './useCompany';
import XButton from '../../../component/button/XButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import useSidebar from '../../../hooks/useSidebar';
import XLoader from '../../../component/loader/XLoader';

interface InfoItemProps {
  label: string;
  value: string | ReactNode;
  className?: string;
}

const renderInfoItem = ({ label, value, className }: InfoItemProps): JSX.Element => (
  <li className={`d-flex ${className ?? ''}`}>
    <span className='text-lt'>{label}</span>
    <span className='text-rt'>{value}</span>
  </li>
);

const CompanyDetails = () => {
  const { role_id } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { locationModalShow, setLocationModalShow, companyDetail, fetchCompanyDetail, isLoading } = useCompany()
  const { onShowSidebar } = useSidebar();

  useEffect(() => {
    fetchCompanyDetail()
  }, [])

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2>
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>My Profile
        </h2>
        <XButton
          variant="primary"
          className="btn-theme d-flex align-items-center"
          buttonText={<span>Edit Info <FontAwesomeIcon icon={faPenToSquare} /></span>}
          onClick={() => {
            navigate('/org/settings/profile/editProfile', { state: { companyDetail } })
          }}
        />
      </div>
      {!isLoading && <div className="contact-details">
        <div className="info-box">
          <Row>
            <Col xxl={6} xl={6} lg={12}>
              <ul>
                {renderInfoItem({ label: 'First Name', value: companyDetail?.first_name })}
                {renderInfoItem({ label: 'Last Name', value: companyDetail?.last_name })}
                {renderInfoItem({ label: 'Phone Number', value: companyDetail?.phone_number })}
                {renderInfoItem({ label: 'Email Address', value: companyDetail?.email })}
                {+role_id === 3 && renderInfoItem({ label: 'Website URL', value: companyDetail?.website_url })}
                {/* {renderInfoItem({ label: 'Total Contacts Per Company', value: '258' })}
                {renderInfoItem({ label: 'Contacts Minus Unsubscribes', value: '140' })} */}
              </ul>
            </Col>

            <Col xxl={6} xl={6} lg={12}>
              <ul>
                {+role_id === 3 && renderInfoItem({ label: 'Organization Name', value: companyDetail?.business_name })}
                {+role_id === 3 && renderInfoItem({ label: 'Business Logo', value: <div><img alt='bussiness-Logo' height={50} width={50} src={companyDetail?.logoUrl} /></div> })}
                {renderInfoItem({ label: 'All Locations', value: companyDetail?.locations?.length ?? 0, className: 'locations-item' })}

                <li>
                  <XButton
                    variant="primary"
                    buttonText={<span>View All Locations <FontAwesomeIcon icon={faPlay} /></span>}
                    className="btn-theme"
                    onClick={() => setLocationModalShow(true)}
                  />
                  {locationModalShow && (
                    <XModal
                      className='locations-modal'
                      title='Locations'
                      body={<LocationsAccordian locations={companyDetail?.locations ?? []} />}
                      show={locationModalShow}
                      handleClose={() => setLocationModalShow(false)}
                    />
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>}
      {isLoading && <XLoader />}
    </div>
  );
};

export default CompanyDetails;
