import { FC } from 'react';
import Col from "react-bootstrap/Col";

interface AnalyticCardProps {
  title: string;
  value: string;
  percentage?: string;
  icon: string;
}

const AnalyticCard: FC<AnalyticCardProps> = ({ title, value, percentage, icon }) => {
  return (
    <Col xxl={3} xl={6} lg={6} md={6} sm={12} xs={12}>
      <div className="card">
        <div className="d-flex">
          <img src={icon} alt="Icon" className="icon" />
          <div className="text">
            <span className="title">{title}</span>
            <div className="text-btm d-flex align-items-center justify-content-between">
              <span className="value">{value}</span>
              {percentage && <span className="percentage">{percentage}</span>}
              
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AnalyticCard;
