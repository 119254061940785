/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ViewOrganization from "../adminPortal/viewOrganization";
import AddOrganization from "../adminPortal/addOrganization";
import Organizations from "../adminPortal/organizations";
import { useDispatch } from "react-redux";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import { setSelectedLocationId, setUserData } from "../../redux/modules/auth/userSlice";
import { setLocationIdInLocalStorage } from "../../utils/helper";
import AuditLog from "../settings/auditLog";
import Users from "../settings/users";
import ViewUser from "../settings/users/viewUser";
import AddUser from "../settings/users/addUser";
import Permission from "../settings/permission";
import useAuthorization from "../../hooks/useAuthorization";
import { MODULES, ROLE } from "../../utils/enums";
import withAuthorization from "../../hoc/withAuthorization";
import AdminDashboard from "../adminPortal/adminDashboard";
import LogInActivities from "../settings/loginActivities/LoginActivities";

const AuthorizedAuditLog = withAuthorization(AuditLog, { module_id: MODULES.AUDIT_LOG, action: 'view' });
const AuthorizedOrganization = withAuthorization(Organizations, { module_id: MODULES.ORGANIZATIONS, action: 'view' });
const AuthorizedOrganizationsView = withAuthorization(ViewOrganization, { module_id: MODULES.ORGANIZATIONS, action: 'view' });
const AuthorizedOrganizationsAdd = withAuthorization(AddOrganization, { module_id: MODULES.ORGANIZATIONS, action: 'edit' });
const AuthorizedLoginActivities = withAuthorization(LogInActivities, { module_id: MODULES.LOGIN_ACTIVITY, action: 'view' });


const AdminContent: FC = (props: object) => {
  const dispatch = useDispatch();
  const { fetchData } = useAxios()
  const { role_id } = useAuthorization()

  useEffect(() => {
    const fetchUserDetail = async () => {
      const response = await fetchData(API_ENDPOINTS.GET_USER_INFO)
      if (response?.success) {
        dispatch(setUserData(response?.data ?? {}))

        //location id set start ------
        const locationId = setLocationIdInLocalStorage(response?.data.orgLocations)
        dispatch(setSelectedLocationId(locationId))
        //location id set end -----
      }
    }
    fetchUserDetail()
  }, [])

  const AuthorizePermission = role_id === ROLE.SUPER_ADMIN ? <Permission/> : <h1>UnAuthorize</h1>
  const AuthorizeUser = role_id === ROLE.SUPER_ADMIN ? <Users/> : <h1>UnAuthorize</h1>
  const AuthorizeViewUser = role_id === ROLE.SUPER_ADMIN ? <ViewUser/> : <h1>UnAuthorize</h1>
  const AuthorizeAddUser = role_id === ROLE.SUPER_ADMIN ? <AddUser/> : <h1>UnAuthorize</h1>

  return (
    <Routes>
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/Organizations" element={<AuthorizedOrganization />} />
      <Route path="/viewOrganization" element={<AuthorizedOrganizationsView />} />
      <Route path="/addOrganization" element={<AuthorizedOrganizationsAdd />} />
      <Route path="/settings/auditLog" element={<AuthorizedAuditLog />} />
      <Route path="/settings/users" element={AuthorizeUser} />
      <Route path="/settings/users/viewUser" element={AuthorizeViewUser} />
      <Route path="/settings/users/addUser/:id?" element={AuthorizeAddUser} />
      <Route path="/settings/pemissions" element={AuthorizePermission} />
      <Route path="/login-activity" element={<AuthorizedLoginActivities />} />
      <Route path="/*" element={404} />
    </Routes>
  );
};

export default AdminContent
