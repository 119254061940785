import { FC } from 'react';
import JourneyBuilder from './journeyBuilder';
import { JourneyProvider } from './JourneyContext';

const JourneyBuilderPage: FC = () => {
  return (
    <JourneyProvider>
      <JourneyBuilder />
    </JourneyProvider>
  );
};

export default JourneyBuilderPage;
