import { Bar } from "react-chartjs-2";
import { StatsType } from "./useCampaignAnalytic";

type Props = {
    stats: StatsType | undefined
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};


const TrackBarChart = (props: Props) => {
    const {stats} = props

    const data = {
        labels: ['Deliveries', 'Opened',  'Clicked'],
        datasets: [
            {
                label: 'Funnel',
                data: [stats?.delivered, stats?.opened, stats?.clicked],
                type: 'bar' as const,
                backgroundColor: '#7BAFD4',
                borderColor: 'white',
                borderWidth: 2,
            }
        ],
    };

    return (
        <>
            <div className="filter-bar d-flex align-items-center justify-content-between row-item analytics-bar sm-block">
                <div className='sub-title'>Track Conversion</div>
            </div>
            <Bar options={options} data={data} />
        </>

    )
}

export default TrackBarChart