/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Form, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronRight,
  faMagnifyingGlass,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { API_ENDPOINTS } from "../../constants/api";
import useAxios from "../../hooks/useAxios";
import XDeleteModal from "../../component/modal/XDeleteModal";
import { toaster } from "../../utils/toast";
import { toast } from "react-toastify";
import useSidebar from "../../hooks/useSidebar";
import PaginationNav from "../../component/pagination/pagination";
import XLoader from "../../component/loader/XLoader";
import { PermissionObject } from '../../redux/modules/auth/types';
import XConfirm from "../../component/confirm/XConfirm";
import { CampaignType } from "../../utils/enums";

interface CompaignProps {
  permission?: PermissionObject
}

const Organizations: React.FC<CompaignProps> = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { fetchData, deleteData, postData, isLoading } = useAxios();
  const { postData: unsubscribePostData, isLoading: unsubscribeIsLoading } = useAxios();
  const [organizationList, setOrganizationList] = useState<any[]>([]);
  const [queryParams, setQueryParams] = useState({
    search: "",
    page: 1,
    limit: 10,
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState("");
  const { onShowSidebar } = useSidebar();
  const [deactivateReasonError, setDeactivateReasonError] = useState("");
  const { permission } = props
  const [smsFeatureModal, setSMSFeatureModal] = useState<boolean>(false)

  useEffect(() => {
    fetchAllOrganizations();
  }, [queryParams]);

  const fetchAllOrganizations = async () => {
    try {
      const { search, page, limit } = queryParams;
      let query = `${API_ENDPOINTS.GET_ALL_ORGANIZATION}?page=${page}&limit=${limit}`;
      if (search) {
        query += `&search=${search}`;
      }
      const response = await fetchData(query);
      setOrganizationList(response.contactList);
      const totalOrganization = response?.totalCount;
      const totalNoOfPages = Math.ceil(totalOrganization / queryParams?.limit);
      setTotalPages(totalNoOfPages);
    } catch (error) {}
  };

  const addNewOrganization = () => {
    navigate("/Admin/addOrganization");
  };

  const handleDeactivate = (orgId: string) => {
    setSelectedOrgId(orgId);
    handleShow();
  };

  const handleActivate = (orgId: any) => {
    setSelectedOrgId(orgId);
    setShowActivationModal(true);
  };

  const handleActivateOrganization = async (orgId: any, isActive: boolean) => {
    const updatedOrganizationList = organizationList.map((organization) => {
      if (organization.id === orgId) {
        organization.is_active = !isActive;
      }
      return organization;
    });
    setOrganizationList(updatedOrganizationList);
    await postData(API_ENDPOINTS.UPDATE_IS_ACTIVE_ORGANIZATION, {
      organizationId: orgId,
    });
  };

  const submitHandleReason = async (orgId: any, isActive: boolean) => {
    if (!deactivateReason.trim()) {
      setDeactivateReasonError("Reason is required.");
      return;
    }
    setDeactivateReasonError("");
    handleClose();
    const updatedOrganizationList = organizationList.map((organization) => {
      if (organization.id === orgId) {
        organization.is_active = !isActive;
      }
      return organization;
    });
    setOrganizationList(updatedOrganizationList);
    const payload = { organizationId: selectedOrgId, reason: deactivateReason };
    await postData(API_ENDPOINTS.UPDATE_IS_ACTIVE_ORGANIZATION, payload);
    setDeactivateReason("");
  };

  const editOrganization = async (orgId: string) => {
    const response = await fetchData(`${API_ENDPOINTS.GET_USER_INFO}/${orgId}`);
    const selectedOrganizationData = response?.data ?? {};
    if (selectedOrganizationData) {
      navigate("/Admin/addOrganization", {
        state: { selectedOrgData: selectedOrganizationData },
      });
    }
  };

  // const handleDeleteIconClick = (orgId: string) => {
  //   setSelectedOrganization(orgId);
  //   setShowDeleteModal(true);
  // };

  const handleDeleteOrganization = async (id: string) => {
    try {
      const response = await deleteData(
        `${API_ENDPOINTS.DELETE_USER}/${id}`
      );
      if (response && response.success === true) {
        setOrganizationList((prevList) =>
          prevList.filter((org) => org.id !== id)
        );
        toast(response.message);
      }
    } catch (error) {
      toaster({ success: false, message: "error fetching users" });
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: pageNumber }));
  };

  const handleSearchChange = (searchValue: string): void => {
    setQueryParams((prevParams) => ({ ...prevParams, search: searchValue }));
  };

  const handleOrgDetailClick = (orgId: string) => {
    navigate(`/Admin/viewOrganization`, { state: { orgId } });
  }

  const onSMSFeatureSubmit = async () => {
    const response = await unsubscribePostData(API_ENDPOINTS.TOGGLE_FEATURE, { feature: CampaignType.SMS, organizationId: selectedOrganization });
    const { message, success } = response;
    toaster({message, success})
    fetchAllOrganizations();
    setSMSFeatureModal(false)
  }

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between main-title-block">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Organizations
        </h2>
        <div className="filter-bar d-flex align-items-center row-item top-block">
          <div className="position-relative search-bar">
            <Form.Control
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={queryParams.search}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          {(permission?.edit) && (
          <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            onClick={() => addNewOrganization()}
          >
            Add New Organization
          </Button>
          )}
        </div>
        {/* <Button
            variant="primary"
            type="submit"
            className="btn-theme"
          >Add User
          </Button> */}
      </div>

      <div className="table-listing all-contacts users-list position-relative">
        <Table responsive>
          <thead>
            <tr>
              <th>Organisation Name</th>
              <th className="text-center">Total Contacts Per Company</th>
              <th className="text-center">Contacts Minus Unsubscribes </th>
              <th>Locations</th>
              <th>SMS Feature</th>
              <th className="text-center">Status</th>
              <th>Actions </th>
            </tr>
          </thead>
            
          <tbody>
            {!isLoading && organizationList?.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="text-center no-records">No Organizations found.</p>
                </td>
              </tr>
            )}
            {!isLoading && organizationList?.map((org: any) => (
              <tr key={org.id}>
                <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-bottom">Click here to view ({org.business_name}) details</Tooltip>}
                    >
                      <span onClick={() => handleOrgDetailClick(org.id)}>
                        <span
                          className="d-inline-block text-truncate cursor-pointer"
                          style={{ maxWidth: "150px" }}
                        >
                          {org.business_name}
                        </span>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="ms-2 mb-1 cursor-pointer"
                        />
                      </span>
                    </OverlayTrigger>
                  </td>
                {/* <td>
                  <img
                    className="business-logo"
                    alt="bussiness-Logo"
                    src={`${process.env.REACT_APP_SERVER_API}/${org.filename}`}
                  />
                </td> */}
                <td align="center">{org?.contact_count}</td>
                <td align="center">{((+org?.contact_count ?? 0) - (+org?.contact_unsubscribe_count ?? 0))}</td>
                <td align="center">{org?.location_count}</td>
                <td>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={org?.sms_feature}
                    onChange={() => {
                      setSelectedOrganization(org.id)
                      setSMSFeatureModal(true)
                    }}
                  />
              </td>
                <td>
                  {org.is_active ? (
                    <span>
                      <span
                        className={(permission?.delete) ? "status-badge green cursor-pointer" : " status-badge green disabled cursor-pointer"}
                        onClick={() => handleDeactivate(org.id)}
                      >
                        Activate
                      </span>
                      <Modal
                        show={show && selectedOrgId === org.id}
                        onHide={() => {
                          handleClose(); 
                          setDeactivateReasonError("");
                        }}
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reason to Deactivate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="form-group mb-0"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Reason</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={5}
                                placeholder="Enter"
                                value={deactivateReason}
                                onChange={(e) =>
                                  setDeactivateReason(e.target.value)
                                }
                              />
                              {deactivateReasonError && !deactivateReason && (
                                <span className="text-danger error-text">{deactivateReasonError}</span>
                              )}
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-theme"
                            onClick={() =>
                              submitHandleReason(selectedOrgId, org.is_active)
                            }
                          >
                            Submit
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </span>
                  ) : (
                    <span>
                      <span
                        className={(permission?.delete) ? "status-badge" : " status-badge disabled"}
                        onClick={() => handleActivate(org.id)}
                      >
                        Deactivate
                      </span>
                      <XDeleteModal
                        show={showActivationModal}
                        handleClose={() => setShowActivationModal(false)}
                        title="Activate Account"
                        body={`Are you sure you want to activate this organisation?`}
                        onConfirm={() => {
                          if (selectedOrgId) {
                            handleActivateOrganization(
                              selectedOrgId,
                              org.is_active
                            );
                          }
                          setShowActivationModal(false);
                        }}
                        onCancel={() => setShowActivationModal(false)}
                      />
                    </span>
                  )}
                </td>
                <td className="actions">
                  {/* <FontAwesomeIcon
                    icon={faEye}
                    onClick={() =>
                      navigate("/org/adminPortal/viewOrganization")
                    }
                  /> */}
                  {/* <FontAwesomeIcon
                    icon={faCircleXmark}
                    onClick={() => handleDeleteIconClick(org.id)}
                  /> */}
                  {(permission?.edit) && (
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    onClick={() => editOrganization(org.id)}
                  />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {isLoading && <XLoader />}
        <XConfirm
          message={`Are you sure you want to change the sms feature?`}
          show={smsFeatureModal}
          onCancel={() => setSMSFeatureModal(false)}
          onConfirm={() => onSMSFeatureSubmit()}
          isLoading={unsubscribeIsLoading}
        />
        <XDeleteModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title="Delete Organization"
          body={`Are you sure you want to delete the organization`}
          onConfirm={() => {
            if (selectedOrganization) {
              handleDeleteOrganization(selectedOrganization);
            }
            setShowDeleteModal(false);
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      </div>
    {!isLoading && organizationList?.length !== 0 && (
      <PaginationNav
        totalPages={totalPages}
        currentPage={queryParams.page}
        handlePageChange={handlePageChange}
      />
    )}
    </div>
  );
};

export default Organizations;
