import moment from "moment";
import { ROLE, TimeUnit } from "./enums";
import { toaster } from "./toast";

export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export function debounceAsync(func, delay) {
    let timeoutId;
    let lastPromise;

    return function (...args) {
        clearTimeout(timeoutId);

        return new Promise((resolve, reject) => {
            const later = async () => {
                timeoutId = null;
                try {
                    const result = await func.apply(this, args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            };

            timeoutId = setTimeout(later, delay);

            if (lastPromise) {
                lastPromise.catch(() => {}); // Ignore any previous pending promise
            }
            lastPromise = new Promise((resolve) => {
                resolve(); // Resolving the promise immediately after setting the timeout
            });
        });
    };
}

export const convertToMilliSeconds = (delayValue, delayUnit) => {
    const conversionFactors = {
        [TimeUnit.SECONDS]: 1,
        [TimeUnit.MINUTES]: 60,
        [TimeUnit.HOURS]: 3600,
        [TimeUnit.DAYS]: 86400
    };

    return delayValue * conversionFactors[delayUnit] * 1000;
};


export const formatDate = created_at =>
    new Date(created_at).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });


export function getMonthName(monthNumber) {
    const date = new Date(null, monthNumber - 1);
    return date.toLocaleString('en', { month: 'long' });
}

export const utcToLocalString = date => {
    return moment.utc(date).local().format('YYYY-MM-DDTHH:mm')
}

export const utcToLocalStringWithoutT = date => {
    return moment.utc(date).local().format('YYYY-MM-DD hh:mm A')
}

export const localeToUtcString = date => {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm')
}

export const utcToLocalStringWithTime = date => {
    return moment(date).format('MMM DD, YYYY HH:mm A')
}

export const openInNewTab = (url) => {
    window.open(url, '_blank');
}

export const formatDateToYYYYMMDDTHHMM = (date = (new Date())) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const decodeJwt = (token) => {
    try {
        const [, payloadEncoded] = token.split('.');
        // const header = JSON.parse(atob(headerEncoded));
        const payload = JSON.parse(atob(payloadEncoded));
        return payload;
    } catch (error) {
        return null
    }
}


export const decodeMilliseconds = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    let value, unit;
    if (duration.asDays() >= 1) {
        value = duration.asDays();
        unit = TimeUnit.DAYS;
    } else if (duration.asHours() >= 1) {
        value = duration.asHours();
        unit = TimeUnit.HOURS;
    } else if (duration.asMinutes() >= 1) {
        value = duration.asMinutes();
        unit = TimeUnit.MINUTES;
    } else {
        value = 0;
        unit = '';
    }
    value = parseFloat(value.toFixed(2));
    return { value, unit };
}


export const setLocationIdInLocalStorage = (orgLocations = []) => {
    const localStorageLocationId = localStorage.getItem('locationId')
    const idArray = orgLocations?.map((item) => +item.id);
    let locationId = ''
    if (localStorageLocationId && idArray.includes(+localStorageLocationId)) {
        locationId = localStorageLocationId
    } else {
        locationId = orgLocations[0].id
    }
    localStorage.setItem('locationId', locationId);
    return locationId
}

export const columnNameToLabel = (columnName) => {
  const label = columnName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  return label;
};

export const downloadCsv = (FieldList) => {
    const predefinedFields = FieldList?.predefinedFields?.map((fields) => {
      return [fields.name];
    });
  
    const customFields = FieldList?.customFields?.map((fields) => {
      return [fields.name];
    });
    const csvColumns = [...predefinedFields, ...customFields];
    const transposedCsvData = csvColumns[0].map((_, colIndex) =>
      csvColumns.map((row) => row[colIndex])
    );
    const csvContent =
      "data:text/csv;charset=utf-8," +
      transposedCsvData.map((row) => row.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "CSV-File-Format");
    document.body.appendChild(link);
    link.click();
  };

  export const copyToClipboard = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toaster({success: true, message: 'Copied'})
      })
      .catch((err) => {});
  };

  export const goToDashboard = (navigate, role_id) => {
    if (role_id === ROLE.SUPER_ADMIN || role_id === ROLE.SUPER_ADMIN_STAFF) {
      localStorage.setItem('expandedItems', JSON.stringify(["/Admin/dashboard"]));
      navigate("/Admin/dashboard");
    } else {
      localStorage.setItem('expandedItems', JSON.stringify(["org/dashboard"]));
      navigate("/org/dashboard");
    }
  };