import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CombinedFormData, StepOneFormValues, StepThreeFormValues, StepTwoFormValues } from './types';

interface AccountSetupState {
  stepOne: StepOneFormValues;
  stepTwo: StepTwoFormValues;
  stepThree: StepThreeFormValues;
  loading: boolean;
  error: any;
  data:any;
}

const initialState: AccountSetupState = {
  stepOne: {
    firstName: '',
    lastName: '',
    businessName: '',
    phoneNumber: '',
  },
  stepTwo: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
  stepThree: {
    websiteURL: '',
    logo: null},
    loading: false,
    error: null,
    data:null
};

const accountSetupSlice = createSlice({
  name: 'accountSetup',
  initialState, 
  reducers: {

    setStepOneData: (state, action: PayloadAction<StepOneFormValues>) => {
      state.stepOne = action.payload;
    },
    setStepTwoData: (state, action: PayloadAction<StepTwoFormValues>) => {
      state.stepTwo = action.payload;
    },
    setStepThreeData: (state, action: PayloadAction<StepThreeFormValues>) => {
        state.stepThree = action.payload;
        state.loading = true;
        state.error = null;
    },
    accountSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data= action.payload;
    },
    accountFailure(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setStepOneData, setStepTwoData, setStepThreeData,accountSuccess,accountFailure} = accountSetupSlice.actions;

export default accountSetupSlice.reducer;
