import Spinner from "react-bootstrap/Spinner";
import { CSSProperties, FC } from 'react';
import './XLoader.css';

interface XLoaderProps {
    size?: 'small' | 'medium' | 'large';
    className?: string;
    style?: CSSProperties;
}

const XLoader: FC<XLoaderProps> = ({ size = 'medium', className='', style }) => {
    return (
        <div className={`x-loader-container ${className}`} style={style}>
            <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className={`spinner-${size}`}
            />
        </div>
    );
};

export default XLoader;
