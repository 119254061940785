/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { API_ENDPOINTS } from "../../constants/api";
import { ReactNode, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { toaster } from "../../utils/toast";
import { useNavigate } from "react-router-dom";

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  editTemplateData?: {
    id: string;
    name: string;
    folder_id: string;
    content: string;
  };
  previewHtml: string;
  previewCss: string;
};

interface FormValues {
  name: string;
  selectedFolder: SelectedFolderType[];
}
interface SelectedFolderType {
  id: string;
  data: object;
  label: string;
  value: string;
}

const CreateContentModal = (props: Props) => {
  const { visible, setVisible, editTemplateData, previewHtml, previewCss } = props;
  const [folders, setFolders] = useState<any>({});
  const { fetchData, postData, isLoading, setIsLoading } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (editTemplateData?.name) formik.setFieldValue("name", editTemplateData?.name ?? "");
    if (editTemplateData?.folder_id) {
      const editFolder = [folders[editTemplateData?.folder_id]];
      formik.setFieldValue("selectedFolder", editFolder ?? []);
    }
  }, [editTemplateData?.id, folders]);

  const fetchFolders = async () => {
    try {
      const response = await fetchData(API_ENDPOINTS.GET_ALL_FOLDERS);
      const formattedOption = response?.data.reduce((acc: any, v: any) => {
        acc[v.id] = {
          value: v.id,
          text: v.name,
          label: v.name,
          key: v.name,
          data: v,
        };
        return acc;
      }, {});
      setFolders(formattedOption);
    } catch (error) {}
  }

  const handleFolderCreate = async (inputValue: string) => {
    const payload = {
      name: inputValue,
    };
    const response = await postData(API_ENDPOINTS.CREATE_FOLDER, payload);
    if (response.success) {
      const savedData = response.data;
      const obj = {
        value: savedData.id,
        text: savedData.name,
        label: savedData.name,
        key: savedData.name,
        data: savedData,
      };
      setFolders({
        ...folders,
        [savedData.id]: obj,
      });
      formik.setFieldValue("selectedFolder", [obj]);

      // formik.setFieldValue('selectedFolder', savedData.id)
    }

    // formik.setFieldValue('selectedFolder', [...(selectedOption || []), newTag]);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // content: Yup.string().required("Content is required"),
    selectedFolder: Yup.array().min(1, "Folder selection is required"),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      // content: "",
      selectedFolder: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handelSubmit(values);
    },
  });

  const handelSubmit = async (values: FormValues) => {
    setIsLoading(true);
    const { name, selectedFolder } = values;
    window.StripoApi.compileEmail(async (err: string, html: string) => {
      try {
        const id = editTemplateData?.id ?? "";
        const url = id
          ? API_ENDPOINTS.EDIT_TEMPLATE
          : API_ENDPOINTS.CREATE_TEMPLATE;
        let payload = {
          content: html,
          html: previewHtml,
          css: previewCss,
          name,
          folder_id: selectedFolder[0]?.value ?? "",
          ...(id && { id }),
        };
        const response = await postData(url, payload);
        const { message, success } = response;
        if (response.success) {
          formik.resetForm();
          navigate("/org/content/email-content-library");
        }
        toaster({ message, success });
      } catch (error) {
        toaster({ message: "something went wrong", success: false });
      }
    });
  };
  return (
    <Modal show={visible} onHide={() => setVisible(false)} centered>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{editTemplateData?.id ? 'Update' : 'Create'} a Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group" controlId="formBasicEmail">
            <Form.Label>Name your template</Form.Label>
            <Form.Control
              type="text"
              {...formik.getFieldProps("name")}
              placeholder="Name"
              isInvalid={formik.touched.name && !!formik.errors.name}
            />
            {formik.errors.name && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-group" controlId="formBasicEmail">
            <Form.Label>Folder</Form.Label>

            <CreatableSelect
              {...formik.getFieldProps("selectedFolder")}
              options={Object.values(folders)}
              onCreateOption={(inputValue) => handleFolderCreate(inputValue)}
              value={formik.values.selectedFolder}
              onChange={(selected) => {
                formik.setFieldValue("selectedFolder", [selected]);
              }}
            />
            {formik.touched.selectedFolder && formik.errors.selectedFolder && (
              <div className="text-danger error-text">
                {formik.errors.selectedFolder as ReactNode}
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="">
          <Button
            variant="primary"
            type="submit"
            className="btn-theme"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Save & Continue"}
            <FontAwesomeIcon icon={faPlay} />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateContentModal;
