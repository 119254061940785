/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { API_ENDPOINTS } from '../constants/api';
import { mapMaker } from '../utils/options';

interface Props {
  refresh?: boolean;
}

export type ListType = {
  id: number;
  name: string;
  contact_count: number;
};

export type ListMapType = {
  [key: string]: ListType;
};

const useList = ({ refresh = true }: Props = {}) => {
  const [listMap, setListMap] = useState<ListMapType>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchData } = useAxios();

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const response = await fetchData(API_ENDPOINTS.GET_LISTS);
        if (response?.success) {
          setListMap(mapMaker(response.contactList, { key: 'id', value: 'id', text: 'name' }));
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (refresh) {
      fetchLists();
    }
  }, [refresh]);

  return {
    listMap,
    loading,
  };
};

export default useList;
