/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC } from "react";
import "./createContent.css";
import Stripo from "../../component/stripo/Stripto";
import CreateContentModal from "./createContentModal";
import { useLocation, useParams } from "react-router-dom";
import XModal from "../../component/modal/XModal";
import useAxios from "../../hooks/useAxios";
import XButton from "../../component/button/XButton";
import { stripoCssTemplate, stripoHtmlTemplate } from "../../component/stripo/stripo.template";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    Stripo: any;
    StripoApi: any;
  }
}
const CreateContent: FC = () => {
  const { orgLocationMap, selectedLocationId } = useSelector((state: RootState) => state.user);

  const [previewHtml, setPreviewHtml] = useState<string>("");
  const [previewCss, setPreviewCss] = useState<string>("");
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [html, setHtml] = useState<string>();
  const [css, setCss] = useState<string>();
  const [createContentModal, setCreateContentModal] = useState<boolean>(false);
  const { id: templateId } = useParams();
  const location = useLocation();
  const { editTemplateData, preBuildTemplate } = location.state || {};
  const { isLoading, setIsLoading } = useAxios();

  useEffect(() => {
    getInitialTemplate();
  }, []);

  const getInitialTemplate = async () => {
    const location = orgLocationMap?.[+selectedLocationId]
    const footerAddressNode = `<p class="footer-text address"></p>`
    const address = `<p class="footer-text address">${location?.address}</p>`;
    let h, c
    if (templateId) {
      h = editTemplateData.html.replace(footerAddressNode, address)
      c = editTemplateData.css
    } else {
      h = (preBuildTemplate?.html || stripoHtmlTemplate).replace(footerAddressNode, address)
      c = preBuildTemplate?.css || stripoCssTemplate
    }
    setHtml(h);
    setCss(c);
  };

  return (
    <div>
      <Stripo
        html={html}
        css={css}
      />
      {previewModal && (
        <XModal
          title="Preview"
          confirmText="Next"
          show={previewModal}
          handleClose={() => setPreviewModal(false)}
          handleConfirm={() => {
            setPreviewModal(false);
            setCreateContentModal(true);
          }}
          size="xl"
          body={
            <div style={{ maxHeight: "67vh", overflowY: "auto" }}>
              <style>{`${previewCss}`}</style>
              <div
                style={{ pointerEvents: 'none' }}
                dangerouslySetInnerHTML={{
                  __html: previewHtml ?? "",
                }}
              />
            </div>
          }
        />
      )}
      {createContentModal && (
        <CreateContentModal
          visible={createContentModal}
          setVisible={setCreateContentModal}
          editTemplateData={editTemplateData}
          previewHtml={previewHtml}
          previewCss={previewCss}
        />
      )}
      <div className="d-flex justify-content-end">
        <XButton
          buttonText="Preview"
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            window.StripoApi.getTemplate((html: string, css: string)=>{
              setPreviewHtml(html);
              setPreviewCss(css)
              setPreviewModal(true);
              setIsLoading(false);
            })
            // window.StripoApi.compileEmail((err: string, html: any) => {
            //   setPreviewHtml(html);
            //   setPreviewModal(true);
            //   setIsLoading(false);
            // });
          }}
        />
      </div>
    </div>
  );
};

export default CreateContent;
