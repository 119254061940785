/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Button, Modal, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../../constants/api";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { toaster } from "../../utils/toast";

const ViewOrganization = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { fetchData, postData } = useAxios();
  const [activeNumbers, setActiveNumbers] = useState<any[]>([]);
  const [allActiveNumbers, setAllActiveNumbers] = useState<any[]>([]);
  const location = useLocation();
  const { orgId } = location.state || {};
  const [selectedNumber, setSelectedNumber] = useState<any>("");

  useEffect(() => {
    getActiveNumbers();
    getUnusedActiveNumbers();
  }, []);

  const getActiveNumbers = async () => {
    try {
      const response = await fetchData(`${API_ENDPOINTS.GET_NUMBERS}/${orgId}`);
      setActiveNumbers(response?.data);
    } catch (error) {}
  };

  const getUnusedActiveNumbers = async () => {
    try {
      const response = await fetchData(API_ENDPOINTS.GET_UNUSED_ACTIVE_NUMBERS);
      const formattedData = response?.data?.map((number: any) => ({
        value: number.phoneNumber,
        label: number.phoneNumber,
      }));
      setAllActiveNumbers(formattedData);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    const payload = { orgId, selectedNumber };
    const response = await postData(
      API_ENDPOINTS.ASSIGN_NUMBER_TO_ORG,
      payload
    );
    const { message, success } = response;
    toaster({ message, success });
    getActiveNumbers();
    getUnusedActiveNumbers();
  };

  return (
    <div>
      <div className="main-title ">
        <h2 className="back-text">
          <span className="d-lg-none cursor-pointer">
            <FontAwesomeIcon icon={faBars} />
          </span>
          Organization Details
        </h2>
        <span className="cursor-pointer" onClick={() => navigate("/Admin/Organizations")}>
          <FontAwesomeIcon icon={faPlay} /> Back to all Organizations
        </span>
      </div>
      <div className="filter-bar d-flex justify-content-between align-items-center">
        <div className="position-relative search-bar">
          <Button
            variant="primary"
            type="button"
            className="btn-theme"
            onClick={handleShow}
          >
            Add Number
          </Button>
        </div>
        {activeNumbers?.length > 0 && (
          <p>{activeNumbers?.length} Contact(s) Available </p>
        )}
      </div>
          <Row>
            <div className="table-listing contact-list all-contacts position-relative">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Serial No. </th>
                    <th>Active Numbers </th>
                  </tr>
                </thead>
                <tbody>
                  {activeNumbers?.length === 0 && (
                    <tr>
                      <td colSpan={9}>
                        <p className="text-center no-records">
                          No contacts available in this organization.
                        </p>
                      </td>
                    </tr>
                  )}
                  {activeNumbers?.map((number, i) => {
                    return (
                      <tr key={number.number}>
                        <td>{i + 1}</td>
                        <td>{number.number}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Modal
              show={show}
              onHide={() => {
                handleClose();
                setSelectedNumber("");
              }}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Number</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label>Select Contact For The Organization</Form.Label>
                <Form.Group
                  className="form-group"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Select
                    aria-label="Default select example"
                    value={selectedNumber}
                    onChange={(e) => setSelectedNumber(e.target.value)}
                  >
                    <option>Select</option>
                    {allActiveNumbers?.map((number, i) => (
                      <option key={i} value={number.phoneNumber}>
                        {number.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  variant="primary"
                  type="submit"
                  className="btn-theme"
                  onClick={() => {
                    handleSubmit();
                    handleClose();
                    setSelectedNumber("");
                  }}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </Row>
    </div>
  );
};

export default ViewOrganization;
