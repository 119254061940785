import { ChangeEvent, FC } from 'react';
import { Form } from 'react-bootstrap';

interface FilterType {
  dateRange: "lastWeek" | "lastMonth" | "lastYear" | "custom";
  from?: string;
  to?: string;
}

interface Props {
  value: FilterType;
  onChange: (e: ChangeEvent<any>) => void;
}

const AnalyticDateFilter: FC<Props> = ({ value, onChange }) => {
  return (
    <div className="d-flex align-items-center gap-2 justify-content-evenly">
      <Form.Group className="d-flex align-items-center" style={{width:'150px'}}>
        <Form.Select
          name="dateRange"
          onChange={onChange}
          value={value.dateRange}
        >
          <option value="">Select</option>
          <option value="lastWeek">Last week</option>
          <option value="lastMonth">Last month</option>
          <option value="lastYear">Last year</option>
          <option value="custom">Custom</option>
        </Form.Select>
      </Form.Group>

      {value.dateRange === 'custom' && (
        <>
          <div className='d-flex align-item-center justify-content-center'>
            {/* <label htmlFor="fromDate">From:</label> */}
            <Form.Control
              type="date"
              name="from"
              id="fromDate"
              value={value.from}
              onChange={onChange}
              className="form-control"
              style={{ width: "200px" }}
            />
          </div>

          <div className='d-flex align-item-center justify-content-center'>
            {/* <label htmlFor="toDate">To:</label> */}
            <Form.Control
              type="date"
              name="to"
              id="toDate"
              value={value.to}
              onChange={onChange}
              className="form-control"
              style={{ width: "200px" }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AnalyticDateFilter;
