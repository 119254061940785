import { useFormik } from 'formik';
import { FC } from 'react';
import { Col, Form, FormControl, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { XModal } from '../../component';
import MultiLevelDropdown from './multiLevelDropdown';
import { TriggerDataType } from './useJourneyBuilder';
import { CampaignType, JourneyAction, TimeUnit } from '../../utils/enums';
import { convertToMilliSeconds, decodeMilliseconds } from '../../utils/helper';

interface Props {
    show: boolean;
    actionId?: number;
    handleClose: () => void;
    handelSubmit:  (data: any) => void,
    templates: any[],
    data?: TriggerDataType,
    currentId: number
}

const JourneyEmail: FC<Props> = ({ show, handleClose, actionId, handelSubmit, templates, data, currentId }) => {
    const { value: timeDelayValue, unit: timeDelayUnit } = decodeMilliseconds(data?.delaySeconds || 0);
    const formik = useFormik({
        initialValues: {
            subject: data?.subject ?? "",
            templateId: data?.templateId ? data?.templateId + '' : '',
            delayValue: timeDelayValue ? timeDelayValue : 5,
            delayUnit: timeDelayUnit ? timeDelayUnit : TimeUnit.MINUTES,
        },
        validationSchema: Yup.object().shape({
            ...(actionId === JourneyAction.EMAIL && {
                subject: Yup.string().required('Email domain is required'),
            }),
            templateId: Yup.string().required('Template is required'),
            delayValue: Yup.number()
            .min(1, 'Delay Value must be greater than 0')
            .required('Delay Value is required'),
            delayUnit: Yup.string().required('Delay Unit is required'),
        }),
        onSubmit: (values) => {
            const { delayValue, delayUnit } = values
            const delaySeconds = convertToMilliSeconds(delayValue, delayUnit)
            handelSubmit({
                ...values, actionId,
                ...(actionId === JourneyAction.SMS && {
                    subject: '',
                }),
                ...(currentId !== 1 && {
                    delaySeconds
                }),
            })
            handleClose()
        },
    });

    const handleTemplateSelect = (id: any) => {
        formik.setFieldValue('templateId', id);
    }

    const addHandler = () => {
        formik.handleSubmit()
    }

    return (
        <XModal
            cancelText="Cancel"
            confirmText="Add"
            size="lg"
            title={`Fill ${actionId === JourneyAction.EMAIL ? CampaignType.EMAIL: CampaignType.SMS} Details`}
            show={show}
            body={
                <>
                    {actionId === JourneyAction.EMAIL && (<Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                            placeholder="Enter Subject"
                            {...formik.getFieldProps('subject')}
                        />
                        <Form.Text className="text-danger error-text">{formik.touched.subject && formik.errors.subject}</Form.Text>
                    </Form.Group>)}
                    

                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label>Template Select From Library</Form.Label>
                        <MultiLevelDropdown
                            data={templates}
                            onSelect={handleTemplateSelect}
                            value={formik.values.templateId}
                        />
                        <Form.Text className='text-danger error-text'>
                            {formik.touched.templateId && formik.errors.templateId}
                        </Form.Text>
                    </Form.Group>
                    
                    {currentId !== 1 && (<>
                        <Form.Label>Time Delay</Form.Label>
                        <Row>
                            <Col>
                                <FormControl
                                    name="delayValue"
                                    type="number"
                                    placeholder="Enter delay value"
                                    value={formik.values.delayValue}
                                    onChange={formik.handleChange}
                                    isInvalid={formik?.touched?.delayValue && !!formik?.errors?.delayValue}
                                //disabled={isJourneyCompleted}
                                />
                                {formik.errors.delayValue && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.delayValue}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col>
                                <Form.Select
                                    name="delayUnit"
                                    value={formik.values.delayUnit}
                                    onChange={formik.handleChange}
                                    isInvalid={formik?.touched?.delayUnit && !!formik?.errors?.delayUnit}
                                //disabled={isJourneyCompleted}
                                >
                                    <option value="">Select</option>
                                    <option value={TimeUnit.MINUTES}>Minutes</option>
                                    <option value={TimeUnit.HOURS}>Hours</option>
                                    <option value={TimeUnit.DAYS}>Days</option>
                                </Form.Select>
                                {formik.errors.delayUnit && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.delayUnit}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                        </Row>
                    </>)}
                </>
            }
            handleClose={handleClose}
            handleConfirm={addHandler}
        />
    );
};

export default JourneyEmail;
