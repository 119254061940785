import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addListsRequestParams, AddListsRequestPayload, DeleteListState, GetListsRequestParams, ListsState } from './types';

const initialState: ListsState = {
    list: [],
    loading: false,
    errorRes: null,
    tags: [],
    successRes: false,
    listRes: null,
};

const listsSlice = createSlice({
    name: 'Lists',
    initialState,
    reducers: {
        getListsRequest(state, action: PayloadAction<GetListsRequestParams>) {
            state.loading = true;
            state.errorRes = null;
            state.successRes= false
        },
        getListsSuccess(state, action: PayloadAction<any>) {
            state.list = action.payload;
            state.loading = false;
            state.errorRes = null;
        },
        getListsFailure(state, action: PayloadAction<string>) {
            state.list = [];
            state.loading = false;
            state.errorRes = action.payload;
        },
        deleteListsRequest(state , action: PayloadAction<DeleteListState>) {
            state.list.contactList = state.list.contactList.filter((item: { id: any; }) => item.id !== action.payload.listId);
            state.loading = true;
            state.errorRes = null;
        },
        deleteListsSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.successRes= true;
            state.listRes=action.payload;

        },
        deleteListsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorRes = action.payload;
        },
        addListsRequest(state, action: PayloadAction<AddListsRequestPayload>) {
            state.loading = true;
            state.errorRes = null;
            state.successRes= false
        },
        addListsSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.errorRes = null;
            state.successRes= true;
            state.listRes=action.payload;
        },
        addListsFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.errorRes = action.payload;
            state.successRes= false
        },
        clearListData: (state) => { 
            state.loading= false
            state.errorRes= null
            state.successRes=false
            state.listRes = null
          }
    },
});

export const {
    getListsRequest,
    getListsSuccess,
    getListsFailure,
    deleteListsRequest,
    deleteListsSuccess,
    deleteListsFailure,
    addListsRequest,
    addListsSuccess,
    addListsFailure,
    clearListData
} = listsSlice.actions;

export default listsSlice.reducer;
