/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard";
import Compaign from "../compaign";
import AllContacts from "../contacts/allContacts";
import AddContact from "../contacts/addcontact";
import ContactDetails from "../contacts/contactDetails";
import Lists from "../contacts/lists";
import Users from "../settings/users";
import ViewUser from "../settings/users/viewUser";
import AddUser from "../settings/users/addUser";
import CompanyDetails from "../settings/myProfile";
import EditCompany from "../settings/myProfile/editProfile";
import Domains from "../settings/domains";
import Journey from "../journey";
import CampaignAnalytics from "../analytics/campaignAnalytics.tsx";
import JourneyAnalytics from "../analytics/journeyAnalytics.tsx";
// import AddContact from "../contacts/addcontact";
import Segments from "../contacts/segments";
import ContentLibrary from "../content/contentLibrary";
import CreateSegment from "../contacts/segments/createSegment";
import CreateContent from "../content/CreateContent";
import ReviewSegment from "../contacts/segments/reviewSegment";
import CustomField from "../settings/customfields";
import Permission from "../settings/permission";
import withAuthorization from "../../hoc/withAuthorization";
import { MODULES } from "../../utils/enums";
import useAuthorization from "../../hooks/useAuthorization";
// import AuditLog from "../settings/auditLog";
import JourneyBuilderPage from "../journey/JourneyBuilderPage";
import useAxios from "../../hooks/useAxios";
import { API_ENDPOINTS } from "../../constants/api";
import { useDispatch } from "react-redux";
import { setSelectedLocationId, setUserData } from "../../redux/modules/auth/userSlice";
import { setLocationIdInLocalStorage } from "../../utils/helper";
//import AddContact from "../contacts/addcontact";
import CreateSmsContent from "../content/CreateSmsContent";
import SmsContentLibrary from "../content/SmsContentLibrary";
import Numbers from "../settings/numbers";
import Chat from "../contacts/contactDetails/Chat";
import PreBuiltTemplates from "../content/preBuiltTemplates";

const AuthorizedCampaign = withAuthorization(Compaign, { module_id: MODULES.CAMPAIGN, action: 'view' });
const AuthorizedAllContacts = withAuthorization(AllContacts, { module_id: MODULES.CONTACTS, action: 'view' });
const AuthorizedLists = withAuthorization(Lists, { module_id: MODULES.LISTS, action: 'view' });
const AuthorizedUsers = withAuthorization(Users, { module_id: MODULES.USERS, action: 'view' });
const AuthorizedViewUser = withAuthorization(ViewUser, { module_id: MODULES.USERS, action: 'view' });
const AuthorizedAddUser = withAuthorization(AddUser, { module_id: MODULES.USERS, action: 'edit' });
const AuthorizedDomains = withAuthorization(Domains, { module_id: MODULES.DOMAINS, action: 'view' });
const AuthorizedJourney = withAuthorization(Journey, { module_id: MODULES.JOURNEYS, action: 'view' });
// const AuthorizedCreateJourney = withAuthorization(CreateJourney, { module_id: MODULES.JOURNEYS, action: 'edit' });
const AuthorizedJourneyBuilder = withAuthorization(JourneyBuilderPage, { module_id: MODULES.JOURNEYS, action: 'edit' });
const AuthorizedCampaignAnalytics = withAuthorization(CampaignAnalytics, { module_id: MODULES.CAMPAIGN, action: 'view' });
const AuthorizedJourneyAnalytics = withAuthorization(JourneyAnalytics, { module_id: MODULES.JOURNEYS, action: 'view' });
const AuthorizedAddContact = withAuthorization(AddContact, { module_id: MODULES.CONTACTS, action: 'edit' });
const AuthorizedContactDetails = withAuthorization(ContactDetails, { module_id: MODULES.CONTACTS, action: 'view' });
const AuthorizedContactChat = withAuthorization(Chat, { module_id: MODULES.CONTACTS, action: 'view' });
const AuthorizedSegments = withAuthorization(Segments, { module_id: MODULES.SEGMENTS, action: 'view' });
const AuthorizedCreateSegment = withAuthorization(CreateSegment, { module_id: MODULES.SEGMENTS, action: 'edit' });
const AuthorizedReviewSegment = withAuthorization(ReviewSegment, { module_id: MODULES.SEGMENTS, action: 'view' });
const AuthorizedContentLibrary = withAuthorization(ContentLibrary, { module_id: MODULES.CONTENT_LIBRARY, action: 'view' });
const AuthorizedAddContent = withAuthorization(CreateContent, { module_id: MODULES.CONTENT_LIBRARY, action: 'edit' });
const AuthorizedPreBuiltTemplates = withAuthorization(PreBuiltTemplates, { module_id: MODULES.CONTENT_LIBRARY, action: 'view' });
const AuthorizedCustomField = withAuthorization(CustomField, { module_id: MODULES.CUSTOM_FIELDS, action: 'view' });
const AuthorizedAddSmsContent = withAuthorization(CreateSmsContent, { module_id: MODULES.CONTENT_LIBRARY, action: 'edit' });
const AuthorizedSmsContentLibrary = withAuthorization(SmsContentLibrary, { module_id: MODULES.CONTENT_LIBRARY, action: 'view' });
const AuthorizedNumbers = withAuthorization(Numbers, { module_id: MODULES.DOMAINS, action: 'view' });

const Content: FC = (props: object) => {
  const dispatch = useDispatch();
  const { role_id } = useAuthorization()
  const { fetchData } = useAxios()

  useEffect(() => {
    const fetchUserDetail = async () => {
      const response = await fetchData(API_ENDPOINTS.GET_USER_INFO)
      if (response?.success) {
        dispatch(setUserData(response?.data ?? {}))

        //location id set start ------
        const locationId = setLocationIdInLocalStorage(response?.data.orgLocations)
        dispatch(setSelectedLocationId(locationId))
        //location id set end -----
      }
    }
    fetchUserDetail()
  }, [])
  
  const AuthorizePermission = role_id !== 1 ? <Permission/> : <h1>UnAuthorize</h1>
  // const AuthorizeAuditLog = role_id !== 1 ? <AuditLog/> : <h1>UnAuthorize</h1>

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/campaign" element={<AuthorizedCampaign />} />
      <Route path="/contacts/allContacts" element={<AuthorizedAllContacts />} />
      <Route path="/contacts/lists" element={<AuthorizedLists />} />
      <Route path="/settings/users" element={<AuthorizedUsers />} />
      <Route path="/settings/users/viewUser" element={<AuthorizedViewUser />} />
      <Route path="/settings/users/addUser/:id?" element={<AuthorizedAddUser />} />
      <Route path="/settings/profile" element={<CompanyDetails />} />
      <Route path="/settings/profile/editProfile" element={<EditCompany />} />
      <Route path="/settings/domains" element={<AuthorizedDomains />} />
      <Route path="/journey/" element={<AuthorizedJourney />} />
      {/* <Route path="/journey/createJourney" element={<AuthorizedCreateJourney />} /> */}
      <Route path="/journey/journeyBuilder" element={<AuthorizedJourneyBuilder />} />
      <Route path="/analytics/campaigns" element={<AuthorizedCampaignAnalytics />} />
      <Route path="/analytics/journeys" element={<AuthorizedJourneyAnalytics />} />
      
      <Route path="/edit-contact/:contactId" element={<AuthorizedAddContact />} />
      <Route path="/contact-detail/:contactId" element={<AuthorizedContactDetails />} />
      <Route path="/contact-detail/:contactId/chat" element={<AuthorizedContactChat />} />
      <Route path="/contacts/segments" element={<AuthorizedSegments />} />
      <Route path="/contacts/createSegment" element={<AuthorizedCreateSegment />} />
      <Route path="/edit-segment/:segmentId" element={<AuthorizedCreateSegment />} />
      <Route path="/content/email-content-library" element={<AuthorizedContentLibrary />} />
      <Route path="/addContact" element={<AuthorizedAddContact />} />
      <Route path="/content/create-email-content" element={<AuthorizedAddContent />} />
      <Route path="/content/create-email-content/:id?" element={<AuthorizedAddContent />} />
      <Route path="/content/pre-built-templates" element={<AuthorizedPreBuiltTemplates />} />
      <Route path="/contacts/reviewSegment" element={<AuthorizedReviewSegment />} />
      <Route path="/contacts/reviewSegment/:segmentId" element={<AuthorizedReviewSegment />} />
      <Route path="/contacts/lists" element={<AuthorizedLists />} />
      <Route path="/settings/customfields" element={<AuthorizedCustomField />} />
      <Route path="/settings/pemissions" element={AuthorizePermission} />
      {/* <Route path="/settings/auditLog" element={AuthorizeAuditLog} /> */}
      <Route path="/content/create-sms-content/:id?" element={<AuthorizedAddSmsContent />} />
      <Route path="/content/sms-content-library" element={<AuthorizedSmsContentLibrary />} />
      {/* <Route path="/detail-journey/:journeyId" element={<AuthorizedCreateJourney />} /> */}
      <Route path="/settings/numbers" element={<AuthorizedNumbers />} />

      <Route path="/*" element={404} />
    </Routes>
  );
};

export default Content


