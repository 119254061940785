import { call, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getRequest } from '../../../axios/interceptors';
import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '../../../constants/api';
import { City, State } from './types';
import { locationRequest, setLocationData } from './locationSlice';

function* locationData(action: PayloadAction<{ states: State[]; cities: City[] }>) {
  try {
    const response: AxiosResponse = yield call(() => getRequest(API_ENDPOINTS.LOCATION_DATA));
    yield put(setLocationData(response.data));
  } catch (error: any) {}
}

function* locationSaga() {
  yield takeLatest(locationRequest.type, locationData);
}

export default locationSaga;
