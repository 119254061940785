import CryptoJS from "crypto-js";

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

export const encryptData = (data) => {
    const message = data+''
    const encrypted = CryptoJS.AES.encrypt(message, encryptionKey).toString();
    return encrypted;
};

export const decryptData = (encryptedMessage) => {
    try {
        const decrypted = CryptoJS.AES.decrypt(encryptedMessage, encryptionKey).toString(CryptoJS.enc.Utf8);
        return decrypted; 
    } catch (error) {
        return ''
    }
};