/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnalyticsIcon, SettingsIcon, ContactIcon, ContentIcon, CampaignIcon, JourneysIcon, DashboardIcon } from "../../assets/images/index"
import { MODULES, ROLE } from "../../utils/enums";
import useAuthorization from "../../hooks/useAuthorization";
import { Accordion, Card, AccordionButton, AccordionCollapse, AccordionItem, Navbar } from 'react-bootstrap';
import { Image } from "react-bootstrap";
import { IMAGES } from "../../constants/imagePath";
import useSidebar from "../../hooks/useSidebar";
import useHelpdesk from "../../hooks/useHelpdesk";
import { HelpdeskIcon } from "../../assets/icons";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { goToDashboard } from "../../utils/helper";
interface NavItem {
  id: string;
  path: string;
  text: string;
  children?: NavItem[];
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | null;
  onClick?: () => void;
}

const SideNav: FC = () => {
  const { isAuthorize, role_id } = useAuthorization();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const navItems: NavItem[] = []
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const { onCloseSidebar, sideBarStatus } = useSidebar();
  const { openHelpdesk } = useHelpdesk()
  const { smsFeature } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = [location.pathname.substring(1)];
    const storedExpandedItems = localStorage.getItem('expandedItems');
    if (storedExpandedItems) {
      setExpandedItems(JSON.parse(storedExpandedItems));
    } else if (currentPath.length > 0 && expandedItems.length === 0) {
      setExpandedItems(currentPath);
      localStorage.setItem('expandedItems', JSON.stringify(currentPath));
    } else if (navItems.length > 0) {
      setExpandedItems([navItems[0].id]);
      localStorage.setItem('expandedItems', JSON.stringify([navItems[0].id]));
    }
  }, [location.pathname]);

  const handleAccordionToggle = (itemIndex: string) => {
    const isExpanded = expandedItems.includes(itemIndex);
    let newExpandedItems: string[];

    if (isExpanded) {
      // If the clicked item is already expanded, collapse it
      newExpandedItems = expandedItems.filter((key) => key !== itemIndex);
    } else {
      // If the clicked item is not expanded, expand it and collapse the previously expanded one
      newExpandedItems = [itemIndex];
    }

    setExpandedItems(newExpandedItems);
    localStorage.setItem('expandedItems', JSON.stringify(newExpandedItems));
  };

  if(role_id !== 2){
    navItems.push({ id: "org/dashboard", path: '/org/dashboard', text: 'Dashboard', icon: DashboardIcon })
  }

  if (role_id === 2) {
    navItems.push({ id: '/Admin/Organizations', path: '/Admin/Organizations', text: 'Organizations' ,icon: DashboardIcon});
  }

  if (isAuthorize([MODULES.CAMPAIGN], 'view')) {
    navItems.push({ id: "org/campaign", path: '/org/campaign', text: 'Campaign', icon: CampaignIcon })
  }

  if (isAuthorize([MODULES.JOURNEYS], 'view')) {
    navItems.push({ id: "org/journey", path: '/org/journey', text: 'Journeys', icon: JourneysIcon })
  }

  const contactsNavItem: NavItem = {
    id: "org/contacts/allContacts",
    path: '/org/contacts/allContacts',
    text: 'Contacts',
    icon: ContactIcon,
    children: [],
  };

  // Check permission for All Contacts
  if (isAuthorize([MODULES.CONTACTS], 'view')) {
    contactsNavItem.children?.push({ id: 'org/contacts/allContacts', path: '/org/contacts/allContacts', text: 'All Contacts' });
  }

  // Check permission for Lists
  if (isAuthorize([MODULES.LISTS], 'view')) {
    contactsNavItem.children?.push({ id: 'org/contacts/lists', path: '/org/contacts/lists', text: 'Lists' });
  }

  // Check permission for Segments
  if (isAuthorize([MODULES.SEGMENTS], 'view')) {
    contactsNavItem.children?.push({ id: 'org/contacts/segments', path: '/org/contacts/segments', text: 'Segments' });
  }

  // Add the Contacts section only if at least one child is present
  if ((contactsNavItem?.children?.length ?? 0) > 0) {
    navItems.push(contactsNavItem);
  }

  const analyticsNavItem: NavItem = {
    id: "org/analytics/campaigns",
    path: '/org/analytics/campaigns',
    text: 'Analytics',
    icon: AnalyticsIcon,
    children: [],
  };

  if (isAuthorize([MODULES.CAMPAIGN], 'view')) {
    analyticsNavItem.children?.push({ id: 'org/analytics/campaigns', path: '/org/analytics/campaigns', text: 'Campaigns' });
  }

  if (isAuthorize([MODULES.JOURNEYS], 'view')) {
    analyticsNavItem.children?.push({ id: 'org/analytics/journeys', path: '/org/analytics/journeys', text: 'Journeys' });
  }

  if ((analyticsNavItem?.children?.length ?? 0) > 0) {
    navItems.push(analyticsNavItem);
  }

  const contentNavItem: NavItem = {
    id: "org/content/email-content-library",
    path: '/org/content/email-content-library',
    text: 'Content',
    icon: ContentIcon,
    children: [],
  };

  if (isAuthorize([MODULES.CONTENT_LIBRARY], 'view')) {
    contentNavItem.children?.push({ id: 'org/content/email-content-library', path: '/org/content/email-content-library', text: 'Email Content Library' });
  }

  if (isAuthorize([MODULES.CONTENT_LIBRARY], 'edit')) {
    contentNavItem.children?.push({ id: 'org/content/create-email-content', path: '/org/content/create-email-content', text: 'Create Email Content' });
  }

  if (isAuthorize([MODULES.CONTENT_LIBRARY], 'view') && smsFeature) {
    contentNavItem.children?.push({ id: 'org/content/sms-content-library', path: '/org/content/sms-content-library', text: 'SMS Content Library' });
  }

  if (isAuthorize([MODULES.CONTENT_LIBRARY], 'edit') && smsFeature) {
    contentNavItem.children?.push({ id: 'org/content/create-sms-content', path: '/org/content/create-sms-content', text: 'Create SMS Content' });
  }

  if ((contentNavItem.children?.length ?? 0) > 0) {
    navItems.push(contentNavItem);
  }

  const settingsNavItem: NavItem = {
    id: "org/settings/users",
    path: '/org/settings/users',
    text: 'Settings',
    icon: SettingsIcon,
    children: [],
  };

  if (isAuthorize([MODULES.USERS], 'view')) {
    settingsNavItem.children?.push({ id: '/org/settings/users', path: '/org/settings/users', text: 'Users' });
  }

  if (isAuthorize([MODULES.DOMAINS], 'view')) {
    settingsNavItem.children?.push({ id: '/org/settings/domains', path: '/org/settings/domains', text: 'Domains' });
  }

  if (isAuthorize([MODULES.CUSTOM_FIELDS], 'view')) {
    settingsNavItem.children?.push({ id: '/org/settings/customfields', path: '/org/settings/customfields', text: 'Custom Fields' });
  }

  if (isAuthorize([MODULES.DOMAINS], 'view') && smsFeature) {
    settingsNavItem.children?.push({ id: '/org/settings/numbers', path: '/org/settings/numbers', text: 'Numbers' });
  }

  if (role_id === ROLE.ORG) {
    settingsNavItem.children?.push({ id: '/org/settings/pemissions', path: '/org/settings/pemissions', text: 'Permission' });
  }

  if ((settingsNavItem.children?.length ?? 0) > 0) {
    navItems.push(settingsNavItem);
  }

  if (isAuthorize([MODULES.HELPDESK_ORG], 'view')) {
    navItems.push({ id: "helpdesk", path: '', text: 'Helpdesk', icon: HelpdeskIcon, onClick: () => openHelpdesk() })
  }

  return (
    <Offcanvas show={show} onHide={handleClose} responsive="lg" className={sideBarStatus ? "show" : ""}>
      <Offcanvas.Header closeButton onClick={()=>onCloseSidebar()}>
        <Navbar.Brand >
          <Image 
            src={IMAGES.blueLogo}
            className="cursor-pointer"
            onClick={() => goToDashboard(navigate, role_id)}
          />
        </Navbar.Brand>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="sidebar">
          <Accordion activeKey={expandedItems} onSelect={(key: any) => setExpandedItems(key ? [key] : [])}>
            {navItems.map((route, index) => (
              <AccordionItem key={index} eventKey={route.id}>
                <AccordionButton
                  onClick={() => handleAccordionToggle(route.id)}
                  className={`d-flex align-items-center custom-accordion-button ${route.children && route.children.length > 0 ? '' : 'no-children'}`}
                >
                  <Link to={route.path} className={location.pathname.includes(route.path) ? 'active-child' : ''}
                  //onClick={() => handleNavItemClick(route)} className={isRouteSelected(route) ? 'selected' : ''}
                  onClick={route.onClick}
                  >{route.icon && <route.icon />} {route.text}</Link>
                </AccordionButton>
                {route.children && route.children.length > 0 && (
                  <AccordionCollapse eventKey={route.id} in={expandedItems.includes(route.id)}>
                    <Card.Body>
                      <ul className="sub-list">
                        {route.children?.map((subRoute, subIndex) => (
                          <li onClick={subRoute.onClick} key={subIndex} className={
                            location.pathname === subRoute.path ? "active-child" : ""
                          }
                          >
                            <Link to={subRoute.path}>{subRoute.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </Card.Body>
                  </AccordionCollapse>
                )}
              </AccordionItem>
            ))}
          </Accordion>


        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SideNav;

