/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Option } from "../utils/interface";
import useAxios from "./useAxios";
import { API_ENDPOINTS } from "../constants/api";

interface UseDomainsReturnType {
    domains: Option[];
    fetchDomains: () => Promise<void>;
  }


const useDomains = (): UseDomainsReturnType => {
    const { postData } = useAxios();

  const [domains, setDomains] = useState<Option[]>([]);

  const fetchDomains = async () => {
    try {
      const response = await postData(API_ENDPOINTS.GET_ALL_DOMAINS, { status: true });
      const formattedOption = response?.data.map((v: any) => ({
        value: v.id,
        text: v.email,
        key: v.email,
      }));
      setDomains(formattedOption);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDomains();
  }, []); 

  return { domains, fetchDomains };
};

export default useDomains;
