/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { Months } from "../../../utils/options";
import { API_ENDPOINTS } from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";

export type SelectOptions = {
  text: string;
  value: string;
  key: string;
};

export type CampaignDailyStats = {
  day: string;
  delivered: number;
  opened: number;
  clicked: number;
  unsubscribed: number;
  softbounce: number;
  hardbounce: number;
};

const getCurrentMonth = (): string => {
  const currentMonth = new Date().getMonth() + 1;
  const monthValue = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
  return `${getCurrentYear()}-${monthValue}`;
};

const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

type Props = {
  type: "campaign" | "journey";
};

const useMonthlyPerformanceChart = ({ type }: Props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [openDataset, setOpenDataset] = useState<number[]>([]);
  const [clickDataset, setClickDataset] = useState<number[]>([]);
  const [unsubscribeDataset, setUnsubscribeDataset] = useState<number[]>([]);
  const [monthList, setMonthList] = useState<SelectOptions[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());
  const { postData } = useAxios();

  useEffect(() => {
    const months = getMonthList();
    setMonthList(months);
  }, []);

  useEffect(() => {
    fetchMonthlyPerformance();
  }, [selectedMonth]);

  const fetchMonthlyPerformance = async () => {
    const url =
      type === "journey"
        ? API_ENDPOINTS.GET_MONTHLY_JOURNEY_PERFORMACE
        : API_ENDPOINTS.GET_MONTHLY_PERFORMACE;
    const response = await postData(url, {
      month: selectedMonth,
    });
    transformData(response?.data);
  };

  const transformData = (rawData: CampaignDailyStats[]) => {
    const labels: string[] = [];
    const opensData: number[] = [];
    const clicksData: number[] = [];
    const unsubscribesData: number[] = [];

    rawData?.forEach((item) => {
      labels.push(item.day.substring(5));
      opensData.push(item.opened);
      clicksData.push(item.clicked);
      unsubscribesData.push(item.unsubscribed);
    });

    setLabels(labels);
    setOpenDataset(opensData);
    setClickDataset(clicksData);
    setUnsubscribeDataset(unsubscribesData);
  };

  const getMonthList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const monthList: SelectOptions[] = [];
    for (let i = 0; i < currentMonth; i++) {
      const month = Months[i];
      monthList.push({
        text: month.text,
        value: `${currentYear}-${month.value}`,
        key: `${currentYear}-${month.value}`,
      });
    }

    return monthList;
  };

  const monthChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSelectedMonth(value);
  };

  return {
    labels,
    openDataset,
    clickDataset,
    unsubscribeDataset,
    monthList,
    selectedMonth,
    monthChangeHandler,
  };
};

export default useMonthlyPerformanceChart;
