import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  } from "../auth/types";

interface SideNavState {
  sideBarStatus: any;
}

const initialState: SideNavState = {
  sideBarStatus: false,
};

const sideNavSlice = createSlice({
  name: "sidenav",
  initialState,
  reducers: {
    setShowSidebarSlice(state, action: PayloadAction<any>) {
      const { changeSidebarStatus } = action.payload;
      state.sideBarStatus = !changeSidebarStatus;
    },
  },
});

export const { setShowSidebarSlice } = sideNavSlice.actions;

export default sideNavSlice.reducer;
