/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {  DashboardIcon, SettingsIcon } from "../../assets/images/index"
import { Accordion, Card, AccordionButton, AccordionCollapse, AccordionItem, Navbar } from 'react-bootstrap';
import { Image } from "react-bootstrap";
import { IMAGES } from "../../constants/imagePath";
import useSidebar from "../../hooks/useSidebar";
import useHelpdesk from "../../hooks/useHelpdesk";
import { MODULES, ROLE } from "../../utils/enums";
import useAuthorization from "../../hooks/useAuthorization";
import { OrganizationIcon, HelpdeskIcon, ActivityIcon } from "../../assets/icons";
import { goToDashboard } from "../../utils/helper";
interface NavItem {
  id: string;
  path: string;
  text: string;
  children?: NavItem[];
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | null;
  onClick?: () => void;
}

const AdminSideNav: FC = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const navItems: NavItem[] = []
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const { onCloseSidebar, sideBarStatus } = useSidebar();
  const { openHelpdesk } = useHelpdesk()
  const { isAuthorize, role_id } = useAuthorization();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = [location.pathname.substring(1)];
    const storedExpandedItems = localStorage.getItem('expandedItems');
    if (storedExpandedItems) {
      setExpandedItems(JSON.parse(storedExpandedItems));
    } else if (currentPath.length > 0 && expandedItems.length === 0) {
      setExpandedItems(currentPath);
      localStorage.setItem('expandedItems', JSON.stringify(currentPath));
    } else if (navItems.length > 0) {
      setExpandedItems([navItems[0].id]);
      localStorage.setItem('expandedItems', JSON.stringify([navItems[0].id]));
    }
  }, [location.pathname]);

  navItems.push({ id: '/Admin/dashboard', path: '/Admin/dashboard', text: 'Dashboard', icon: DashboardIcon});

  navItems.push({ id: '/Admin/Organizations', path: '/Admin/Organizations', text: 'Organizations', icon: OrganizationIcon });

  const settingsNavItem: NavItem = {
    id: "/Admin/settings/auditLog",
    path: '/Admin/settings/auditLog',
    text: 'Settings',
    icon: SettingsIcon,
    children: [],
  };
  if (isAuthorize([MODULES.AUDIT_LOG], 'view')) {
    settingsNavItem.children?.push({ id: '/Admin/settings/auditLog', path: '/Admin/settings/auditLog', text: 'Audit Log' });
  }

  if (role_id === ROLE.SUPER_ADMIN) {
    settingsNavItem.children?.push({ id: '/Admin/settings/users', path: '/Admin/settings/users', text: 'Users' });
  }

  if (role_id === ROLE.SUPER_ADMIN) {
    settingsNavItem.children?.push({ id: '/Admin/settings/pemissions', path: '/Admin/settings/pemissions', text: 'Permission' });
  }
  
  if ((settingsNavItem.children?.length ?? 0) > 0) {
    navItems.push(settingsNavItem);
  }

  if (isAuthorize([MODULES.LOGIN_ACTIVITY], 'view')) {
    navItems.push({ id: "/Admin/login-activity", path: '/Admin/login-activity', text: 'Login Activity', icon: ActivityIcon })
  }

  const handleAccordionToggle = (itemIndex: string) => {
    const isExpanded = expandedItems.includes(itemIndex);
    let newExpandedItems: string[];

    if (isExpanded) {
      // If the clicked item is already expanded, collapse it
      newExpandedItems = expandedItems.filter((key) => key !== itemIndex);
    } else {
      // If the clicked item is not expanded, expand it and collapse the previously expanded one
      newExpandedItems = [itemIndex];
    }

    setExpandedItems(newExpandedItems);
    localStorage.setItem('expandedItems', JSON.stringify(newExpandedItems));
  };
  if (isAuthorize([MODULES.HELPDESK_ADMIN], 'view')) {
    navItems.push({ id: "helpdesk", path: '/Admin/Organizations', text: 'Helpdesk', icon: HelpdeskIcon, onClick: () => openHelpdesk() })
  }

  return (
    <Offcanvas show={show} onHide={handleClose} responsive="lg" className={sideBarStatus ? "show" : ""}>
      <Offcanvas.Header closeButton onClick={()=>onCloseSidebar()}>
        <Navbar.Brand >
          <Image 
            src={IMAGES.blueLogo} 
            onClick={() => goToDashboard(navigate, role_id)}
          />
        </Navbar.Brand>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="sidebar">
          <Accordion activeKey={expandedItems} onSelect={(key: any) => setExpandedItems(key ? [key] : [])}>
            {navItems.map((route, index) => (
              <AccordionItem key={index} eventKey={route.id}>
                <AccordionButton
                  onClick={() => handleAccordionToggle(route.id)}
                  className={`d-flex align-items-center custom-accordion-button ${route.children && route.children.length > 0 ? '' : 'no-children'}`}
                >
                  <Link to={route.path} onClick={route.onClick} className={location.pathname.includes(route.path) ? 'active-child' : ''}
                  >{route.icon && <route.icon />} {route.text}</Link>
                </AccordionButton>
                {route.children && route.children.length > 0 && (
                  <AccordionCollapse eventKey={route.id} in={expandedItems.includes(route.id)}>
                    <Card.Body>
                      <ul className="sub-list">
                        {route.children?.map((subRoute, subIndex) => (
                          <li onClick={subRoute.onClick} key={subIndex} className={
                            location.pathname === subRoute.path ? "active-child" : ""
                          }
                          >
                            <Link to={subRoute.path}>{subRoute.text}</Link>
                          </li>
                        ))}
                      </ul>
                    </Card.Body>
                  </AccordionCollapse>
                )}
              </AccordionItem>
            ))}
          </Accordion>

        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AdminSideNav;

