/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from "./useAxios";
import { API_ENDPOINTS } from "../constants/api";
import { CampaignType } from "../utils/enums";


const useFolderTemplates = () => {
    const { postData } = useAxios();
    const [templates, setTemplates] = useState([]);
    const [smsTempalate, setSmsTemplates] = useState([]);

    const fetchTemplates = async () => {
        try {
            const response = await postData(API_ENDPOINTS.GET_ALL_TEMPLATE_WITH_FOLDER, { status: true, type: CampaignType.EMAIL });
            if(response.success){
                setTemplates(response?.data);
            }
        } catch (error) {}
    };

    const fetchSmsTemplates = async () => {
        try {
            const response = await postData(API_ENDPOINTS.GET_ALL_TEMPLATE_WITH_FOLDER, { status: true, type: CampaignType.SMS });
            if(response.success){
                setSmsTemplates(response?.data);
            }
        } catch (error) {}
    };

    useEffect(() => {
        fetchTemplates();
        fetchSmsTemplates()
    }, []); 

    return { templates, smsTempalate };
};

export default useFolderTemplates;
