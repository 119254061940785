import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { faFolder, faFile} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface MenuItem {
  folder_id?: string;
  folder_name?: string;
  templates?: MenuItem[];
  name: string;
  id: string;
}

interface MultiLevelDropdownProps {
  data: MenuItem[];
  onSelect: (id: string) => void;
  value: string;
  disabled?: boolean;
}

const MultiLevelDropdown: React.FC<MultiLevelDropdownProps> = ({ data, onSelect, value, disabled }) => {
  const [selectedTemplateName, setSelectedTemplateName] = useState<string | null>("");
  const [hasData, setHasData] = useState<boolean>(false);

  useEffect(()=>{
   if(value && data.length > 0) {
      const foundTemplate = data.map((folder:any) => 
            folder.templates.find((template:any) => template.id === +value)
            ).find(template => template !== undefined);
    foundTemplate && setSelectedTemplateName(foundTemplate.name);
   }
   setHasData(data.length > 0);
  },[value, data])

  const renderMenuItem = (item: MenuItem) => {
    if (item.folder_id) {
      return (
        <Dropdown key={item.folder_id}>
          <Dropdown.Toggle variant="default" id={`dropdown-${item.folder_name}`}>
          <FontAwesomeIcon icon={faFolder} />   {item.folder_name}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%" }}>
            {item.templates?.map((child) => renderMenuItem(child))}
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown.Item
          key={item.name}
          onClick={() => {
            onSelect(item.id);
            setSelectedTemplateName(item.name);
          }}
        >
        <FontAwesomeIcon icon={faFile} /> {item.name  }
        </Dropdown.Item>
      );
    }
  };

  return (
    <div>
    <Dropdown className='templateCustomDropdown'>
    {hasData ? (
      <>
      <Dropdown.Toggle id="dropdown-basic" className='templateDropdown form-select' disabled={disabled}>
        {selectedTemplateName ? selectedTemplateName : "Choose"}
        {/* {hasData ? (selectedTemplateName ? selectedTemplateName : "Choose") : "No options"} */}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "100%" }} className='inside-toggles'>
        {data.map((item) => renderMenuItem(item))}
      </Dropdown.Menu>
      </>
      ) : (
        <Dropdown.Toggle id="dropdown-basic" className="templateDropdown form-select" disabled>
          No options
        </Dropdown.Toggle>
      )}
    </Dropdown>
    </div>
  );
};

export default MultiLevelDropdown;
