import { FC, ReactNode } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface XDeleteModalProps {
  show: boolean;
  handleClose: () => void;
  title?: string;
  body?: ReactNode;
  size?: 'sm' | 'lg' | 'xl';
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const XDeleteModal: FC<XDeleteModalProps> = ({
  show,
  handleClose,
  title,
  body,
  size,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered size={size ?? 'sm'}>
      {title && (
        <Modal.Header closeButton className="centerHeader">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={{ maxHeight: '65vh', overflowY: 'auto' }}>
      <div className="content">
                       <p>{body}</p>
                     </div>
        </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {onConfirm && (
          <Button variant="primary" type="button" className="btn-theme" onClick={onConfirm}>
            {confirmText ?? 'Yes'}
          </Button>
        )}
        {onCancel && (
          <Button variant="primary" type="button" className="btn-theme btn-border" onClick={onCancel}>
            {cancelText ?? 'No'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default XDeleteModal;
