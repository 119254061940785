/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { IMAGES } from '../../../../constants/imagePath';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { StepProps, StepTwoFormValues } from '../../../../redux/modules/auth/types';
import { setStepTwoData } from '../../../../redux/modules/auth/accountSetupSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { locationRequest } from '../../../../redux/modules/auth/locationSlice';
import useLocationData from '../../../../hooks/useLocationData';
import XSelect from '../../../../component/form/XSelect';

const StepTwo: React.FC<StepProps> = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const selectStepTwoData = useSelector((state: RootState) => state.accountSetup.stepTwo);
  const { countries, states, fetchStates } = useLocationData();

  const StepTwoValidationSchema = Yup.object().shape({
    address: Yup.string().required('Address is required'),
    city: Yup.string().matches(/^[A-Za-z\s]+$/, 'Invalid city name').required('Select your city'),
    state: Yup.string().required('Select your state'),
    zipCode: Yup.string()
    .matches(/^[0-9]{5}$/, 'ZIP Code must be a 5-digit number')
    .required('Enter your ZIP Code'),
    country: Yup.string().required('Select your country'),
  });

  const formik = useFormik<StepTwoFormValues>({
    initialValues: {
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    validationSchema: StepTwoValidationSchema,
    onSubmit: async (values: StepTwoFormValues) => {
      localStorage.setItem('selectedCountryId', values.country);
      dispatch(setStepTwoData(values));
      onNext();
    },
  });

  const [countrySelected, setCountrySelected] = useState<boolean>(false);

  useEffect(() => {
    dispatch(locationRequest());
  }, []);

  useEffect(() => {
    if (formik.values.country) {
      fetchStates(formik.values.country);
      setCountrySelected(true);
    } else {
      setCountrySelected(false);
    }
  }, [formik.values.country]);


  useEffect(() => {
    formik.setValues(selectStepTwoData);
  }, [selectStepTwoData]);

  useEffect(() => {
    const selectedCountryId = localStorage.getItem('selectedCountryId')
    if (formik.values?.country !== selectedCountryId && formik.values?.country !== "") {
      formik.setFieldValue('state', '');
    }
  }, [formik.values?.country]);
  
  return (
    <div>
      {/* // -------------------- 2 step -------------------------- */}
      <div className='signup-process'>
        <Container>
          <div className="content">
            <Image src={IMAGES.blueLogo} className='top-logo' />
            <div className="card">
              <h2 className='card-title'>Account Setup</h2>
              <Row className='justify-content-center'>
                <Col xl={10}>
                <div className="stepper">
              <ul className='d-flex justify-content-between align-items-center'>
                <li className='text-center done'><span className='step d-inline-block'><svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.22619 16.1629L2.70709 10.6438C2.4097 10.3464 2.00635 10.1793 1.58578 10.1793C1.1652 10.1793 0.761855 10.3464 0.464464 10.6438C0.167073 10.9412 0 11.3445 0 11.7651C0 11.9733 0.0410177 12.1795 0.120711 12.3719C0.200403 12.5643 0.317211 12.7392 0.464464 12.8864L7.11283 19.5348C7.73313 20.1551 8.73516 20.1551 9.35546 19.5348L26.1831 2.70709C26.4805 2.4097 26.6476 2.00635 26.6476 1.58578C26.6476 1.1652 26.4805 0.761856 26.1831 0.464464C25.8857 0.167073 25.4824 0 25.0618 0C24.6412 0 24.2379 0.167073 23.9405 0.464464L8.22619 16.1629Z" fill="white" />
                </svg></span>
                </li>
                <li className='active'><span className='border-line'></span></li>
                <li className='text-center active'><span className='step d-inline-block'>2</span></li>
                <li className='active'><span className='border-line'></span></li>
                <li className='text-center'><span className='step d-inline-block'>3</span></li>
              </ul>
            </div>
                  {formik.touched.address && formik.touched.city && formik.touched.state && formik.touched.zipCode && formik.touched.country && Object.keys(formik.errors).length > 0 && (
                    <div className="error-box" style={{ display: formik.touched && Object.keys(formik.errors).length > 0 ? 'block' : 'none' }}>
                          <span style={{fontWeight:'bold'}}> {`There are ${Object.values(formik.errors).length} errors in your form.`}<br /> </span>
                      {Object.values(formik.errors).map((error, index) => (
                        <span key={index}>{error}<br /></span>
                      ))}
                    </div>
                  )}
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="form-title">
                      <h2>What’s your business address?</h2>
                    </div>
                    <Row>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <Form.Group controlId="formBasicEmail" className='form-group'>
                          <Form.Label>Address</Form.Label>
                          <Form.Control type="text" placeholder="Enter"
                            {...formik.getFieldProps('address')}
                          />
                          <Form.Text className="text-danger error-text">{formik.touched.address && formik.errors.address}</Form.Text>

                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Form.Group controlId="formBasicEmail" className='form-group'>
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text" placeholder="Enter"
                            {...formik.getFieldProps('city')}
                          />
                          <Form.Text className='text-danger error-text'>{formik.touched.city && formik.errors.city}</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xl={4} lg={6} md={6} sm={12}>
                        <XSelect
                          name='country'
                          label="Country"
                          options={Object.values(countries)}
                          formik={formik}
                        />
                      </Col>
                      <Col xl={4} lg={6} md={6} sm={12}>
                            <Form.Group controlId="formBasicEmail" className='form-group'>
                              <Form.Label>ZIP Code</Form.Label>
                              <Form.Control type="text" placeholder="Enter" {...formik.getFieldProps('zipCode')}/>
                              <Form.Text className='text-danger error-text'>{formik.touched.zipCode && formik.errors.zipCode}</Form.Text>
                           
                            </Form.Group>
                          </Col>
                          <Col xl={4} lg={12} md={12}>
                            <XSelect
                              disabled ={!countrySelected}
                              name='state'
                              label="State"
                              options={Object.values(states)}
                              formik={formik}
                            />
                          </Col>
                    </Row>
                    <div className='d-flex justify-content-between align-items-center'>
                    <Button variant="primary" type="submit" className='btn-theme btn-border' onClick={onBack}>
                        <FontAwesomeIcon icon={faPlay} />Back
                      </Button>
                      <Button variant="primary" type="submit" className='btn-theme' >
                        Next<FontAwesomeIcon icon={faPlay} />
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>

)
}

export default StepTwo;