import { Form } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import useMonthlyPerformanceChart from "./useMonthlyPerformanceChart";

type Props = {
    type: 'campaign' | 'journey'
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};


const MonthlyPerformanceChart = ({ type }: Props) => {
    const {
        labels,
        openDataset,
        clickDataset,
        unsubscribeDataset,
        monthList,
        selectedMonth,
        monthChangeHandler
    } = useMonthlyPerformanceChart({ type });

    const data = {
        labels,
        datasets: [
            {
                label: 'Open Rate',
                data: openDataset,
                backgroundColor: '#13294B',
                borderColor: '13294B',
                borderWidth: 2,
            },
            {
                label: 'Click Rate',
                data: clickDataset,
                backgroundColor: '#7BAFD4',
                borderColor: '7BAFD4',
                borderWidth: 2,
            },
            {
                label: 'Unsubscribe Rate',
                data: unsubscribeDataset,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
            }
        ],
    };

    return (
        <>
            <div className="filter-bar d-flex align-items-center justify-content-between row-item analytics-bar sm-block">
                <div className='sub-title'>Emails and SMS send over time</div>
                <div className="d-flex align-items-center gap-2 justify-content-evenly">
                    <Form.Group className="d-flex align-items-center" style={{ width: '140px' }}>
                        <Form.Select
                            name="dateRange"
                            value={selectedMonth}
                            onChange={monthChangeHandler}
                        >
                            <option value="">Select</option>
                            {monthList.map((v) => <option key={v.key} value={v.value}>{v.text}</option>)}
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>
            <Line options={options} data={data} />
        </>

    )
}

export default MonthlyPerformanceChart