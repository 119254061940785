import { useState } from "react";
import useRuleActions from "../../hooks/useRuleActions";

export type StepPayloadType = {
    id?: number;
    parent_id?: number;
    action_id?: number;
    rule_id?: number;
    rule_type?: number;
    delaySeconds?: number;
}

type Props = {
    updateStep:  (updatedStep: any) => void
}
 
type actionClickHandlerType = {
    action_id: number
}

const useJourneyStepModal = (props: Props) => {
    const [conditionModal, setConditionModal] = useState<boolean>(false);
    const [actionId, setActionId] = useState<number>(0);
    const [conditionDelayModal, setConditionDelayModal] = useState<boolean>(false);
    const [stepPayload, setStepPayload] = useState<StepPayloadType>({});
    const {rules, actions} = useRuleActions()
    const conditions = rules.slice(0,2)
    const timeDelay = rules.slice(2)


    const conditionModalClickHandler = () => {
        setConditionModal(!conditionModal);
    };

    const actionClickHandler = ({action_id}: actionClickHandlerType)=>{
            setActionId(action_id)
    }

    const closeEmailModal = () => {
        setActionId(0);
    };
    const conditionDelayModalClickHandler = () => {
        setConditionDelayModal(!conditionDelayModal);
    };


    const updateStepPayloadHandler = (payload: StepPayloadType)=>{
        setStepPayload({...stepPayload, ...payload})
    }

    return {
        conditionModal,
        conditionModalClickHandler,
        conditionDelayModal,
        conditionDelayModalClickHandler,
        stepPayload,
        conditions,
        timeDelay,
        actions,
        updateStepPayloadHandler,
        actionId,
        closeEmailModal,
        actionClickHandler
    };
};

export default useJourneyStepModal;
