import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { loginRequest, loginSuccess, loginFailure, registerSuccess, registerFailure, registerRequest, resetPasswordRequest, updatePasswordRequest, resetPasswordFailure, updatePasswordFailure, resetPasswordSuccess, updatePasswordSuccess,emailVerifyRequest, emailVerifyFailure, emailVerifySuccess } from '../auth/authSlice';
import { postRequest} from '../../../axios/interceptors';
import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from '../../../constants/api';
import { CreatePasswordPayload, LoginFormPayload, RegisterPayload, ResetFormValues } from './types';

function* register(action: PayloadAction<RegisterPayload>) {
  try {
    const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.REGISTER, action.payload));
    yield put(registerSuccess(response.data));
  } catch (error:any) {
    yield put(registerFailure(error.response.data || error));
  }  
}
function* emailVerify(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.EMAIL_VERIFY, action.payload));
    yield put(emailVerifySuccess(response.data));
  } catch (error:any) {
    yield put(emailVerifyFailure(error.response.data || error));
  }  
}

  function* login(action: PayloadAction<LoginFormPayload>) {
    try {
      const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.LOGIN , action.payload));
      yield put(loginSuccess({...response.data, requestPayload: action.payload}));
    } catch (error:any) {
      yield put(loginFailure(error.response.data || error));
    }  
  }

  function* resetPassword(action: PayloadAction<ResetFormValues>) {
    try {
      const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.RESETPASSWORD, action.payload));
      yield put(resetPasswordSuccess(response.data));
    } catch (error:any) {
      yield put(resetPasswordFailure(error.response.data.message || error));
    }  
  }
  function* updatePassword(action: PayloadAction<CreatePasswordPayload>) {
    try {
      const response: AxiosResponse = yield call(() => postRequest(API_ENDPOINTS.UPDATEPASSWORD, action.payload));
      yield put(updatePasswordSuccess(response.data));
    } catch (error:any) {
      yield put(updatePasswordFailure(error.response.data.message || error));
    }  
  } 
function* authSaga() {
    yield takeLatest(loginRequest.type, login);
    yield takeLatest(registerRequest.type, register);
    yield takeLatest(emailVerifyRequest.type, emailVerify);
    yield takeLatest(resetPasswordRequest.type, resetPassword);
    yield takeLatest(updatePasswordRequest.type, updatePassword);
}

export default authSaga;
