import React from "react";
import { Form, Col, Row } from "react-bootstrap";

const TextField: React.FC<any> = ({
  label,
  name,
  id,
  value,
  onChange,
  placeholder,
}) => {
  return (
    <Form.Group controlId={label}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        data-id={id}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Form.Group>
  );
};

const DropdownField: React.FC<any> = ({
  label,
  field,
  form,
  options,
  ...props
}) => (
  <Form.Group as={Row}>
    <Col>
      <Form.Label>{label}</Form.Label>
      <Form.Select {...field} {...props}>
        <option>Select</option>
        {options.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Col>
  </Form.Group>
);

const BooleanField: React.FC<any> = ({ label, name, value, onChange, id }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Check
        type="checkbox"
        data-id={id}
        name={name}
        checked={value === "true"}
        onChange={onChange}
        className="d-flex align-items-center"
      />
    </Form.Group>
  );
};

const DateField: React.FC<any> = ({ label, field, value, id, ...props }) => {
  return(
  <Form.Group as={Row} className="form-group">
    <Form.Label>{label}</Form.Label>
    <Col>
      <Form.Control type="date" {...field} {...props} value={value} data-id={id}/>
    </Col>
  </Form.Group>
)};

export { TextField, DropdownField, BooleanField, DateField };
