/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import { IMAGES } from './../../constants/imagePath';
import useAxios from '../../hooks/useAxios';
import { API_ENDPOINTS } from '../../constants/api';
import { MailgunEventType } from '../../utils/enums';
import XLoader from '../../component/loader/XLoader';
import { utcToLocalStringWithoutT } from '../../utils/helper';

const ActivityItem: React.FC<{ imageSrc: string; date: string; activity: string }> = ({ imageSrc, date, activity }) => {
    return (
        <li className="d-flex">
            <Image src={imageSrc} />
            <div className="text">
                <span>{date}</span>
                <p>{activity}</p>
            </div>
        </li>
    );
};


type FilterType = {
    dateRange: "lastWeek" | "lastMonth",
    event: 'opened' | 'clicked' | 'delivered' | ''
}

type ContactActivityProps = {
    email: string
}

const eventImage = {
    [MailgunEventType.OPENED]: IMAGES.iconOpenMail,
    [MailgunEventType.CLICKED]: IMAGES.iconClickLink,
    [MailgunEventType.DELIVERED]: IMAGES.iconMail,
}

const ContactActivity: FC<ContactActivityProps> = ({ email }) => {
    const [filter, setFilter] = useState<FilterType>({ dateRange: 'lastMonth', event: '' })
    const [activity, setActivity] = useState([])

    const { postData, isLoading } = useAxios()

    useEffect(() => {
        const payload = {
            ...filter,
            email
        }
        const fetchContactAnalyticData = async () => {
            const response = await postData(API_ENDPOINTS.CONTACT_ANALYTIC, payload);
            if (response?.success) {
                setActivity(response.data)
            }
        };
        fetchContactAnalyticData()

    }, [filter.dateRange, filter.event, email])

    const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        setFilter((preFilter) => ({ ...preFilter, [name]: value as FilterType[keyof FilterType] }))
    };


    return (
        <div className="info-box">
            <div className="filter-title d-flex justify-content-between align-items-center">
                <div className="options d-flex">
                    <Form.Group controlId="filterBy" className="d-flex align-items-center">
                        <Form.Label>Filter By</Form.Label>
                        <Form.Select name='event' onChange={handleFilterChange} value={filter.event}>
                            <option value="">All Activity</option>
                            <option value={MailgunEventType.DELIVERED}>Delivered</option>
                            <option value={MailgunEventType.OPENED}>Opens</option>
                            <option value={MailgunEventType.CLICKED}>Clicks</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="dateFilter" className="d-flex align-items-center">
                        <Form.Label>Date</Form.Label>
                        <Form.Select name='dateRange' onChange={handleFilterChange} value={filter.dateRange}>
                            <option value="lastWeek">Last Week</option>
                            <option value="lastMonth">Last Month</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>

            <ul className="activity-list">
                {(activity.length > 0 && !isLoading) && activity.map((v: any, k) => {
                    const event = v.event
                    return (<ActivityItem key={k} imageSrc={eventImage[event]} date={utcToLocalStringWithoutT(v.date)} activity={`Email is ${event}`} />)
                })}
                {(activity.length === 0 && !isLoading) && <p>No Data Available</p>}
                {isLoading && <XLoader />}
            </ul>
        </div>
    );
};

export default ContactActivity;
